/* eslint-disable array-callback-return */
import React, { useRef, useState } from 'react';
import { BulletLine } from '../../shared/Bullets';
import { showModal, setModalPos } from '../../../actions/Networks';
import { connect } from 'react-redux';
import { Language } from '../../shared/Language';
import { SideParagraph } from '../../shared/Layout';
import _has from 'lodash.has';
import { sizes, COLORS } from '../../../constants';
import { Padding } from '../../welcome/SideContent';
import { SubMenu } from '../../shared/SubMenu';
import styled from 'styled-components/macro';

export const calcYOffset = (elem, parent) => {
  const rect = elem.getBoundingClientRect();
  const parentRect = parent.getBoundingClientRect();
  const scrollY = parent.parentNode.scrollTop;
  const y = rect.top - parentRect.top - scrollY;
  return y;
};

const Text = styled.div`
  color: ${COLORS.TEXT_DARK};
  font-size: 16px;
  letter-spacing: 0.5;
  line-height: 22px;
`;

const Button = styled.button`
  background: #359b46;
  border: none;
  color: white;
  padding: 16px 32px;
  margin-top: 32px;
  font-size: 16px;
  cursor: pointer;
`;

const CondSideCopy = props => {
  let children = props.children;
  if (typeof children === 'string' && /\[/.test(children[0])) {
    children = JSON.parse(children).join('');
  }

  return (
    <div
      style={{
        marginTop: 34,
        marginBottom: 0,
      }}
    >
      {props.children.map((c, i) => {
        return <Text key={i} dangerouslySetInnerHTML={{ __html: c }} />;
      })}
    </div>
  );
};

const Mobile = styled.div`
  @media (min-width: ${sizes.tablet}px) {
    display: none;
  }
`;

const Menus = styled.div`
  background-color: ${COLORS.ANTHEM_MENU_BG};
  color: white;
  font-size: 18px;
  opacity: ${props => props.opacity};
  box-sizing: border-box;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const _Item = props => {
  const [showMenus, setShowMenus] = useState(false);
  const parentElem = useRef();

  return (
    <div ref={parentElem}>
      <Padding
        style={{
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <BulletLine
          bullettype={props.plan && props.plan.bulletType}
          onClick={e => {
            e.stopPropagation(true);

            // if there is a URL, open it in a new tab
            if (props.item.url) {
              return window.open(props.item.url, '_blank');
            }

            // show modal in the content area if it exists
            if (props.item.menus && props.item.menus.length) {
              props.onShowModal(
                props.item.menus,
                calcYOffset(
                  e.target.parentNode,
                  parentElem.current.parentNode.parentNode,
                ),
                props.id,
              );
            }

            // toggle mobile menu
            setShowMenus(prev => !prev);
          }}
          textstyle={props.textStyle}
        >
          {props.item.title}
        </BulletLine>
      </Padding>

      <Mobile>
        {showMenus && props.item.menus && props.item.menus.length && (
          <Menus>
            <Padding>
              <SubMenu items={props.item.menus} />
            </Padding>
          </Menus>
        )}
      </Mobile>
    </div>
  );
};

export const Item = connect(null, dispatch => {
  return {
    onShowModal: (items, y, id) => {
      dispatch(setModalPos(0, y));
      dispatch(showModal(items, id));
    },
  };
})(_Item);

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const MainContentWrapper = styled.div`
  /* locks the EndContent to the bottom and scrolls the logo and bullets */
  /* overflow-y: auto; */
`;

export let SideContent = ({
  language,
  acoNetworks,
  hpnNetworks,
  has,
  is,
  options,
  isMedical,
  hideLanguageSelector = false,
  ihcId,
  eventId,
  ...props
}) => {
  return (
    <FlexColumn>
      <MainContentWrapper>
        <Padding
          style={{
            paddingBottom: props.plan.shiftBulletsUp ? 12 : 36,
          }}
        >
          {!hideLanguageSelector && <Language />}

          {/* Logo */}
          {_has(props.plan, 'images.logo.url') && (
            <div
              style={{
                marginTop: 48,
                position: 'relative',
                width: 111,
              }}
            >
              <img
                style={{
                  width: props.plan.images.logo.width || 'auto',
                  height: props.plan.images.logo.height || 'auto',
                }}
                src={
                  props.plan.images.logo.useOriginal
                    ? props.plan.images.logo.origUrl
                    : props.plan.images.logo.url
                }
                alt="logo"
              />
            </div>
          )}

          {/* Slug Line if available */}
          {props.plan.slug && props.plan.slug[language] && (
            <SideParagraph style={{ marginBottom: 62 }}>
              {props.plan.slug[language].value}
            </SideParagraph>
          )}

          {props.overrideContent && props.overrideContent}

          {/* Conditional side copy */}
          {!props.overrideContent &&
            props.plan.sideCopy &&
            // eslint-disable-next-line
            props.plan.sideCopy.map((text, i) => {
              return (
                <CondSideCopy key={i}>{text[language].value}</CondSideCopy>
              );
            })}

          <a href="https://www1.deltadentalins.com/members.html">
            <Button>Learn more</Button>
          </a>
        </Padding>
      </MainContentWrapper>
    </FlexColumn>
  );
};

SideContent.defaultProps = {
  isMedical: false,
};

SideContent = connect(state => {
  return {
    language: state.Language.current.toLowerCase(),
    acoNetworks: state.Data.acoNetworks,
    hpnNetworks: state.Data.hpnNetworks,
    has: state.Data.has,
    is: state.Data.is,
    options: state.Options,
    ihcId: state.Data.ihcId,
    eventId: state.Data.event.eventId,
  };
})(SideContent);
