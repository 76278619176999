import React, { useEffect, useState } from 'react';
import ft from 'feature-toggle';
import { connect } from 'react-redux';

export const FeaturesContext = React.createContext();

export const FeaturesProvider = ({ features: featuresIn = [], children }) => {
  /**
   * Available features:
   *
   * - showRetaC19: Coronavirus message for Reta
   * - sideC19: Place the Coronavirus message in the left side column
   * - fullBg: Use the full canvas of a section for the background and make
   *   side content transparent
   * - autoSectionHeight: auto height for all sections so they won't resize
   *   with the browser
   */
  const [features, setFeatures] = useState(ft(featuresIn));

  useEffect(() => {
    if (featuresIn.length) {
      setFeatures(ft(featuresIn));
    }
  }, [featuresIn]);

  return (
    <FeaturesContext.Provider value={features}>
      {children}
    </FeaturesContext.Provider>
  );
};

export default connect(state => {
  return { features: state.Data.features };
})(FeaturesProvider);
