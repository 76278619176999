import {
  FETCHING_DATA,
  RECEIVED_DATA,
  FETCH_ERROR,
  SET_API_HOST,
} from '../actions/Data';
import _sortBy from 'lodash.sortby';

const initState = {
  orgId: null,
  fetching: false,
  initialized: false,
  error: false,
  errorMsg: null,
  apiHost: null,
  personalInfo: {
    name: {},
  },
  options: {},
  enrollment: {},
  trustor: {
    images: {
      backgroundImage: {},
    },
    message: [],
    whatsNew: [],
  },
  location: {
    contact: {},
    message: [],
  },
  whatsNew: [],
  plans: {
    current: {},
    available: {},
  },
  hasHSA: false,
  hasKaiser: false,
  hasAnthem: false,
  has: {
    EPO: false,
    PPO: false,
    HMO: false,
    HSA: false,
    ACO: false,
    DEPO: false,
    HPN: false,
    anthem: false,
    kaiser: false,
    aetna: false,
    uhc: false,
    vsp: false,
    wellness: false,
  },
  only: {
    kaiser: false,
  },
  showWhatsNew: false,
};

const _hasNetwork = (plans, network) => {
  return plans.reduce((p, c) => {
    if (p) return p;
    if (c.network === network) return true;
    return false;
  }, false);
};

const _hasType = (plans, type) => {
  return plans.reduce((p, c) => {
    if (p) return p;
    if (c.type === type) return true;
    return false;
  }, false);
};

export const Data = (state = initState, action) => {
  switch (action.type) {
    case FETCHING_DATA:
      return {
        ...state,
        fetching: true,
      };

    case RECEIVED_DATA: {
      const data = action.data;
      /**
       * get the tier names
       */
      let tierNames = [];
      data.plans.available.medical.forEach(p => {
        if (p.costTiers) {
          p.costTiers.forEach(t => {
            if (!tierNames.includes(t.name)) {
              tierNames.push(t.name);
            }
          });
        }
      });

      /**
       * Transform cost tiers into rows
       */
      data.plans.available.medical = data.plans.available.medical.map(p => {
        return {
          ...p,
          _tierNames: tierNames,
          _costTiers: tierNames.map(tierName => {
            const item = p.costTiers.find(tier => tier.name === tierName);
            if (item) {
              return item.amount;
            }
            return 'N/A';
          }),
        };
      });

      /**
       * Order all plans together
       */
      data.plans.available.medical = _sortBy(data.plans.available.medical, [
        m => m.network,
      ]);

      /**
       * Tag all `other` plans
       */
      data.plans.available.other = data.plans.available.other.map(o => {
        return {
          ...o,
          other: true,
        };
      });
      /**
       * Get networks with ACO plans
       */
      const acoNetworks = data.plans.available.medical.reduce((p, c) => {
        if (c.type === 'ACO' && !p.includes(c.network)) {
          p.push(c.network);
        }
        return p;
      }, []);

      /**
       * Get networks with HPN plans
       */
      const hpnNetworks = data.plans.available.medical.reduce((p, c) => {
        if (c.type === 'HPN' && !p.includes(c.network)) {
          p.push(c.network);
        }
        return p;
      }, []);

      /**
       * Sort alphabetically by network (carrier) name
       */

      const isClergy =
        data.isPriest ||
        data.isNun ||
        data.isBishop ||
        data.isBrother ||
        data.isRetiredPriest ||
        /\w+/.test(data.clergyType);

      data.plans.available.other = data.plans.available.other
        .filter(plan => {
          if (plan.condition === 'NOT_CLERGY' && isClergy) {
            return false;
          }
          return true;
        })
        .sort((a, b) => {
          if (a.network < b.network) return -1;
          if (a.network > b.network) return 1;
          return 0;
        });

      /*
       * Process event information
       */
      const event = data.event;
      if (event.message && /\[|\{/.test(event.message[0])) {
        event.message = JSON.parse(event.message);
      }
      if (event.congrats && /\[|\{/.test(event.congrats[0])) {
        event.congrats = JSON.parse(event.congrats);
      }

      /**
       * Mkae sure the is `media` on plans
       */
      ['available', 'current'].forEach(cat => {
        Object.keys(data.plans[cat]).forEach(t => {
          data.plans[cat][t] = data.plans[cat][t].map(p => {
            if (!p.media) {
              p.media = [];
            }

            return p;
          });
        });
      });

      return {
        ...initState,
        initialized: true,
        ...data,
        trustor: {
          ...initState.trustor,
          ...data.trustor,
        },
        location: {
          ...initState.location,
          ...data.location,
        },
        event,
        fetching: false,
        error: false,
        // TODO: Depricate the root level `has*` (Sun Mar  5 09:53:36 2017);
        hasHSA: _hasType(data.plans.available.medical, 'HSA'),
        hasKaiser: _hasType(data.plans.available.medical, 'KAISER'),
        hasAnthem: _hasType(data.plans.available.medical, 'ANTHEM'),
        has: {
          ...state.has,
          EPO:
            _hasType(data.plans.available.medical, 'EPO') ||
            _hasType(data.plans.available.medical, 'EPO+HSA'),
          PPO: _hasType(data.plans.available.medical, 'PPO'),
          HOM: _hasType(data.plans.available.medical, 'HOM'),
          HSA:
            _hasType(data.plans.available.medical, 'HSA') ||
            _hasType(data.plans.available.medical, 'EPO+HSA'),
          ACO: _hasType(data.plans.available.medical, 'ACO'),
          DEPO: _hasType(data.plans.available.medical, 'DEPO'),
          HPN: _hasType(data.plans.available.medical, 'HPN'),
          kaiser: _hasNetwork(data.plans.available.medical, 'KAISER'),
          anthem: _hasNetwork(data.plans.available.medical, 'ANTHEM'),
          blueShieldCA: _hasNetwork(
            data.plans.available.medical,
            'BLUE_SHIELD_CA',
          ),
          aetna: _hasNetwork(data.plans.available.medical, 'AETNA'),
          uhc: _hasNetwork(data.plans.available.medical, 'UHC'),
          vsp: _hasNetwork(data.plans.available.vision, 'VSP'),
          webmd: _hasNetwork(data.plans.available.wellness, 'WEBMD'),
          ww: _hasNetwork(data.plans.available.wellness, 'WW'),
          fitbit: _hasNetwork(data.plans.available.wellness, 'FITBIT'),
          livongo: data.plans.available.hasOwnProperty('livongo'),
          bridgeHealth: _hasNetwork(
            data.plans.available.medical,
            'BRIDGEHEALTH',
          ),
          vivante: _hasNetwork(data.plans.available.medical, 'VIVANTE'),
          catapult: _hasNetwork(data.plans.available.wellness, 'CATAPULT'),
          wellness: data.plans.available.wellness.length > 0,
        },
        only: {
          kaiser:
            _hasNetwork(data.plans.available.medical, 'KAISER') &&
            !_hasNetwork(data.plans.available.medical, 'ANTHEM') &&
            !_hasNetwork(data.plans.available.medical, 'AETNA') &&
            !_hasNetwork(data.plans.available.medical, 'UHC'),
        },
        acoNetworks,
        hpnNetworks,
        is: {
          clergy: isClergy,
          seminarian: data.isSeminarian || /SEMINARIAN/i.test(data.clergyType),
          lay:
            !isClergy ||
            data.isSeminarian ||
            /SEMINARIAN/i.test(data.clergyType),
          priest: data.isPriest || /PRIEST/i.test(data.clergyType),
          retiredPriest:
            data.isRetiredPriest || /RETIRED_PRIEST/i.test(data.clergyType),
          nun: data.isNun || /NUN/i.test(data.clergyType),
          brother: data.isBrother || /BROTHER/i.test(data.clergyType),
          deacon: data.isDeacon || /DEACON/i.test(data.clergyType),
          bishop: data.isBishop || /BISHOP/i.test(data.clergyType),
        },
        orgId: data.trustor.id,
      };
    }

    case SET_API_HOST:
      return {
        ...state,
        apiHost: action.host,
      };

    case FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: true,
        errorMsg: action.message,
      };

    default:
      return state;
  }
};
