import React from 'react';
import { Provider } from 'react-redux';
import { store } from './store';
import { store as editStore } from './reducers/Editor';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import App from './App';
import { Editor } from './components/editor/Editor';

const PresentationProvider = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

const EditorProvider = () => {
  return (
    <Provider store={editStore}>
      <Editor />
    </Provider>
  );
};

const NoMatch = () => {
  return <div>Unable to match route.</div>;
};

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={PresentationProvider} />
        <Route path="/_editor" component={EditorProvider} />
        <Route component={NoMatch} />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
