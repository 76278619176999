const initialState = {
  hasError: false,
  message: null,
  stack: null,
  component: null,
};

const Error = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ERROR':
      return {
        hasError: true,
        message: action.message,
        stack: action.stack,
        component: action.component,
      };

    case 'FETCH_ERROR':
      return {
        hasError: true,
        message: action.message,
        stack: action.stack,
        component: action.component,
      };

    default:
      return state;
  }
};

export default Error;
