import { FlexColumn } from './Flexbox';
import styled from 'styled-components';
import { media } from '../../constants';

export const SideNav = styled(FlexColumn)`
  width: 175px;
  height: 100vh;
  flex: 0 0 175px;

  ${media.desktop`
    display: none !important;
  `}
`;
