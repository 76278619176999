import React, { useContext } from 'react';
import {
  SectionWithSideContent,
  SectionWithSideContentFullBg,
} from '../shared/Layout';
import { Content } from './Content';
import { SideContent } from './SideContent';
import { COLORS } from '../../constants';
import { connect } from 'react-redux';
import _has from 'lodash.has';
import Color from 'color2';
import { FeaturesContext } from '../../contexts/features';

export let WelcomeSection = ({ bgImg, client }) => {
  const features = useContext(FeaturesContext);
  if (features.active('fullBg')) {
    return (
      <SectionWithSideContentFullBg
        topMargin={true}
        id="welcome"
        sideContentStyle={{
          color: _has(client, 'colors.text.global')
            ? client.colors.text.global
            : COLORS.TEXT,
          backgroundColor: new Color(
            _has(client, 'colors.background.global')
              ? client.colors.background.global
              : COLORS.RED,
          ).alpha(0.85),
          padding: 0,
        }}
        sideContent={<SideContent />}
        content={<Content />}
        bgImage={bgImg}
      />
    );
  } else {
    return (
      <SectionWithSideContent
        topMargin={true}
        id="welcome"
        sideContentStyle={{
          color: _has(client, 'colors.text.global')
            ? client.colors.text.global
            : COLORS.TEXT,
          backgroundColor: _has(client, 'colors.background.global')
            ? client.colors.background.global
            : COLORS.RED,
          padding: 0,
        }}
        sideContent={<SideContent />}
        content={<Content />}
      />
    );
  }
};

WelcomeSection = connect(state => {
  return {
    client: state.Client,
    bgImg: _has(state.Data, 'trustor.images.background.url')
      ? state.Data.trustor.images.background.url
      : 'assets/img/welcomeBackgroundDefault.png',
  };
})(WelcomeSection);

export { WelcomeSection as default };
