import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { rootReducer } from './reducers/root';

const middleware = [thunkMiddleware];

if (process.env.NODE_ENV !== 'production') {
  const { createLogger } = require('redux-logger');
  middleware.push(createLogger());
}

export const store = createStore(rootReducer, applyMiddleware(...middleware));

export default store;
