import React from 'react';
import { FlexColumn } from './Flexbox';
import { MenuItem } from './MenuItem';
import { connect } from 'react-redux';

export let SubMenu = ({ trustorVsp, has, ...props }) => {
  return (
    <FlexColumn>
      {props.items.map((item, i) => {
        /**
         * Skip vision plan submenus if they don't match the condition.  This
         * should get moved to the API.
         */
        if (item.condition && /RETA_TRUST_PLAN_/.test(item.condition)) {
          if (!trustorVsp || !trustorVsp.includes(item.condition)) {
            return <></>;
          }
        }

        if (item.condition === 'HAS_HSA' && !has.HSA) {
          return <></>;
        }

        if (item.condition === 'HAS_PPO' && !has.PPO) {
          return <></>;
        }

        if (item.condition === 'HAS_EPO' && !has.EPO) {
          return <></>;
        }

        if (item.condition === 'HAS_DEPO' && !has.DEPO) {
          return <></>;
        }

        if (item.condition === 'HAS_ACO' && !has.ACO) {
          return <></>;
        }

        if (item.condition === 'HAS_HPN' && !has.HPN) {
          return <></>;
        }

        return (
          <MenuItem
            key={i}
            style={Object.assign(
              {},
              i >= props.items.length - 1 && {
                marginBottom: 0,
              },
              !item.url && { cursor: 'default' },
            )}
            onClick={e => {
              // stop menu hidigin if there is no url
              if (!item.url) {
                e.stopPropagation(true);
                return;
              }

              // check for hashes
              if (/^#(.+)/.test(item.url)) {
                window.location.hash = item.url;
              } else {
                window.open(item.url);
              }
            }}
          >
            {item.title}
          </MenuItem>
        );
      })}
    </FlexColumn>
  );
};

SubMenu = connect(state => {
  return {
    trustorVsp: state.Data.trustor.vspPlans,
    has: state.Data.has,
  };
})(SubMenu);
