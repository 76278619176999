import React, { useEffect } from 'react';
import { COLORS } from '../../constants';

export let MenuModal = ({ offset = {}, onClicked, ...props }) => {
  // Keep the modal within the parent element
  useEffect(() => {
    const elem = document.querySelector('.modal-sub-menu');
    const parentElem = elem.parentNode;

    const elemRect = elem.getBoundingClientRect();
    const parentRect = parentElem.getBoundingClientRect();

    if (offset.y + elemRect.height > parentRect.height) {
      elem.style.top = null;
      elem.style.bottom = 0;
    } else {
      elem.style.top = offset.y + 'px';
      elem.style.bottom = null;
    }
  }, [offset]);

  return (
    <div
      className="modal-sub-menu"
      style={{
        backgroundColor: props.bgColor,
        opacity: props.opacity,
        position: 'absolute',
        padding: 14,
        boxSizing: 'border-box',
        zIndex: 2000,
        ...props.style,
      }}
      onClick={onClicked}
    >
      {props.children}
    </div>
  );
};

MenuModal.defaultProps = {
  bgColor: COLORS.ANTHEM_MENU_BG,
  opacity: 0.85,
};
