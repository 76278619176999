import React, { useContext } from 'react';
import {
  SectionWithSideContent,
  SectionWithSideContentFullBg,
} from '../shared/Layout';
import { SideContent } from './SideContent';
import { Content } from './Content';
import { COLORS } from '../../constants';
import { FeaturesContext } from '../../contexts/features';
import imgBackground from '../../assets/img/workplace.png';
import Color from 'color2';

export let Workplace = ({ id = 'workplace', data = {} }) => {
  const features = useContext(FeaturesContext);

  if (features.active('fullBg')) {
    return (
      <SectionWithSideContentFullBg
        id={id}
        sideContentStyle={{
          backgroundColor: new Color(COLORS.RED).alpha(0.85),
        }}
        sideContent={<SideContent data={data} />}
        contentStyle={{
          paddingLeft: 0,
        }}
        content={<Content />}
        bgImage={imgBackground}
      />
    );
  } else {
    return (
      <SectionWithSideContent
        id={id}
        sideContentStyle={{
          backgroundColor: COLORS.RED,
        }}
        sideContent={<SideContent data={data} />}
        contentStyle={{
          paddingLeft: 0,
        }}
        content={<Content />}
      />
    );
  }
};

export default Workplace;
