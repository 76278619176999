import React from 'react';
import { Section } from '../shared/Layout';
import { SideContent } from './SideContent';
import { COLORS, sizes } from '../../constants';
// import { FlexColumn } from '../shared/Flexbox';
import { connect } from 'react-redux';
import { Grid } from './Grid';
import styled from 'styled-components';

const Background = styled.div`
  width: 100%;
  background-color: ${COLORS.GRAY_BLUE};
  flex: 1 1 100px;
`;

const ContentWrapper = styled.div`
  background-color: ${COLORS.GRAY_BLUE};

  @media (min-width: ${sizes.desktop}px) {
    display: ${props => (props.plans <= 3 ? 'flex' : 'block')};
  }
`;

export let CompareAlt = ({ plans }) => {
  return (
    <Section id="compareBenefits">
      <ContentWrapper plans={plans.length}>
        <SideContent />

        <Background>
          <Grid />
        </Background>
      </ContentWrapper>
    </Section>
  );
};
CompareAlt = connect(state => {
  return {
    plans: state.Data.plans.available.medical,
  };
})(CompareAlt);
