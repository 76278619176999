export const SHOW_MODAL_NETWORK = 'SHOW_MODAL_NETWORK';
export const showModal = (items, id) => {
  return {
    type: SHOW_MODAL_NETWORK,
    items,
    id,
  };
};

export const SET_MODAL_POS_NETWORK = 'SET_MODAL_POS_NETWORK';
export const setModalPos = (x = null, y = null) => {
  return dispatch => {
    dispatch({
      type: SET_MODAL_POS_NETWORK,
      pos: [x, y],
    });
  };
};
