import React from 'react';
import { BulletLine } from '../shared/Bullets';
import { SideParagraph } from '../shared/Layout';
import { SideTitle } from '../shared/Text';
import { connect } from 'react-redux';
import { FlexColumn } from '../shared/Flexbox';
import { Padding } from '../welcome/SideContent';
import { media } from '../../constants';
import styled from 'styled-components';
import { OrangeText } from '../shared/Text';
import Expandable from './Expandable';

const Title = styled(SideTitle)`
  margin-top: 55px;

  ${media.tablet`margin-top: 0;`}
`;

export let SideContent = ({ data, location, organization, ihcId }) => {
  return (
    <FlexColumn
      style={{
        flexGrow: 1,
      }}
    >
      <Padding>
        <Title>{ihcId === 'bsg' ? 'My' : 'Your'} Workplace</Title>

        <SideParagraph
          style={{
            marginTop: 20,
            marginBottom: 42,
          }}
        >
          Here&apos;s a place to go to find your workplace information
          that&apos;s there when you need it. Your quick guide for quick answers
          on HR and benefit programs, work policies, reference materials and
          other resources available to you through the{' '}
          <OrangeText>{organization.name}</OrangeText>{' '}
          {ihcId === 'reta' && (
            <>
              and <OrangeText>{location.name}</OrangeText>
            </>
          )}
          .
        </SideParagraph>

        {data.categories.map((cat, i) => {
          return (
            <Expandable expand={cat.expand || true} title={cat.title} key={i}>
              {cat.documents.map((doc, d) => {
                return (
                  <BulletLine
                    key={d}
                    onClick={() => doc.url && window.open(doc.url)}
                  >
                    {doc.title}
                  </BulletLine>
                );
              })}
            </Expandable>
          );
        })}
      </Padding>
    </FlexColumn>
  );
};

SideContent = connect(state => {
  return {
    organization: state.Data.trustor,
    location: state.Data.location,
    ihcId: state.Data.ihcId,
  };
})(SideContent);
