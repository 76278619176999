import React from 'react';
import { EnrollDeadlineMsg } from '../shared/Enrollment';
import { TextEmphasis, SideTitle } from '../shared/Text';
import { SideParagraph } from '../shared/Layout';
import { Padding } from '../welcome/SideContent';
import { sizes } from '../../constants';
import styled from 'styled-components';
import { WhatsNew } from './Content';

export const Mobile = styled.div`
  @media (min-width: ${sizes.tablet}px) {
    display: none;
  }
`;

const PadBottom = styled.div`
  height: 48px;
`;

export let SideContent = () => {
  return (
    <div>
      <Padding>
        <SideTitle>What&apos;s new this year</SideTitle>

        <SideParagraph
          style={{
            marginTop: 77,
          }}
        >
          Before we get into an overview of the benefits offered by your
          employer, let&apos;s take a look at &quot;what&apos;s new this
          year&quot;... things that could impact the enrollment decisions you
          make.
        </SideParagraph>

        <SideParagraph
          style={{
            marginTop: 20,
          }}
        >
          <TextEmphasis>
            <EnrollDeadlineMsg />
          </TextEmphasis>
        </SideParagraph>
      </Padding>

      <Mobile>
        <WhatsNew />
      </Mobile>

      <PadBottom />
    </div>
  );
};
