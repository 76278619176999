import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

export const EnrollStartDate = connect((state, ownProps) => {
  return {
    enroll: state.Data.enrollment,
    ...ownProps,
  };
})(({ enroll }) => {
  return (
    <span>{moment(enroll.dateStart, 'YYYY-MM-DD').format('MMMM D, YYYY')}</span>
  );
});

export const EnrollEndDate = connect((state, ownProps) => {
  return {
    enroll: state.Data.enrollment,
    ...ownProps,
  };
})(({ enroll }) => {
  return (
    <span>{moment(enroll.dateEnd, 'YYYY-MM-DD').format('MMMM D, YYYY')}</span>
  );
});

export const EnrollmentYear = connect((state, ownProps) => {
  return {
    enroll: state.Data.enrollment,
    ...ownProps,
  };
})(({ enroll }) => {
  return (
    <span>
      the {moment(enroll.dateEnd, 'YYYY-MM-DD').format('YYYY')} plan year
    </span>
  );
});

export const EnrollmentRange = connect((state, ownProps) => {
  return {
    enroll: state.Data.enrollment,
    ...ownProps,
  };
})(({ enroll }) => {
  return (
    <span>
      {moment(enroll.dateEnd, 'YYYY-MM-DD').format('YYYY')}-
      {moment(enroll.dateEnd, 'YYYY-MM-DD').add(1, 'year').format('YYYY')}
    </span>
  );
});

export let EnrollDeadlineMsg = ({ enrollment, show }) => {
  return (
    <div>
      {enrollment.type === 'ANNUAL' && show && (
        <span>
          Your enrollment deadline is <EnrollEndDate />.
        </span>
      )}
    </div>
  );
};

EnrollDeadlineMsg = connect(state => {
  return {
    enrollment: state.Data.enrollment,
    show: state.Data.enrollment.dateStart && state.Data.enrollment.dateEnd,
  };
})(EnrollDeadlineMsg);
