import React from 'react';
// import imgRetaLogo from 'assets/img/retaLogo.svg';
import { Flexbox } from '../shared/Flexbox';
import { connect } from 'react-redux';

export let Logo = ({ client, style }) => {
  return (
    <Flexbox
      style={{
        width: '100%',
        justifyContent: 'center',
        flex: '0 0 44px',
      }}
    >
      <Flexbox style={{ width: '100%', justifyContent: 'center', padding: 12 }}>
        <img
          style={{
            width: '100%',
            height: 'auto',
            ...style,
          }}
          src={client.images.logo.url}
          alt="logo"
        />
      </Flexbox>
    </Flexbox>
  );
};

Logo = connect(state => ({
  client: state.Client,
}))(Logo);
