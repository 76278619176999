import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { COLORS } from '../../../constants';

const colorOrange = '#cf552d';

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  background-color: #eef6e9;
  padding: 24px 42px;
  box-sizing: border-box;
`;

const H1 = styled.h1`
  // font-family: 'Roboto Condensed', sans-serif;
  font-size: 52px;
  font-weight: 200;
  color: ${colorOrange};
  letter-spacing: 0.05em;
  margin-bottom: 16px;
`;

const H2 = styled.h2`
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.07em;
  color: ${COLORS.TEXT_DARK};
`;

const H3 = styled.h3`
  font-size: 16px;
  font-weight: 500;
  color: ${colorOrange};
  line-height: 1.4;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  &:first-child {
    margin-right: 16px;
  }
`;

const Bullet = styled.li`
  color: ${COLORS.TEXT_DARK};
  font-size: 16px;
  list-style: disc;
  margin-left: 10px;
  line-height: 1.4;
  list-style-type: none;

  &::before {
    content: '\\2022';
    color: ${colorOrange};
    font-size: 24px;
    font-weight: bold;
    display: inline-block;
    width: 0.5em;
    top: 3px;
    position: relative;
    display: inline-block;
    margin-left: -0.5em;
  }
`;

const Button = styled.button`
  background: ${colorOrange};
  border: none;
  color: white;
  padding: 16px 32px;
  margin-top: 32px;
  font-size: 16px;
  cursor: pointer;
`;

const Paragraph = styled.div`
  color: ${COLORS.TEXT_DARK};
  line-height: 1.4;
`;

const Anchor = styled.a`
  color: ${colorOrange};
`;

export let Content = () => {
  return (
    <ContentWrapper>
      <Row
        style={{
          marginBottom: 32,
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ position: 'relative', top: -10 }}>
          <H1>
            Plan for a <br /> Healthy Smile
          </H1>
          <H2>Get a pre-treatment estimate</H2>
        </div>

        <div>
          <img
            style={{ width: 240 }}
            alt="man in circle"
            src="https://firebasestorage.googleapis.com/v0/b/forty8-ihc-e908.appspot.com/o/clients%2Freta%2Fevents%2F2023-oe%2Fcarriers%2Fdelta%2Fimages%2Fcontent%2FmanInCircle3.png?alt=media&token=1ac67448-6440-469d-97ba-9d7fc3a8786b"
          />
        </div>
      </Row>

      <Row>
        <Column>
          <H3>You might benefit from a pre- treatment estimate if you are:</H3>

          <div>
            <ul style={{ listStyle: 'none' }}>
              <Bullet>
                Planning dental work that will likely exceed $300, like a crown,
                wisdom tooth extraction, bridge, dentures or periodontal surgery
              </Bullet>
              <Bullet>Wondering if a procedure is covered by your plan</Bullet>
              <Bullet>
                Worried a procedure might exceed your annual plan maximum
              </Bullet>
              <Bullet>
                On a budget and need to plan your payment in advance
              </Bullet>
            </ul>
          </div>
        </Column>

        <Column>
          <H3>
            The pre-treatment estimate
            <br /> includes:
          </H3>

          <div>
            <ul>
              <Bullet>
                An overview of services covered by your dental plan, as well as
                those that are limited or excluded
              </Bullet>
              <Bullet>
                Planning dental work that will likely exceed $300, like a crown,
                wisdom tooth extraction, bridge, dentures or periodontal surgery
              </Bullet>
            </ul>
          </div>
        </Column>
      </Row>

      <Row style={{ marginBottom: 32 }}>
        <Button>Step by Step</Button>
      </Row>

      <Row>
        <H2>The pre-treatment estimate process</H2>
      </Row>

      <Row>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/forty8-ihc-e908.appspot.com/o/clients%2Freta%2Fevents%2F2023-oe%2Fcarriers%2Fdelta%2Fimages%2Fcontent%2Fchart2.png?alt=media&token=4aa7ab1e-58a9-46ee-b308-5edc77bf1a68"
          alt="chart"
        />
      </Row>

      <Row>
        <Paragraph>
          Have more questions about your plan? To visit Your{' '}
          <b>Dental Plan Support Guide</b>, click{' '}
          <Anchor href="https://www1.deltadentalins.com/members.html">
            here
          </Anchor>
          .
        </Paragraph>
      </Row>
    </ContentWrapper>
  );
};

Content = connect((state, ownProps) => {
  return {};
})(Content);
