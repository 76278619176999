import styled from 'styled-components/macro';
import imgBulletinBoard from '../../assets/img/bulletinBoard.png';
import { COLORS } from '../../constants';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  background-image: url(${imgBulletinBoard});
  color: #303030;
  padding-top: 60px;
  padding-left: 68px;
  padding-right: 75px;
  padding-bottom: 25px;
  box-sizing: border-box;
`;

export const Sticky = styled.div`
  width: 100%;
  height: 100%;

  & .fullname {
    font-style: italic;
    font-size: 15px;
    margin-bottom: 10px;
  }

  & .slug {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 10px;
  }

  & p {
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 10px;
  }

  & .list {
    margin-bottom: 3px;

    & .item {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      font-style: italic;
      cursor: pointer;

      & img {
        flex: 0 0 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 6px;
      }

      & div {
        flex: 1 1 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        background-color: rgba(0, 255, 255, 0.1);
      }
    }
  }
`;

export const Messages = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: black;
  overflow: hidden;

  & .top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 35px;
    border-bottom: 1px solid rgb(47, 38, 38);
    background-color: ${COLORS.RED};
    color: ${COLORS.TEXT};

    & h3 {
      font-size: 0.9em;
      text-transform: uppercase;
    }

    & .close {
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: pointer;
      color: ${COLORS.TEXT};
    }
  }

  & .main {
    flex: 1 1 150px;
    display: flex;
    overflow-y: auto;

    & .note {
      color: ${COLORS.TEXT_DARK};
      font-style: italic;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }

  & .left {
    flex: 1 1 200px;
    height: 100%;
    align-items: stretch;
    border-right: 1px solid rgb(47, 38, 38);
    overflow-y: auto;
    background-color: ${COLORS.RED};
    color: ${COLORS.TEXT};

    & .item {
      border-bottom: 1px solid rgb(47, 38, 38);
      padding: 16px;
      cursor: pointer;

      &.active {
        background-color: ${COLORS.ORANGE};
        color: ${COLORS.TEXT_DARK};

        &:hover {
          background-color: ${COLORS.ORANGE};
        }

        & .slug {
          color: #5a5a5a;
        }
      }

      &:hover {
        background-color: rgba(255, 179, 0, 0.31);
      }

      & .title {
        font-size: 0.9em;
        font-weight: bold;
        line-height: 1.6;
      }

      & .slug {
        font-size: 0.8em;
        font-style: italic;
        line-height: 1.6;
        color: #c5bdbd;
      }
    }
  }

  & .right {
    flex: 2 2 400px;
    height: 100%;
    padding: 24px;
    overflow-y: auto;

    & .title {
      font-size: 22px;
      font-weight: bold;
      line-height: 1.6;
      margin-bottom: 16px;
    }

    & .slug {
      font-size: 0.9em;
      font-style: italic;
      line-height: 1.6;
      color: #5a5a5a;
      margin-bottom: 24px;
    }
  }
`;

export const BodyStyle = styled.div`
  & h1 {
    color: #495b5e;
    font-family: Roboto;
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    box-sizing: border-box;
    line-height: 1.6;
    margin: 32px 0;

    &:first-child {
      margin-top: 0;
    }
  }

  & h2 {
    font-size: 18px;
    line-height: 1.6;
    color: #951a2f;
    font-family: Roboto;
    box-sizing: border-box;
    margin-top: 22px;
  }

  & h3 {
    font-family: Roboto;
    font-weight: bold;
    font-size: 17px;
    line-height: 1.6;
    color: #495b5e;
    margin-bottom: 6px;
    box-sizing: border-box;
  }

  & p,
  .unstyled-body {
    font-size: 16px;
    line-height: 1.6;
    margin: 0.8em 0;
    box-sizing: border-box;
  }

  & ul {
    list-style: none;
    list-style-position: outside;
    margin-bottom: 0.8em;

    & li {
      padding-left: 20px;
      text-indent: -20px;
      margin-bottom: 12px;
      line-height: 1.6em;

      &::before {
        content: '+';
        font-weight: bold;
        color: #951a2f;
        padding: 0 6px;
        line-height: 1.6em;
      }
    }

    & .public-DraftStyleDefault-depth1::before,
    & .public-DraftStyleDefault-depth2::before,
    & .public-DraftStyleDefault-depth3::before,
    & .public-DraftStyleDefault-depth4::before {
      content: '-';
    }

    & ul {
      padding-left: 20px;
      margin-bottom: 0;

      & li {
        &::before {
          content: '-';
        }
      }
    }
  }

  & ol {
    list-style: none;
    counter-reset: li;
    margin-bottom: 0.8em;

    & li::before {
      content: counter(li) '.';
      color: #951a2f;
      display: inline-block;
      padding: 0 6px;
      line-height: 1.6em;
    }

    & li {
      counter-increment: li;
    }
  }
`;
