import { RECEIVED_DATA } from '../actions/Data';
import _uniqWith from 'lodash.uniqwith';
import _isEqual from 'lodash.isequal';
import _has from 'lodash.has';

const initialState = {
  Plans: [],
};

const mash = (current, available, options = {}) => {
  return {
    available: available.map(m => {
      let hash = m.network && m.network.toLowerCase();

      if (options.isMedical) {
        hash = 'medical-' + m.network && m.network.toLowerCase();
      }

      if (options.isNonReta) {
        hash = 'voluntary';
      }

      return {
        id: m.id,
        network: m.network,
        networkFullName: m.networkFullName,
        description: m.description,
        images: m.images,
        category: m.category,
        hash: m.other ? 'voluntary' : hash,
        other: m.other,
      };
    }),
    current,
  };
};

const getLogos = plans => {
  if (!plans || !plans.length) {
    return [];
  }

  // filter for the small logos
  const images = plans.map(m => {
    if (m.images && m.images.logoSmall) {
      return {
        src: m.images.logoSmall.url,
        link: m.link,
        hash: m.hash,
      };
    }

    return undefined;
  });

  // return a unique list of images
  return _uniqWith(
    images.filter(i => i && i),
    _isEqual,
  );
};

const reKnownPlans = /DENTAL|VISION|LIFE|LTD|STD|AD&D|RX|WELLNESS|EAP/;

export const BenefitsNow = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVED_DATA: {
      const plans = {
        ...state,
        medical: mash(
          action.data.plans.current.medical,
          action.data.plans.available.medical,
          { isMedical: true },
        ),
        dental: mash(
          action.data.plans.current.dental,
          action.data.plans.available.dental.concat(
            action.data.plans.available.other.filter(
              p => p.category === 'DENTAL',
            ),
          ),
        ),
        rx: mash(
          action.data.plans.current.rx,
          action.data.plans.available.rx.concat(
            action.data.plans.available.other.filter(p => p.category === 'RX'),
          ),
        ),
        vision: mash(
          action.data.plans.current.vision,
          action.data.plans.available.vision.concat(
            action.data.plans.available.other.filter(
              p => p.category === 'VISION',
            ),
          ),
        ),
        life: mash(
          action.data.plans.current.life,
          action.data.plans.available.other
            .filter(p => p.category === 'LIFE')
            .concat(action.data.plans.available.life),
          { isNonReta: true },
        ),
        wellness: mash(
          (action.data.plans.current.wellness || []).concat(
            action.data.plans.current.webmd || [],
          ),
          action.data.plans.available.wellness,
        ),
        ltd: mash(
          action.data.plans.current.ltd,
          action.data.plans.available.other
            .filter(p => p.category === 'LTD')
            .concat(action.data.plans.available.ltd),
          { isNonReta: true },
        ),
        eap: mash(
          action.data.plans.current.other.filter(
            p => p.category === 'EAP' || /EAP/.test(p.description),
          ),
          action.data.plans.available.other.filter(
            p => p.category === 'EAP' || /EAP/.test(p.description),
          ),
          { isNonReta: true },
        ),
        other: mash(
          action.data.plans.current.other,
          // Merge in `other` benefits attached to the trustor
          _has(action.data, 'trustor.plans.available.other')
            ? action.data.plans.available.other.concat(
                action.data.trustor.plans.available.other,
              )
            : action.data.plans.available.other,
          { isNonReta: true },
        ),
        std: mash(
          action.data.plans.current.std,
          action.data.plans.available.other.filter(p => p.category === 'STD'),
          { isNonReta: true },
        ),
        rvo: mash(
          action.data.plans.current.rvo,
          action.data.plans.available.other.filter(p => p.category === 'RVO'),
        ),
      };

      let Plans = [];

      if (plans.medical.current.length || plans.medical.available.length) {
        Plans.push({
          description: 'Medical Plans',
          current: plans.medical.current,
          available: plans.medical.available,
          logos: getLogos(plans.medical.available),
          type: 'medical',
        });
      }

      if (plans.rx.current.length || plans.rx.available.length) {
        Plans.push({
          description: 'Pharmacy Benefits',
          current: plans.rx.current,
          available: plans.rx.available,
          logos: getLogos(plans.rx.available),
          type: 'rx',
        });
      }

      if (plans.dental.current.length || plans.dental.available.length) {
        Plans.push({
          description: 'Dental Plans',
          current: plans.dental.current,
          available: plans.dental.available,
          logos: getLogos(plans.dental.available),
          type: 'dental',
        });
      }

      if (plans.vision.current.length || plans.vision.available.length) {
        Plans.push({
          description: 'Vision Plans',
          current: plans.vision.current,
          available: plans.vision.available,
          logos: getLogos(plans.vision.available),
          type: 'vision',
        });
      }

      if (
        action.data.features.includes('showWellness') &&
        (plans.wellness.current.length || plans.wellness.available.length)
      ) {
        Plans.push({
          description: 'Wellness',
          current: plans.wellness.current,
          available: plans.wellness.available,
          logos: getLogos(plans.wellness.available),
          type: 'wellness',
        });
      }

      if (plans.life.current.length || plans.life.available.length) {
        Plans.push({
          description: 'Life and AD&D',
          current: plans.life.current,
          available: plans.life.available,
          logos: getLogos(plans.life.available),
          type: 'life',
        });
      }

      if (plans.ltd.current.length || plans.ltd.available.length) {
        Plans.push({
          description: 'Long-Term Disability',
          current: plans.ltd.current,
          available: plans.ltd.available,
          logos: getLogos(plans.ltd.available),
          type: 'ltd',
        });
      }

      if (plans.eap.current.length || plans.eap.available.length) {
        Plans.push({
          description: 'Employee Assistance Program',
          current: plans.eap.current,
          available: plans.eap.available,
          logos: getLogos(plans.eap.available),
          type: 'eap',
        });
      }

      /**
       * Filter the other plans
       */
      plans.other.current = plans.other.current.filter(
        p => !reKnownPlans.test(p.category) && !/EAP/.test(p.description),
      );
      plans.other.available = plans.other.available.filter(
        p => !reKnownPlans.test(p.category) && !/EAP/.test(p.description),
      );

      if (plans.other.current.length || plans.other.available.length) {
        Plans.push({
          description: 'Other Plans',
          current: plans.other.current,
          available: plans.other.available,
          logos: getLogos(plans.other.available),
          type: 'other',
        });
      }

      /**
       * Get a list of all current plans
       */
      let currentPlans = [];
      for (let key in action.data.plans.current) {
        currentPlans = currentPlans.concat(action.data.plans.current[key]);
      }

      /**
       * Get a list of all available plans
       */
      let availablePlans = [];
      for (let key in action.data.plans.available) {
        availablePlans = availablePlans.concat(
          action.data.plans.available[key],
        );
      }

      /**
       * Sort alphabetically by network (carrier) name
       */
      Plans.forEach(p => {
        p.available = p.available.sort((a, b) => {
          if (a.network < b.network) return -1;
          if (a.network > b.network) return 1;
          return 0;
        });
      });

      availablePlans = availablePlans.sort((a, b) => {
        if (a.network < b.network) return -1;
        if (a.network > b.network) return 1;
        return 0;
      });

      return {
        Plans,
        currentPlans,
        availablePlans,
        dentalBundledWithMedical:
          action.data.plans.available.dental.filter(
            p => p.bundledWith && p.bundledWith.includes('MEDICAL'),
          ).length > 0,
        visionBundledWithMedical:
          action.data.plans.available.vision.filter(
            p => p.bundledWith && p.bundledWith.includes('MEDICAL'),
          ).length > 0,
        showNoChangesCol: action.data.enrollment.type === 'ANNUAL',
      };
    }

    default:
      return state;
  }
};
