import { RECEIVED_DATA, SET_OPTIONS } from '../actions/Data';

const initialState = {
  bridgeHealth: false,
  vivante: false,
  showBridgeHealth: true,
  showVivante: true,
  showWW: false,
  showFitbit: false,
  showWebmd: false,
  showLivongo: false,
};

export const Options = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVED_DATA: {
      const available = action.data.plans.available;
      return {
        ...state,
        ...action.data.options,
        bridgeHealth:
          available.bridgeHealth && available.bridgeHealth.length
            ? true
            : false,
        vivante: available.vivante && available.vivante.length ? true : false,
        showLivongo:
          available.livongo && available.livongo.length ? true : false,
      };
    }

    case SET_OPTIONS:
      return {
        ...state,
        ...action.options,
      };

    default:
      return state;
  }
};
