import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { MenuModal } from '../shared/MenuModal';
import { SubMenu } from '../shared/SubMenu';
import { VideoMenu } from './VideoMenu';
import _has from 'lodash.has';
import styled from 'styled-components';
import { media } from '../../constants';
import { FeaturesContext } from '../../contexts/features';

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  background-repeat: no-repeat;
  background-position-x: ${props => props.positionX || 'center'};
  background-position-y: ${props => props.positionY || 'center'};
  background-image: ${props => `url(${props.backgroundImage})`};
  background-size: cover;

  ${media.tablet`
    display: none;
  `}
`;
export let Content = ({
  showModal,
  subModalX,
  subModalY,
  items,
  modalId,
  sideScrollY,
  ...props
}) => {
  const features = useContext(FeaturesContext);

  return (
    <ContentWrapper
      backgroundImage={
        !features.active('fullBg') &&
        _has(props.plan, 'images.background.url') &&
        props.plan.images.background.url
      }
      positionX={props.plan?.images?.background?.position?.x}
      positionY={props.plan?.images?.background?.position?.y}
    >
      {showModal && props.id === modalId && (
        <MenuModal
          className="modal-sub-menu"
          offset={{ x: subModalX, y: subModalY }}
          yOffset={subModalY}
          style={{
            maxWidth: 500,
            backgroundColor: _has(props.plan, 'colors.background.menu')
              ? props.plan.colors.background.menu
              : '#4377af',
          }}
        >
          <SubMenu items={items} />
        </MenuModal>
      )}

      {props.plan.hasOwnProperty('videoContent') && (
        <VideoMenu plan={props.plan} />
      )}
    </ContentWrapper>
  );
};

Content = connect((state, ownProps) => {
  return {
    showModal: state.Networks.modal.visible,
    subModalX: state.Networks.modal.pos.x,
    subModalY: state.Networks.modal.pos.y + 28,
    items: state.Networks.modal.items,
    modalId: state.Networks.modal.id,
    ...ownProps,
  };
})(Content);
