import React from 'react';
import { SectionWithSideContent } from '../shared/Layout';
import { SideContent } from './SideContent';
import { Content } from './Content';
import { COLORS } from '../../constants';

export let DTool = () => {
  return (
    <SectionWithSideContent
      id="decision-tool"
      sideContentStyle={{
        backgroundColor: COLORS.CLEARVIEW_RED,
      }}
      sideContent={<SideContent />}
      contentStyle={{
        paddingLeft: 0,
      }}
      content={<Content />}
    />
  );
};
