import { EXPAND_ENROLL_MENU, CLOSE_ENROLL_MENU } from '../actions/RetaEnroll';

const initialState = {
  expanded: true,
};

const _hideAll = state => {
  return {
    ...state,
    expanded: true,
  };
};

export const RetaEnroll = (state = initialState, action) => {
  switch (action.type) {
    case EXPAND_ENROLL_MENU:
      return {
        ...state,
        expanded: true,
      };

    case CLOSE_ENROLL_MENU:
      return _hideAll(state);

    case 'HIDE_ALL_MENUS':
      return _hideAll(state);

    default:
      return { ...state };
  }
};
