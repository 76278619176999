import React from 'react';
import { FlexColumn } from '../shared/Flexbox';
import { TextEmphasis } from '../shared/Text';
import { SideParagraph } from '../shared/Layout';
import { Header } from './MenuClient';

export let MenuBSGDOC = () => {
  return (
    <FlexColumn>
      <Header>
        <TextEmphasis>A message from Kevin A. Carr</TextEmphasis>
      </Header>

      <div>
        <SideParagraph>
          Vivamus eget eros. Phasellus congue, sapien ac iaculis feugiat, lacus
          lacus accumsan lorem, quis volutpat justo turpis ac mauris.
        </SideParagraph>

        <SideParagraph>
          Duis velit magna, scelerisque vitae, varius ut, aliquam vel, justo.
          Proin ac augue. Nullam auctor lectus vitae arcu. Vestibulum porta
          justo placerat purus. Ut sem nunc, vestibulum nec, sodales vitae,
          vehicula eget, ipsum. Sed nec tortor. Aenean malesuada. Nunc
          convallis, massa eu vestibulum commodo, quam mauris interdum arcu, at
          pellentesque diam metus ut nulla. Vestibulum eu dolor sit amet lacus
          varius fermentum. Morbi dolor enim, pulvinar eget, lobortis ac,
          fringilla ac, turpis. Duis ac erat. Etiam consequat. Integer sed est
          eu elit pellentesque.
        </SideParagraph>

        <SideParagraph>--Kevin A. Carr--</SideParagraph>
      </div>
    </FlexColumn>
  );
};
