import React from "react";
import { COLORS } from "../../constants";
import { FlexColumn } from "../shared/Flexbox";

export function HeaderCell(props) {
  return (
    <FlexColumn
      style={{
        justifyContent: "flex-end",
        color: COLORS.RED,
        backgroundColor: COLORS.ORANGE,
        fontSize: 12,
        fontFamily: "Roboto Condensed",
        fontWeight: "bold",
        lineHeight: "16px",
        textTransform: "uppercase",
        padding: "4px 12px",
        boxSizing: "border-box",
        borderRight: "1px solid #ffc96b",
        ...props.style,
      }}
    >
      <div>{props.children}</div>
    </FlexColumn>
  );
}
