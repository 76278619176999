import { SHOW_ABOUT_MODAL, CLOSE_ABOUT_MODAL } from "../actions/AboutPlans";

const initialState = {
  show: false,
};

export const AboutPlans = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ABOUT_MODAL:
      return {
        ...state,
        show: true,
      };

    case CLOSE_ABOUT_MODAL:
      return {
        ...state,
        show: false,
      };

    default:
      return {
        ...state,
      };
  }
};
