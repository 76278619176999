import React from 'react';
import { Flexbox } from '../shared/Flexbox';
import { HeaderCell } from './HeaderCell';
import { connect } from 'react-redux';

const _HeaderRow = ({ isPriest, isNun, showNoChangesCol, isNewHire }) => {
  return (
    <Flexbox
      style={{
        alignItems: 'stretch',
        aligntContent: 'baseline',
        width: '100%',
      }}
    >
      <HeaderCell
        style={{
          minHeight: 60,
          flex: '3 3 200px',
        }}
      >
        Benefit Plans that are Available to You
      </HeaderCell>

      {!isNewHire && (
        <HeaderCell
          style={{
            flex: '2 2 150px',
          }}
        >
          What plans you have now
        </HeaderCell>
      )}

      {!isPriest && !isNun && (
        <HeaderCell
          style={{
            flex: '1 1 115px',
          }}
        >
          Who&apos;s Covered
        </HeaderCell>
      )}

      {showNoChangesCol && !isNewHire && (
        <HeaderCell
          style={{
            flex: '2 2 145px',
          }}
        >
          What you&apos;ll have next plan year if you don&apos;t make changes
        </HeaderCell>
      )}
    </Flexbox>
  );
};

export const HeaderRow = connect(state => {
  return {
    isPriest: state.Data.is.priest ? true : false,
    isNun: state.Data.is.nun ? true : false,
    showNoChangesCol: state.BenefitsNow.showNoChangesCol,
    isNewHire: state.Data.enrollment.type === 'NEW_HIRE',
  };
})(_HeaderRow);
