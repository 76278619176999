import React, { useContext } from 'react';
import {
  SectionWithSideContent,
  SectionWithSideContentFullBg,
} from '../shared/Layout';
import { SideContent } from './SideContent';
import { Content } from './Content';
import { COLORS } from '../../constants';
import { FeaturesContext } from '../../contexts/features';
import imgBackground from '../../assets/img/other/background.png';
import Color from 'color2';

export let NonReta = () => {
  const features = useContext(FeaturesContext);

  if (features.active('fullBg')) {
    return (
      <SectionWithSideContentFullBg
        id="voluntary"
        sideContentStyle={{
          backgroundColor: new Color(COLORS.GRAY_BLUE).alpha(0.85),
        }}
        sideContent={<SideContent />}
        contentStyle={{
          paddingLeft: 0,
        }}
        content={<Content />}
        bgImage={imgBackground}
      />
    );
  } else {
    return (
      <SectionWithSideContent
        id="voluntary"
        sideContentStyle={{
          backgroundColor: COLORS.GRAY_BLUE,
        }}
        sideContent={<SideContent />}
        contentStyle={{
          paddingLeft: 0,
        }}
        content={<Content />}
      />
    );
  }
};
