import { HIDE_WELCOME_MENUS, SHOW_WELCOME_MENU } from '../actions/Welcome';

const initState = {
  menus: {
    reta: {
      active: false,
    },
    trustor: {
      active: false,
    },
    location: {
      active: false,
    },
    congrats: {
      active: false,
    },
  },
  clientWelcomeMessageUrl: null,
};

export const Welcome = (state = initState, action) => {
  switch (action.type) {
    case 'RECEIVED_DATA': {
      return {
        ...state,
        clientWelcomeMessageUrl: action.data.event.clientWelcomeMessageUrl,
      };
    }

    case HIDE_WELCOME_MENUS: {
      let newState = { ...state };

      for (let menu in newState.menus) {
        newState.menus[menu].active = false;
      }

      return newState;
    }

    case SHOW_WELCOME_MENU: {
      let newState = { ...state };

      for (let menu in newState.menus) {
        newState.menus[menu].active = false;
      }

      newState.menus[action.menuName].active = true;

      return newState;
    }

    default:
      return { ...state };
  }
};
