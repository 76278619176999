export const SET_PERSONA = 'SET_PERSONA';
export const setPersona = json => {
  return {
    type: SET_PERSONA,
    json,
  };
};

export const SET_VIM = 'SET_VIM';
export const setVim = value => {
  window.localStorage.setItem('_editor', JSON.stringify({ vim: value }));
  return {
    type: SET_VIM,
    value,
  };
};

export const SET_OUTPUT = 'SET_OUTPUT';
export const setOutput = value => {
  return {
    type: SET_OUTPUT,
    value,
  };
};

export const FETCHING_PRESENTATION = 'FETCHING_PRESENTATION';
export const fetchingPresentation = () => {
  return {
    type: FETCHING_PRESENTATION,
  };
};

export const FETCH_ERROR_PRESENTATION = 'FETCH_ERROR_PRESENTATION';
export const fetchError = message => {
  return {
    type: FETCH_ERROR_PRESENTATION,
    message,
  };
};

export const FETCHED_PRESENTATION = 'FETCHED_PRESENTATION';
export const fetched = () => {
  return {
    type: FETCHED_PRESENTATION,
  };
};
