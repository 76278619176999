import React from 'react';
import { COLORS } from '../../constants';
import { FlexColumn } from '../shared/Flexbox';
import { select } from '../../actions/Menu';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { media } from '../../constants';

const Wrapper = styled(FlexColumn)`
  margin-bottom: 7px;
  background-color: ${COLORS.NAVITEM_BG};
  color: ${COLORS.NAVITEM_TEXT};
  cursor: pointer;
  font-size: 14px;
  justify-content: center;
  align-items: flex-start;
  padding: 5px;
  padding-left: ${props => (props['data-expandable'] ? '0' : '11')}px;
  padding-right: 8px;
  box-sizing: border-box;
  text-align: left;

  ${media.tablet`
    font-size: 14px;
    padding-left: ${props => (props['data-expandable'] ? '8' : '20')}px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 8x;
    margin-bottom: 5px;
  `}
`;

export let NavItem = props => {
  return (
    <Wrapper
      style={Object.assign(
        {},
        props.menu.selected && {
          backgroundColor: COLORS.ORANGE,
          color: 'white',
        },
        props.activeStyle,
        props.style,
      )}
      data-expandable={props.expandable}
      onClick={
        props.onClick ? props.onClick : () => props.onSelect(props.menu.id)
      }
      ref={props.ref}
    >
      {props.children}
    </Wrapper>
  );
};

NavItem = connect(null, dispatch => {
  return {
    onSelect: id => dispatch(select(id)),
  };
})(NavItem);
