import React from 'react';
import './Spinner.css';
import { COLORS } from '../../constants';

export const Spinner = props => {
  return (
    <div
      className="spinner"
      style={{
        backgroundColor: COLORS.RED,
        ...props.style,
      }}
    ></div>
  );
};
