import React from "react";
import { SectionWithSideContent } from "../shared/Layout";
import { SideContent } from "./SideContent";
import { BenefitsNowContent } from "./Content";
import { COLORS } from "../../constants";
import { connect } from "react-redux";
import { sizes } from "../../constants";

export class _BenefitsNow extends React.Component {
  render() {
    return (
      <SectionWithSideContent
        id="benefitsNow"
        sideContentStyle={Object.assign(
          {
            backgroundColor: COLORS.GRAY_BLUE,
          },
          this.props.x > sizes.tablet && {
            width: 310,
            flex: "0 0 310px",
          }
        )}
        sideContent={<SideContent />}
        contentStyle={{
          backgroundColor: COLORS.GRAY_BLUE,
          paddingLeft: 0,
        }}
        content={<BenefitsNowContent />}
      />
    );
  }
}

export const BenefitsNow = connect((state) => {
  return {
    width: state.Window.x,
  };
})(_BenefitsNow);
