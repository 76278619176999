import React from 'react';
import { SideParagraph } from '../shared/Layout';
import { TextEmphasis, OrangeText, SideTitle } from '../shared/Text';
import { EnrollDeadlineMsg, EnrollmentYear } from '../shared/Enrollment';
import { FullName } from '../shared/PII';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { COLORS, sizes } from '../../constants';
import { Padding } from '../welcome/SideContent';
import { Logo } from './Logo';
import { Spacer } from '../shared/Layout';

const Mobile = styled.div`
  @media (min-width: ${sizes.tablet}px) {
    display: none;
  }
`;

const Canvas = styled.div`
  background-color: white;
  margin-bottom: 36px;
`;

const Header = styled.div`
  color: ${COLORS.RED};
  font-size: 16px;
  font-family: Roboto Condensed;
  font-weight: bold;
  line-height: 16px;
  text-transform: uppercase;
  box-sizing: border-box;
`;

const GroupName = styled.div`
  color: white;
  font-family: Roboto Condensed;
  font-size: 26px;
`;

const Center = styled.div`
  text-align: center;
`;

const BenefitsAvailable = ({ logos }) => {
  return (
    <div>
      <Header>Benefit Plans that are Available to You</Header>

      <Center
        style={{
          paddingTop: 25,
          paddingBottom: 25,
        }}
      >
        {logos.map(
          (logo, i) =>
            logo && (
              <Logo
                key={i}
                src={logo.src}
                onClick={() => {
                  if (logo.link) {
                    window.open(logo.link);
                  }

                  if (logo.hash) {
                    window.location.hash = logo.hash;
                  }
                }}
              />
            ),
        )}

        {logos.length === 0 && <CellText>No Plans Available</CellText>}
      </Center>
    </div>
  );
};

const CellText = styled.div`
  font-family: Roboto Condensed;
  font-size: 18px;
  color: ${COLORS.TEXT_DARK};
  padding-top: 25px;
  padding-bottom: 25px;
  box-sizing: border-box;
`;

const BenefitsCurrent = ({ current }) => {
  return (
    <div>
      <Header>What plans you have now</Header>

      <Center>
        {current.map((cur, i) => (
          <CellText key={i}>{cur.description}</CellText>
        ))}

        {current.length === 0 && <CellText>Not enrolled</CellText>}
      </Center>
    </div>
  );
};

const Coverage = ({ current }) => {
  return (
    <div>
      <Header>Who&apos;s Covered</Header>

      <Center>
        {current.map((cur, i) => {
          if (/Waive|Not enrolled/i.test(cur.tier)) {
            return <CellText key={i}>No coverage</CellText>;
          }

          return <CellText key={i}>{cur.tier}</CellText>;
        })}

        {current.length === 0 && <CellText>No coverage</CellText>}
      </Center>
    </div>
  );
};

const NoChange = ({ current }) => {
  return (
    <div>
      <Header>
        What you&apos;ll have next plan year if you don&apos;t make changes
      </Header>

      <Center>
        {current.map((cur, i) => {
          switch (cur.noChange) {
            case 'SAME_COVERAGE':
              return <CellText key={i}>Same coverage</CellText>;
            case 'SAME_BENEFITS':
              return <CellText key={i}>Same benefits</CellText>;
            case 'NO_COVERAGE':
              return <CellText key={i}>No coverage</CellText>;
            case 'EMPLOYEE_ONLY_COVERAGE':
              return <CellText key={i}>Employee only coverage</CellText>;
            case 'NEW_PLAN':
              return <CellText key={i}>Defaults to a new plan</CellText>;
            case 'DISCONTINUED_SIMILAR':
              return (
                <CellText key={i}>
                  Plan discontinued. You will be placed In a similar plan.
                </CellText>
              );
            case 'DISCONTINUED':
              return (
                <CellText key={i}>
                  Plan discontinued and not available.
                </CellText>
              );
            case 'POSITIVE_ENROLLMENT':
              return (
                <CellText key={i}>A plan will be picked for you.</CellText>
              );
            default:
              return <CellText key={i}>{cur.noChange} </CellText>;
          }
        })}

        {!current.length && <CellText>No coverage</CellText>}
      </Center>
    </div>
  );
};

let Group = ({ isPriest, isNun, showNoChangesCol, ...props }) => {
  return (
    <div>
      <Padding>
        <GroupName>{props.description}</GroupName>
      </Padding>

      <Canvas>
        <Padding style={{ paddingBottom: 0 }}>
          <BenefitsAvailable logos={props.logos} />

          <BenefitsCurrent current={props.current} />

          {!isPriest && !isNun && <Coverage current={props.current} />}

          {showNoChangesCol && <NoChange current={props.current} />}
        </Padding>
      </Canvas>
    </div>
  );
};

Group = connect((state, ownProps) => {
  return {
    isPriest: state.Data.isPriest ? true : false,
    isNun: state.Data.isNun ? true : false,
    showNoChangesCol: state.BenefitsNow.showNoChangesCol,
    ...ownProps,
  };
})(Group);

export let SideContent = ({
  isOE,
  currentPlans,
  dentalBundledWithMedical,
  visionBundledWithMedical,
  Plans,
  isNewHire,
  ihcId,
}) => {
  return (
    <div>
      <Padding>
        {!isOE && !isNewHire && currentPlans.length > 0 && (
          <div>
            <SideTitle>
              <OrangeText>
                <FullName />,
              </OrangeText>{' '}
              these are the benefits you have now.
            </SideTitle>

            <SideParagraph
              style={{
                marginTop: 32,
              }}
            >
              Here are the plans made available to you during the last open
              enrollment and your current enrollment status. You will not be
              able to change your coverage status until the next open enrollment
              period unless you have a qualified life event, such as having a
              baby or getting married that allows you to make changes before
              then. Check with your benefits contact for more information on
              qualifying life events.
            </SideParagraph>
          </div>
        )}

        {!isOE && !isNewHire && currentPlans.length === 0 && (
          <div>
            <SideTitle>
              <OrangeText>
                <FullName />,
              </OrangeText>{' '}
              these are the benefits made available for this year.
            </SideTitle>

            <SideParagraph
              style={{
                marginTop: 32,
              }}
            >
              Here are the plans made available to you during the last open
              enrollment. You are currently not enrolled in any of these plans.
              You will not be able to enroll in these benefits until the next
              open enrollment period unless you have a qualified life event
              (such as having a baby or getting married) that allows you to make
              enrollment changes before then. Check with your benefits contact
              for more information on qualifying life events.
            </SideParagraph>
          </div>
        )}

        {/* New Hire */}
        {isNewHire && (
          <div>
            <SideTitle>
              <OrangeText>
                <FullName />,
              </OrangeText>{' '}
              here are the benefits available to you.
            </SideTitle>

            <SideParagraph
              style={{
                marginTop: 32,
              }}
            >
              Here you’ll find all the plans available to you as a new hire or
              newly eligible employee. After you enroll, you won’t be able to
              make changes until the next open enrollment period unless you have
              a qualified life event, such as having a baby or getting married
              that allows you to make changes before then. Check with your
              benefits contact for more information on qualifying life events.
            </SideParagraph>
          </div>
        )}

        {isOE && currentPlans.length === 0 && (
          <div>
            <SideTitle>
              <OrangeText>
                <FullName />,
              </OrangeText>{' '}
              these are the benefits you have now.
            </SideTitle>

            <SideParagraph
              style={{
                marginTop: 32,
              }}
            >
              Here you can see all the benefit plans available to you, your
              enrollment status, and the coverage you’ll have if you don’t make
              changes during your enrollment period.
            </SideParagraph>
          </div>
        )}

        {isOE && currentPlans.length > 0 && (
          <div>
            <SideTitle>
              <OrangeText>
                <FullName />,
              </OrangeText>{' '}
              these are the benefits available to you.
            </SideTitle>

            <SideParagraph
              style={{
                marginTop: 32,
              }}
            >
              Here you can see all the benefit plans available to you, your
              enrollment status and how your coverage is affected for{' '}
              <EnrollmentYear /> if you don&apos;t make changes during this open
              enrollment period.
            </SideParagraph>
          </div>
        )}

        {ihcId === 'reta' &&
          dentalBundledWithMedical &&
          visionBundledWithMedical && (
            <div>
              <SideParagraph
                style={{
                  marginTop: 32,
                }}
              >
                Your Diocese has decided to offer members a bundled plan that
                means when you enroll in a Reta Medical Plan, you are also
                automatically enrolling in the Reta Dental and Visions plans as
                well.
              </SideParagraph>
            </div>
          )}

        {ihcId === 'reta' &&
          dentalBundledWithMedical &&
          !visionBundledWithMedical && (
            <div>
              <SideParagraph
                style={{
                  marginTop: 32,
                }}
              >
                Your Diocese has decided to offer members a bundled plan that
                means when you enroll in a Reta Medical Plan, you are also
                automatically enrolling in the Reta Dental plan as well.
              </SideParagraph>
            </div>
          )}

        {ihcId === 'reta' &&
          !dentalBundledWithMedical &&
          visionBundledWithMedical && (
            <div>
              <SideParagraph
                style={{
                  marginTop: 32,
                }}
              >
                Your Diocese has decided to offer members a bundled plan that
                means when you enroll in a Reta Medical Plan, you are also
                automatically enrolling in the Reta Vision plan as well
              </SideParagraph>
            </div>
          )}

        {/* Deadline */}
        {isOE && (
          <SideParagraph
            style={{
              marginTop: 20,
            }}
          >
            <TextEmphasis>
              <EnrollDeadlineMsg />
            </TextEmphasis>
          </SideParagraph>
        )}
      </Padding>

      <Mobile>
        <Spacer />
        {Plans.map((plan, i) => {
          if (!plan.available.length && !plan.current.length) {
            console.debug(
              `No plans available for "${plan.description}" Skipping.`,
            );
            console.debug('Plan:', plan);
            return <></>;
          }

          return (
            <Group
              key={i}
              id={i}
              description={plan.description}
              current={plan.current}
              available={plan.available}
              logos={plan.logos}
            />
          );
        })}
      </Mobile>
    </div>
  );
};
SideContent = connect(state => {
  return {
    isOE: state.Data.enrollment.type === 'ANNUAL',
    isNewHire: state.Data.enrollment.type === 'NEW_HIRE',
    currentPlans: state.BenefitsNow.currentPlans,
    dentalBundledWithMedical: state.BenefitsNow.dentalBundledWithMedical,
    visionBundledWithMedical: state.BenefitsNow.visionBundledWithMedical,
    Plans: state.BenefitsNow.Plans,
    ihcId: state.Data.ihcId,
  };
})(SideContent);
