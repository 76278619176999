import React from 'react';
import { NavItem } from './NavItem';
import { Expandable } from './Expandable';
import { connect } from 'react-redux';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 175px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  background-color: white;
  overflow-y: auto;

  & > div {
    flex: 0 0;
  }
`;

export let Navigator = ({ menus, language, features }) => {
  return (
    <Wrapper>
      {menus.map((menu, i) => {
        // check for active feature flags
        if (menu.featureFlag) {
          if (!features.active(menu.featureFlag)) {
            console.debug(
              'Feature flag not enabled for menu item:',
              menu.featureFlag,
            );
          }
        }

        if (menu.hasOwnProperty('menus')) {
          return (
            <Expandable
              key={i}
              menu={menu}
              style={Object.assign(
                {},
                i === menus.length - 1 && { marginBottom: 0 },
              )}
            >
              {menu.name[language]}
            </Expandable>
          );
        }

        return (
          <NavItem
            key={i}
            menu={menu}
            style={Object.assign(
              {},
              i === menus.length - 1 && { marginBottom: 0 },
              menu.style && menu.style,
            )}
          >
            {menu.name[language]}
          </NavItem>
        );
      })}
    </Wrapper>
  );
};

Navigator = connect(
  state => {
    return {
      menus: state.Menu.menus,
      language: state.Language.current.toLowerCase(),
      features: state.Features.features,
    };
  },
  null,
  null,
  {
    areStatePropsEqual: () => false,
  },
)(Navigator);
