export const SHOW_ABOUT_MODAL = 'SHOW_ABOUT_MODAL';
export const show = () => {
  return {
    type: SHOW_ABOUT_MODAL,
  };
};

export const CLOSE_ABOUT_MODAL = 'CLOSE_ABOUT_MODAL';
export const close = () => {
  return {
    type: CLOSE_ABOUT_MODAL,
  };
};
