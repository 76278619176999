import React, { useState, useEffect } from 'react';
import imgExpand from '../../assets/img/expand.svg';
import styled from 'styled-components';
import { SideTitle } from '../shared/Text';

const Icon = styled.img`
  transform: rotate(180deg);
  height: 12px;

  &.expanded {
    transform: rotate(0deg);
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

const Expandable = ({ title, children, expand = true }) => {
  const [expanded, setExpanded] = useState(expand);

  useEffect(() => {
    setExpanded(expand);
  }, [expand]);

  const toggle = () => setExpanded(!expanded);

  return (
    <div>
      <Row onClick={toggle}>
        {title.length > 0 && (
          <>
            <SideTitle style={{ fontSize: 17, margin: '12px 0' }}>
              {title}
            </SideTitle>
            <Icon className={expanded ? 'expanded' : ''} src={imgExpand} />
          </>
        )}
      </Row>
      {expanded && <div>{children}</div>}
    </div>
  );
};
export default Expandable;
