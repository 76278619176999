import React from 'react';
import ErrorMsg from './ErrorMsg';
import { connect } from 'react-redux';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // static getDerivedStateFromError(error) {
  //   // Update state so the next render will show the fallback UI.
  //   return { hasError: true, error };
  // }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // this.setState({ error, info });
    this.props.onSetError(error.message, error.stack, info.componentStack);
  }

  render() {
    const { hasError } = this.props;

    if (hasError) {
      return <ErrorMsg />;
    }

    return this.props.children;
  }
}

const mapStateToProps = state => {
  return {
    hasError: state.Error.hasError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetError: (message, stack, componentStack) =>
      dispatch({
        type: 'SET_ERROR',
        message,
        stack,
        componentStack,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorBoundary);
