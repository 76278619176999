import React from 'react';
import { SideParagraph } from './Layout';
import { Text } from './Text';

export let MenuItem = props => {
  return (
    <div
      style={{
        marginBottom: 11,
        cursor: 'pointer',
        ...props.style,
      }}
      onClick={props.onClick}
    >
      <SideParagraph
        style={{
          margin: 0,
        }}
      >
        <Text dangerouslySetInnerHTML={{ __html: props.children }} />
      </SideParagraph>
    </div>
  );
};

MenuItem.defaultProps = {
  onClick: () => console.log('Click!'),
};
