import React from 'react';
import { FlexColumn } from '../shared/Flexbox';
import { TextEmphasis } from '../shared/Text';
import { SideParagraph } from '../shared/Layout';
import { Header } from './MenuClient';

export let MenuBSGETF = () => {
  return (
    <FlexColumn>
      <Header>
        <TextEmphasis>A message from Robert Conlin</TextEmphasis>
      </Header>

      <div>
        <SideParagraph>
          Turpis lacus, tristique sit amet, convallis sollicitudin, commodo a,
          purus. Nulla vitae eros a diam blandit mollis. Proin luctus feugiat
          eros. Pellentesque habitant morbi tristique senectus et netus et
          malesuada fames ac turpis egestas. Duis ultricies urna. Etiam enim
          urna, pharetra suscipit, varius et, congue quis, odio. Donec lobortis,
          elit bibendum euismod faucibus, velit nibh egestas libero, vitae
          pellentesque elit augue ut massa.
        </SideParagraph>

        <SideParagraph>
          Nulla consequat erat at massa. Vivamus id mi. Morbi purus enim,
          dapibus a, facilisis non, tincidunt at, enim. Vestibulum ante ipsum
          primis in faucibus orci luctus et ultrices posuere cubilia Curae; Duis
          imperdiet eleifend arcu. Cras magna ligula, consequat.
        </SideParagraph>

        <SideParagraph>--Kevin A. Carr--</SideParagraph>
      </div>
    </FlexColumn>
  );
};
