import React from 'react';
import { FlexColumn } from '../shared/Flexbox';
import { TextEmphasis } from '../shared/Text';
import { SideParagraph } from '../shared/Layout';
import { connect } from 'react-redux';
import { LocationName, TrustorName } from '../shared/PII';
import { Header } from './MenuClient';

export let MenuLocation = ({ message, isOE }) => {
  return (
    <FlexColumn>
      <Header>
        <TextEmphasis>
          A message from <LocationName />
        </TextEmphasis>
      </Header>

      {/* Default Message */}
      {isOE && !message.length && (
        <div>
          <SideParagraph>
            We work with <TrustorName /> and Reta to manage your benefits. And
            while these organizations do the heavy lifting to make the plans
            available and streamline enrollment, we want you to know we’re here
            to help.
          </SideParagraph>

          <SideParagraph>
            If you want guidance understanding your options or making your
            enrollment decisions, and you can’t find it here, feel free to
            contact us. You’ll find contact information on the last section of
            this Reta Benefits Center.
          </SideParagraph>
        </div>
      )}

      {isOE &&
        message.map((line, i) => {
          return <SideParagraph key={i}>{line}</SideParagraph>;
        })}

      {!isOE && message.length === 0 && (
        <div>
          <SideParagraph>
            We work with <TrustorName /> and Reta to manage your benefits. And
            while these organizations do the heavy lifting to make the plans
            available, we want you to know we’re here to help.
          </SideParagraph>

          <SideParagraph>
            If you’d like some guidance to make the most of your benefits, and
            you can’t find it here, feel free to contact us. You’ll find contact
            information on the last section of this Reta Benefits Center.
          </SideParagraph>
        </div>
      )}
    </FlexColumn>
  );
};

MenuLocation = connect(state => {
  return {
    message: state.Data.location.message,
    isOE: state.Data.enrollment.type === 'ANNUAL',
  };
})(MenuLocation);
