import { Padding } from '../welcome/SideContent';
import { Text } from '../shared/Text';
import styled from 'styled-components/macro';
import { Item } from './SideContent';

const YellowText = styled(Text)`
  color: rgb(255, 177, 30);
  font-weight: bold;
`;

const Paragraph = styled.div`
  margin: 16px 0;
`;

const FooterMenuKaiser = ({ plan, language, iconType, id }) => {
  const menuItems = [
    {
      english: {
        title: 'Total Health Assessment (THA)',
        menus: [
          {
            title: 'Take Your Total Health Assessment',
            url:
              'https://healthy.kaiserpermanente.org/northern-california/health-wellness/health-assessment?kp_shortcut_referrer=kp.org/tha',
          },
          {
            title: 'FAQ About Total Health Assessment',
            url:
              'https://firebasestorage.googleapis.com/v0/b/forty8-ihc-e908.appspot.com/o/clients%2Freta%2Fevents%2F2021-oe%2Fcarriers%2Fkaiser%2F411319207_WFH-HLP-THA-FAQ-Flyer_SCM_EW_2019_Flr_r1d_ATC_ja-rl_HiRes.pdf?alt=media&token=accfa52c-c19a-4019-9597-cdfae949cfe9',
          },
        ],
      },
      spanish: {
        title: 'Evaluación completa de salud',
        menus: [
          {
            title: 'Hágase una evaluación completa de la salud',
            url:
              'https://healthy.kaiserpermanente.org/northern-california/health-wellness/health-assessment?kp_shortcut_referrer=kp.org/tha',
          },
          {
            title:
              'Preguntas frecuentes acerca de la evaluación completa de la salud',
            url:
              'https://firebasestorage.googleapis.com/v0/b/forty8-ihc-e908.appspot.com/o/clients%2Freta%2Fevents%2F2021-oe%2Fcarriers%2Fkaiser%2F411319207_WFH-HLP-THA-FAQ-Flyer_SCM_EW_2019_Flr_r1d_ATC_ja-rl_HiRes.pdf?alt=media&token=accfa52c-c19a-4019-9597-cdfae949cfe9',
          },
        ],
      },
    },
    {
      english: {
        title: 'Healthy Living',
        menus: [
          {
            title: 'Healthy Lifestyle Programs',
            url:
              'https://healthy.kaiserpermanente.org/northern-california/health-wellness/healthy-lifestyle-programs/?kp_shortcut_referrer=kp.org/healthylifestyles',
          },
          {
            title: 'Health Classes',
            url:
              'https://healthy.kaiserpermanente.org/northern-california/health-wellness/classes-programs?kp_shortcut_referrer=kp.org/classes',
          },
          {
            url:
              'https://healthy.kaiserpermanente.org/northern-california/health-wellness/fitness-deals',
            title: 'Resources to Help You Stay Active and Fit',
          },
        ],
      },
      spanish: {
        title: 'Estilo de vida saludable',
        menus: [
          {
            title: 'Programas de estilo de vida saludable',
            url:
              'https://healthy.kaiserpermanente.org/northern-california/health-wellness/healthy-lifestyle-programs/?kp_shortcut_referrer=kp.org/healthylifestyles',
          },
          {
            title: 'Clases de salud',
            url:
              'https://healthy.kaiserpermanente.org/northern-california/health-wellness/classes-programs?kp_shortcut_referrer=kp.org/classes',
          },
          {
            url:
              'https://healthy.kaiserpermanente.org/northern-california/health-wellness/fitness-deals',
            title: 'Recursos para que se mantenga activo y en forma',
          },
        ],
      },
    },
    {
      english: {
        title: 'Get Wellness Support',
        menus: [
          {
            title: 'Wellness Coach',
            url:
              'https://healthy.kaiserpermanente.org/northern-california/health-wellness/wellness-coaching?kp_shortcut_referrer=kp.org/coaching',
          },
          {
            title: 'Calm and myStrength Mobile App',
            url:
              'https://healthy.kaiserpermanente.org/northern-california/health-wellness/mental-health/tools-resources',
          },
        ],
      },
      spanish: {
        title: 'Reciba apoyo para su bienestar',
        menus: [
          {
            title: 'Promotor de bienestar',
            url:
              'https://healthy.kaiserpermanente.org/northern-california/health-wellness/wellness-coaching?kp_shortcut_referrer=kp.org/coaching',
          },
          {
            title: 'Aplicación “Calm” y “myStrength” para móvil',
            url:
              'https://healthy.kaiserpermanente.org/northern-california/health-wellness/mental-health/tools-resources',
          },
        ],
      },
    },
  ];

  return (
    <div style={{ paddingBottom: 32 }}>
      <Padding style={{ paddingTop: 0, paddingBottom: 0 }}>
        <Paragraph>
          {language === 'english' && (
            <div>
              <YellowText style={{ fontSize: 24 }}>Kaiser Wellness</YellowText>
            </div>
          )}

          {language === 'spanish' && (
            <div>
              <YellowText style={{ fontSize: 24 }}>
                Bienestar de Kaiser
              </YellowText>
            </div>
          )}

          {language === 'english' && (
            <YellowText style={{ fontStyle: 'italic' }}>
              Your integrated Reta Wellness Plan
            </YellowText>
          )}

          {language === 'spanish' && (
            <YellowText style={{ textStyle: 'italic' }}>
              Su Plan Integrado de Bienestar de Reta
            </YellowText>
          )}
        </Paragraph>

        <Paragraph>
          {language === 'english' && (
            <YellowText>
              If you are enrolled in the Kaiser medical plan, you are
              automatically enrolled in this wellness plan at no additional cost
              to you.
            </YellowText>
          )}
          {language === 'spanish' && (
            <YellowText>
              Si está inscrito en el plan médico de Kaiser, está inscrito
              automáticamente en este plan de bienestar sin costo adicional para
              usted.
            </YellowText>
          )}
        </Paragraph>
      </Padding>

      {/* Menu Items */}
      {menuItems.map((menu, i) => {
        return (
          <Item
            key={i}
            id={id}
            item={menu[language.toLowerCase()]}
            iconType={iconType}
            textStyle={{ fontSize: 15 }}
          />
        );
      })}
    </div>
  );
};

export default FooterMenuKaiser;
