import React from 'react';
import { SideTitle, OrangeText } from '../shared/Text';
import { COLORS } from '../../constants';
import { connect } from 'react-redux';
import { BulletVideoLine } from '../shared/Bullets';
import _has from 'lodash.has';

class _ExpandableItem extends React.Component {
  state = {
    expanded: false,
  };

  onClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    return (
      <div
        style={{
          paddingBottom: 10,
          boxSizing: 'border-box',
          cursor: 'pointer',
          ...this.props.style,
        }}
        onClick={this.onClick}
      >
        <OrangeText
          style={{
            fontSize: 13,
          }}
        >
          {this.props.section.title}
        </OrangeText>

        {this.state.expanded && (
          <div
            style={{
              marginTop: 10,
            }}
          >
            {this.props.section.items.map((item, idx) => {
              /**
               * Check the conditions
               */
              const has = this.props.has;
              if (item.condition === 'HAS_HSA' && !has.HSA) {
                return <></>;
              }
              if (item.condition === 'HAS_PPO' && !has.PPO) {
                return <></>;
              }
              if (item.condition === 'HAS_EPO' && !has.EPO) {
                return <></>;
              }
              if (item.condition === 'HAS_DEPO' && !has.DEPO) {
                return <></>;
              }
              if (item.condition === 'HAS_ACO' && !has.ACO) {
                return <></>;
              }
              if (item.condition === 'HAS_HPN' && !has.HPN) {
                return <></>;
              }

              return (
                <BulletVideoLine
                  key={idx}
                  style={Object.assign({}, idx === 0 && { marginTop: 0 })}
                  onClick={e => {
                    e.stopPropagation();
                    window.open(item.url);
                  }}
                >
                  {item.title}
                </BulletVideoLine>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}
const ExpandableItem = connect(state => {
  return {
    has: state.Data.has,
  };
})(_ExpandableItem);

export let VideoMenu = ({ video, language }) => {
  if (
    !_has(video, `${language}.sections`) ||
    !video[language].sections.length
  ) {
    return <div></div>;
  }

  return (
    <div
      style={{
        position: 'absolute',
        right: 0,
        bottom: 0,
        backgroundColor: COLORS.ANTHEM_MENU_BG,
        opacity: 0.85,
        padding: 10,
        boxSizing: 'boreder-box',
        minWidth: 235,
        maxWidth: 350,
      }}
    >
      <div
        style={{
          marginBottom: 10,
        }}
      >
        <SideTitle>{video[language].title}</SideTitle>
      </div>

      {video[language].sections.map((section, i) => {
        return <ExpandableItem key={i} section={section} />;
      })}
    </div>
  );
};

/**
 * Connect to redux
 */
VideoMenu = connect((state, ownProps) => {
  return {
    video: ownProps.plan.videoContent,
    language: state.Language.current.toLowerCase(),
  };
})(VideoMenu);
