import React from "react";
import { COLORS } from "../../constants";

export function CellText(props) {
  return (
    <div
      style={{
        minHeight: 30,
        fontSize: 11,
        lineHeight: "13px",
        color: COLORS.TEXT_DARK,
        paddingBottom: 5,
      }}
    >
      {props.children}
    </div>
  );
}
