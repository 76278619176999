import { store } from '../store';
import _debounce from 'lodash.debounce';
import { sizes } from '../constants';

export const SELECT_NAV_MENU = 'SELECT_NAV_MENU';
export const select = (id, hash = true) => {
  if (hash) {
    window.location.hash = id;
  }

  return {
    type: SELECT_NAV_MENU,
    id,
  };
};

export const EXPAND_NAV_MENU = 'EXPAND_NAV_MENU';
export const expand = id => {
  return {
    type: EXPAND_NAV_MENU,
    id,
  };
};

export const SELECT_NAV_SUB_MENU = 'SELECT_NAV_SUB_MENU';
export const selectSub = (parentId, id, hash = true) => {
  if (hash) {
    window.location.hash = id;
  }

  return {
    type: SELECT_NAV_SUB_MENU,
    parentId,
    id,
  };
};

/**
 * Scroll Spy
 */
export const spy = () => {
  // skip scroll spy if mobile
  if (store.getState().Window.x < sizes.desktop) {
    return;
  }

  let visible = [];
  const elems = document.querySelectorAll('.section');
  for (let i = 0; i < elems.length; i++) {
    const elem = elems[i];
    let rect = elem.getBoundingClientRect();
    if (rect.top >= 0 && rect.top < window.innerHeight) {
      visible.push(elem);
    }
  }

  const topElem = visible.reduce((p, c) => {
    if (c.getBoundingClientRect().top < p.getBoundingClientRect().top) {
      return c;
    }

    return p;
  }, visible[0]);

  topElem && store.dispatch(select(topElem.getAttribute('id'), false));
};

window.onscroll = _debounce(spy, 250);

export const SHOW_MOBILE_MENU = 'SHOW_MOBILE_MENU';
export const showMobileMenu = value => {
  return {
    type: SHOW_MOBILE_MENU,
    value,
  };
};
