import React from 'react';
import styled from 'styled-components';
import { sizes, shadow } from '../../constants';
import { EnrollIcon } from './Enroll';
import { connect } from 'react-redux';
import { Flexbox } from '../shared/Flexbox';
import imgHamburger from '../../assets/img/hamburger.svg';
import { showMobileMenu } from '../../actions/Menu';
import { NavItem } from './NavItem';
import { Expandable } from './Expandable';
import { headerHeight } from '../../constants';

const Shadow = styled.div`
  -webkit-box-shadow: ${shadow};
  -moz-box-shadow: ${shadow};
  box-shadow: ${shadow};
`;

const NavHeader = styled(Shadow)`
  height: ${headerHeight}px;
  width: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;

  @media (min-width: ${sizes.desktop}px) {
    display: none;
  }
`;

const ImgHamburger = styled.img`
  cursor: pointer;
  width: auto;
  height: ${headerHeight - 25}px;
`;

let Hamburger = ({ onShowMobileMenu }) => {
  return (
    <ImgHamburger
      src={imgHamburger}
      onClick={e => {
        e.stopPropagation(true);
        onShowMobileMenu(true);
      }}
    />
  );
};
Hamburger = connect(null, dispatch => {
  return {
    onShowMobileMenu: value => dispatch(showMobileMenu(value)),
  };
})(Hamburger);

const ImgLogo = styled.img`
  cursor: pointer;
  width: auto;
  height: ${headerHeight - 16}px;
`;

export let MobileMenu = ({ testing, showMobileMenu, client }) => {
  return (
    <NavHeader>
      <Flexbox
        style={{
          height: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: 20,
          paddingRight: 20,
          boxSizing: 'border-box',
        }}
      >
        <Hamburger />

        <ImgLogo src={client.images.logo.url} />

        <EnrollIcon testing={testing} />
      </Flexbox>

      {showMobileMenu && <MobileMenuItems />}
    </NavHeader>
  );
};
MobileMenu = connect(state => {
  return {
    testing: state.Data.test,
    showMobileMenu: state.Menu.showMobileMenu,
    client: state.Client,
  };
})(MobileMenu);

const MenuItems = styled(Shadow)`
  position: fixed;
  top: ${headerHeight}px;
  left: 0;
  z-index: 2000;
  min-width: 100px;
  background-color: white;
`;

export let MobileMenuItems = ({ menus, language }) => {
  return (
    <MenuItems>
      {menus.map((menu, i) => {
        if (menu.id === 'decision-tool') {
          return <></>;
        }

        if (menu.hasOwnProperty('menus')) {
          return (
            <Expandable
              key={i}
              menu={menu}
              style={Object.assign(
                {},
                i === menus.length - 1 && { marginBottom: 0 },
              )}
            >
              {menu.name[language]}
            </Expandable>
          );
        }

        return (
          <NavItem
            key={i}
            menu={menu}
            style={Object.assign(
              {},
              i === menus.length - 1 && { marginBottom: 0 },
              menu.style && menu.style,
            )}
          >
            {menu.name[language]}
          </NavItem>
        );
      })}
    </MenuItems>
  );
};

MobileMenuItems = connect(
  state => {
    return {
      menus: state.Menu.menus,
      language: state.Language.current.toLowerCase(),
    };
  },
  null,
  null,
  {
    areStatePropsEqual: () => false,
  },
)(MobileMenuItems);
