import { RECEIVED_DATA } from '../actions/Data';

const initialState = {
  Plans: [],
};

export const Compare = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVED_DATA: {
      return {
        ...state,
        Plans: action.data.plans.available.medical.map(p => {
          let sbc = p.media.filter(m => m.type === 'SBC');
          let spd = p.media.filter(m => m.type === 'SPD');

          return {
            description: p.description,
            deductible: {
              in: {
                ind: p.details.deductible.individual.inNetwork,
                fam: p.details.deductible.family.inNetwork,
              },
              out: {
                ind: p.details.deductible.individual.outNetwork,
                fam: p.details.deductible.family.outNetwork,
              },
            },
            oopMax: {
              in: {
                ind: p.details.oopMax.individual.inNetwork,
                fam: p.details.oopMax.family.inNetwork,
              },
              out: {
                ind: p.details.oopMax.individual.outNetwork,
                fam: p.details.oopMax.family.outNetwork,
              },
            },
            doctor: {
              in: {
                special: p.details.copays.doctor.specialist.inNetwork,
                primary: p.details.copays.doctor.primaryCare.inNetwork,
              },
              out: {
                special: p.details.copays.doctor.specialist.outNetwork,
                primary: p.details.copays.doctor.primaryCare.outNetwork,
              },
            },
            hospitalOut: {
              in: {
                surgery: p.details.copays.hospitalOutpatient.surgery.inNetwork,
                xrayLabs:
                  p.details.copays.hospitalOutpatient.xrayLabs.inNetwork,
              },
              out: {
                surgery: p.details.copays.hospitalOutpatient.surgery.outNetwork,
                xrayLabs:
                  p.details.copays.hospitalOutpatient.xrayLabs.outNetwork,
              },
            },
            hospitalIn: {
              in: p.details.copays.hospitalInpatient.inNetwork,
              out: p.details.copays.hospitalInpatient.outNetwork,
            },
            er: {
              in: p.details.copays.er.inNetwork,
              out: p.details.copays.er.outNetwork,
            },
            urgentCare: {
              in: p.details.copays.urgentCare.inNetwork,
              out: p.details.copays.urgentCare.outNetwork,
            },
            rxPharm: {
              in: {
                brand: p.details.copays.rx.retail['30day'].brand.inNetwork.cost,
                generic:
                  p.details.copays.rx.retail['30day'].generic.inNetwork.cost,
              },
              out: {
                brand:
                  p.details.copays.rx.retail['30day'].brand.outNetwork.cost,
                generic:
                  p.details.copays.rx.retail['30day'].generic.outNetwork.cost,
              },
            },
            rxMail: {
              in: {
                brand: p.details.copays.rx.mail['30day'].brand.inNetwork.cost,
                generic:
                  p.details.copays.rx.mail['30day'].generic.inNetwork.cost,
              },
              out: {
                brand: p.details.copays.rx.mail['30day'].brand.outNetwork.cost,
                generic:
                  p.details.copays.rx.mail['30day'].generic.outNetwork.cost,
              },
            },
            costTiers:
              p.costTiers &&
              p.costTiers.reduce((p, c) => {
                p[c.name] = c.amount;
                return p;
              }, {}),
            sbc: sbc.length ? sbc[sbc.length - 1] : null,
            spd: spd.length ? spd[spd.length - 1] : null,
            default: p.default || false,
          };
        }),

        costTierNames: action.data.plans.available.medical
          .map(p => p._tierNames)
          .reduce((p, c) => {
            if (p.includes(c)) {
              // eslint-disable-next-line
              return;
            }
            return c;
          }, []),

        restrictedRx: action.data.plans.available.medical
          .map(x => {
            const rx = x.details.copays.rx;

            return [
              rx.retail['30day'].generic.inNetwork.planRxBenefitOnly,
              rx.retail['30day'].generic.outNetwork.planRxBenefitOnly,

              rx.retail['30day'].brand.inNetwork.planRxBenefitOnly,
              rx.retail['30day'].brand.outNetwork.planRxBenefitOnly,

              rx.mail['30day'].generic.inNetwork.planRxBenefitOnly,
              rx.mail['30day'].generic.outNetwork.planRxBenefitOnly,

              rx.mail['30day'].brand.inNetwork.planRxBenefitOnly,
              rx.mail['30day'].brand.outNetwork.planRxBenefitOnly,
            ].includes(true);
          })
          .includes(true),
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
