import React from 'react';
import { connect } from 'react-redux';
import { TextDark } from '../shared/Text';
import { Flexbox, FlexColumn } from '../shared/Flexbox';
import { COLORS } from '../../constants';
import imgBullet from '../../assets/img/redBullet.svg';

// eslint-disable-next-line
const MenuItem = props => {
  return (
    <Flexbox
      style={{
        cursor: 'default',
        alignItems: 'center',
        paddingBottom: 6,
      }}
    >
      <img
        style={{
          width: 'auto',
          height: 'auto',
          paddingRight: 6,
        }}
        src={imgBullet}
        alt=""
      />

      <TextDark
        style={{
          fontSize: 12,
          fontFamily: 'Roboto',
          fontWeight: 'bold',
          color: COLORS.RED,
        }}
      >
        {props.children}
      </TextDark>
    </Flexbox>
  );
};

let Menu = () => {
  return (
    <div
      style={{
        // marginLeft: 27,
        paddingLeft: 8,
        paddingTop: 8,
        boxSizing: 'border-box',
        textAlign: 'left',
      }}
    >
      {/* Hide these for now as per Rob Geyer */}
      {/* <MenuItem> */}
      {/*   Enroll Now */}
      {/* </MenuItem> */}
      {/*  */}
      {/* <MenuItem> */}
      {/*   Add/Remove Dependents */}
      {/* </MenuItem> */}
      {/*  */}
      {/* <MenuItem> */}
      {/*   Other MyEnroll Options */}
      {/* </MenuItem> */}
    </div>
  );
};
Menu = connect(state => {
  return {
    testing: state.Data.test,
  };
})(Menu);

export let EnrollIcon = ({ testing, client }) => {
  return (
    <div
      style={{
        cursor: 'pointer',
        width: 98,
        height: 23,
      }}
      onClick={e => {
        e.stopPropagation(true);

        const href = testing
          ? 'https://www.bastest.com/web_projects/MainHomePage/MyEnroll_Home.aspx?'
          : 'https://www.myenroll.com/web_projects/MainHomePage/MyEnroll_Home.aspx?';

        window.open(href);
      }}
    >
      <img src={client.images.enroll.url} alt="enroll" />
    </div>
  );
};

EnrollIcon = connect(state => ({
  client: state.Client,
}))(EnrollIcon);

export let Enroll = ({ expanded, testing }) => {
  return (
    <FlexColumn
      style={{
        width: '100%',
        margin: '25px 0',
        flex: '1 2 50px',
      }}
    >
      <FlexColumn
        style={{
          // alignItems: 'baseline',
          alignItems: 'center',
          width: '100%',
          paddingLeft: 6,
          boxSizing: 'border-box',
        }}
      >
        {/* Icon */}
        <EnrollIcon testing={testing} />

        {/* Menu */}
        {expanded && <Menu />}
      </FlexColumn>
    </FlexColumn>
  );
};

Enroll = connect(state => {
  return {
    expanded: state.RetaEnroll.expanded,
    testing: state.Data.test,
  };
})(Enroll);
