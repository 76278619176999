import React, { useState } from 'react';
import { Navigator } from './Navigator';
import { Enroll } from './Enroll';
import { Logo } from './Logo';
import { FlexColumn } from '../shared/Flexbox';
import styled from 'styled-components/macro';
import imgETF from '../../assets/img/Your.ETF.Benefits.tree.jpg';
import img4Doc from '../../assets/img/4-A.DOC.app.png';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { Message } from '../welcome/BSG';
// import EnrollBSG from './EnrollBSG';

Modal.setAppElement('#rbc');

const Group = styled.div`
  flex: 0 1 150px;

  &.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &.end {
    flex: 1 2 150px;
    display: flex;
    align-items: flex-end;
  }

  & img {
    cursor: pointer;
  }

  & div.doc {
    color: #7d7d7d;
    font-size: 11px;
    line-height: 1.6;
    width: 135px;
    margin-bottom: 16px;
  }
`;

export let Menu = ({ ihcId }) => {
  const [docModal, setDocModal] = useState(false);

  return (
    <FlexColumn
      id="menu"
      style={{
        width: 175,
        textAlign: 'center',
        position: 'fixed',
        height: '100%',
        boxSizing: 'border-box',
        paddingTop: 20,
        paddingBottom: 50,
        backgroundColor: 'white',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      {ihcId === 'bsg' && (
        <img style={{ width: 150, marginLeft: 5 }} src={imgETF} alt="ETF" />
      )}
      <Group>
        <Logo
          style={
            ihcId === 'bsg'
              ? {
                  width: 100,
                }
              : {}
          }
        />
      </Group>
      {ihcId === 'bsg' && (
        <Group className="center">
          <img src={img4Doc} alt="?-4-a" onClick={() => setDocModal(true)} />
          <div className="doc">
            Download this free BSGA app for medical consults, urgent care advice
            & more.
          </div>

          <Modal
            isOpen={docModal}
            onRequestClose={() => setDocModal(false)}
            contentLabel="?-4-a-doc"
            style={{
              content: {
                maxWidth: 480,
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
              },
            }}
          >
            <Message>
              <div>
                <p>
                  <b>Important Ways to Checkup on Yourself</b>
                </p>

                <p>
                  We would like to help you, help yourself to be well and have a
                  healthier lifestyle. And that’s why ETF has partnered with
                  BSGA to offer a convenient and private way for you to check up
                  on yourself. Here are three steps you can take to get started
                </p>

                <ol>
                  <li className="blue italic">
                    Download the free mobile app called ?-4-A-Doc™. Personal
                    medical consults, urgent care advice, personal alerts and
                    more at your fingertips.
                  </li>
                  <li>
                    Be sure to check for It’s About You mail on the homepage of
                    this website for personal and private health messages about
                    you. You will receive alerts by text and email reminding
                    that You Got: It’s About You Mail.
                  </li>
                  <li>
                    Look for text and email alerts for when and where Health
                    monitoring kiosks are available at your workplace and check
                    in regularly. Your results will be automatically transmitted
                    to your primary care physician for review.
                  </li>
                </ol>
              </div>
            </Message>
          </Modal>
        </Group>
      )}

      {/* <Group>{ihcId === 'bsg' ? <EnrollBSG /> : <Enroll />}</Group> */}

      <Group>
        <Enroll />
      </Group>

      <Group className="end">
        <Navigator />
      </Group>
    </FlexColumn>
  );
};

Menu = connect(state => ({
  ihcId: state.Data.ihcId,
}))(Menu);
