export const HIDE_WELCOME_MENUS = 'HIDE_WELCOME_MENUS';
export const hideAll = () => {
  return {
    type: HIDE_WELCOME_MENUS,
  };
};

export const SHOW_WELCOME_MENU = 'SHOW_WELCOME_MENU';
export const showMenu = menuName => {
  return {
    type: SHOW_WELCOME_MENU,
    menuName,
  };
};
