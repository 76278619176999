import React, { useContext } from 'react';
import MenuClient from './MenuClient';
import { MenuTrustor } from './MenuTrustor';
import { MenuLocation } from './MenuLocation';
import { MenuCongrats } from './MenuCongrats';
import { Text } from '../shared/Text';
import { COLORS } from '../../constants';
import { ModalMenu } from '../shared/ModalMenu';
import { connect } from 'react-redux';
import { hideAll } from '../../actions/Welcome';
import { Flexbox, FlexColumn } from '../shared/Flexbox';
import _has from 'lodash.has';
import Covid19 from './Covid19/Covid19';
import { FeaturesContext } from '../../contexts/features';
import BSG from './BSG';
import { MenuBSGGov } from './MenuBSGGov';
import { MenuBSGDOC } from './MenuBSGDOC';
import { MenuBSGETF } from './MenuBSGETF';

export let Content = ({ ihcId, background, ...props }) => {
  const features = useContext(FeaturesContext);

  return (
    <FlexColumn
      style={{
        flex: '1 0 100px',
        backgroundImage: features.active('fullBg')
          ? 'none'
          : `url(${props.bgImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: background?.position?.x || 'center',
        backgroundPositionY: background?.position?.y || 'center',
        backgroundSize: 'cover',
        height: '100%',
        position: 'relative',
      }}
      onClick={props.onClose}
    >
      {ihcId === 'reta' &&
        (props.showRetaMenu ||
          props.showTrustorMenu ||
          props.showLocationMenu ||
          props.showCongrats) && (
          <ModalMenu
            style={Object.assign(
              {},
              props.showCongrats && {
                width: 'auto',
                minHeight: 'inherit',
                backgroundColor: COLORS.ANTHEM_BLUE,
                opacity: 0.85,
              },
            )}
          >
            {props.showRetaMenu ? <MenuClient /> : null}
            {props.showTrustorMenu ? <MenuTrustor /> : null}
            {props.showLocationMenu ? <MenuLocation /> : null}
            {props.showCongrats ? <MenuCongrats /> : null}
          </ModalMenu>
        )}

      {ihcId === 'bsg' &&
        (props.showRetaMenu ||
          props.showTrustorMenu ||
          props.showLocationMenu ||
          props.showCongrats) && (
          <ModalMenu
            style={Object.assign(
              {},
              props.showCongrats && {
                width: 'auto',
                minHeight: 'inherit',
                backgroundColor: COLORS.ANTHEM_BLUE,
                opacity: 0.85,
              },
            )}
          >
            {props.showRetaMenu ? <MenuBSGGov /> : null}
            {props.showTrustorMenu ? <MenuBSGDOC /> : null}
            {props.showLocationMenu ? <MenuBSGETF /> : null}
            {props.showCongrats ? <MenuCongrats /> : null}
          </ModalMenu>
        )}

      <div
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
        }}
      >
        {props.desc && (
          <Flexbox>
            <Text
              style={{
                position: 'relative',
                width: '100%',
                textAlign: 'center',
                bottom: 0,
                color: COLORS.TEXT_DARK,
                paddingLeft: 10,
                fontSize: 10,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                boxSizing: 'border-box',
              }}
              dangerouslySetInnerHTML={{ __html: props.desc }}
            />
          </Flexbox>
        )}

        {ihcId === 'bsg' && <BSG />}
      </div>

      {features.active('showRetaC19') && !features.active('sideC19') && (
        <Covid19 />
      )}
    </FlexColumn>
  );
};

/**
 * Connect to redux
 */
Content = connect(
  state => {
    return {
      background: state.Data.trustor.images?.background,
      bgImg: _has(state.Data, 'trustor.images.background.url')
        ? state.Data.trustor.images.background.url
        : 'assets/img/welcomeBackgroundDefault.png',
      desc: _has(state.Data, 'trustor.images.background.description')
        ? state.Data.trustor.images.background.description
        : null,
      showRetaMenu: state.Welcome.menus.reta.active,
      showTrustorMenu:
        _has(state.Data, 'trustor.id') && state.Welcome.menus.trustor.active,
      showLocationMenu:
        _has(state.Data, 'location.id') && state.Welcome.menus.location.active,
      showCongrats: state.Welcome.menus.congrats.active,
      ihcId: state.Data.ihcId,
    };
  },
  dispatch => {
    return {
      onClose: () => dispatch(hideAll()),
    };
  },
)(Content);
