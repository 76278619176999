import React, { useState } from 'react';
import AceEditor from 'react-ace';
import { connect } from 'react-redux';
import 'ace-builds';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/src-noconflict/keybinding-vim';
import {
  setPersona,
  setVim,
  setOutput,
  fetchingPresentation,
  fetchError,
  fetched,
} from '../../actions/Editor';
import fetch from 'isomorphic-fetch';
import { Flexbox, FlexColumn } from '../shared/Flexbox';
import _debounce from 'lodash.debounce';
import { Spinner } from '../shared/Spinner';
import { store } from '../../store';

const Text = props => {
  return (
    <span
      style={{
        fontSize: 11,
        ...props.style,
      }}
    >
      {props.children}
    </span>
  );
};

const _Menu = ({
  personas,
  output,
  fetching,
  error,
  useVim,
  carriers,
  onFetchPersona,
  onToggle,
  onGetPresentation,
}) => {
  const [hideBridgeHealth, setHideBridgeHealth] = useState(false);
  const [hideVivante, setHideVivante] = useState(false);
  const [hideWellness, setHideWellness] = useState(false);

  return (
    <Flexbox
      style={{
        flexGrow: 0,
        flexShrink: 0,
        justifyContent: 'space-between',
        padding: 10,
      }}
    >
      {/* Select Persona */}
      <div>
        <Text style={{ paddingRight: 5 }}>Select Persona:</Text>

        <Flexbox>
          <select
            onChange={e => {
              onFetchPersona(e.target.value);
            }}
          >
            <option>----</option>
            {personas.map((persona, i) => {
              return (
                <option key={i} value={persona.url}>
                  {persona.name}
                </option>
              );
            })}
          </select>

          <select
            onChange={e => {
              onFetchPersona(e.target.value);
            }}
          >
            <option>----</option>
            {carriers &&
              carriers.map((carrier, i) => {
                return (
                  <option key={i} value={carrier.url}>
                    {carrier.name}
                  </option>
                );
              })}
          </select>
        </Flexbox>
      </div>

      {/* Enable Vim Keys */}
      <div>
        <input
          type="checkbox"
          defaultChecked={useVim}
          onChange={e => onToggle(e.target.checked)}
        />
        <Text>Enable Vim keys</Text>
      </div>
      <div>
        <input
          type="checkbox"
          defaultChecked={hideVivante}
          onChange={e => setHideVivante(e.target.checked)}
        />
        <Text>Hide Vivante</Text>
      </div>
      <div>
        <input
          type="checkbox"
          defaultChecked={hideBridgeHealth}
          onChange={e => setHideBridgeHealth(e.target.checked)}
        />
        <Text>Hide BridgeHealth</Text>
      </div>
      <div>
        <input
          type="checkbox"
          defaultChecked={hideWellness}
          onChange={e => setHideWellness(e.target.checked)}
        />
        <Text>Hide Wellness</Text>
      </div>

      {!fetching ? (
        <button
          onClick={() =>
            onGetPresentation(
              output,
              hideBridgeHealth,
              hideVivante,
              hideWellness,
            )
          }
        >
          Get Presentation
        </button>
      ) : (
        <Spinner
          style={Object.assign(
            {
              width: 15,
              height: 15,
              backgroundColor: 'green',
            },
            error && {
              backgroundColor: 'red',
            },
          )}
        />
      )}
    </Flexbox>
  );
};

export const Menu = connect(
  state => {
    return {
      personas: state.Editor.personas,
      carriers: state.Editor.carriers,
      output: state.Editor.output,
      fetching: state.Editor.fetching,
      error: state.Editor.error,
      useVim: state.Editor.vim,
    };
  },
  dispatch => {
    return {
      onFetchPersona: url => {
        fetch(url)
          .then(res => res.text())
          .then(json => {
            dispatch(setPersona(json));
          })
          .catch(err => console.error('err', err));
      },

      onToggle: value => dispatch(setVim(value)),

      onGetPresentation: (
        json,
        hideBridgeHealth,
        hideVivante,
        hideWellness,
      ) => {
        dispatch(fetchingPresentation());

        fetch('/apiHost')
          .then(res => res.text())
          .then(url =>
            fetch(`${url}/presentation`, {
              method: 'POST',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(JSON.parse(json)),
            }),
          )
          .then(res => {
            if (!res.ok) {
              res.json().then(json =>
                json.errors.forEach(e => {
                  console.error('Validation Error:', e);
                  dispatch(
                    fetchError(
                      'Validation Error: ' + JSON.stringify(e, null, 2),
                    ),
                  );
                }),
              );
              throw new Error(res.statusText);
            }
            return res;
          })
          .then(res => res.json())
          .then(json => {
            console.debug('Res JSON:', json);
            dispatch(fetched());
            window.open(
              `/${json.search}${hideBridgeHealth ? '&showBridgeHealth=0' : ''}${
                hideVivante ? '&showVivante=0' : ''
              }${hideWellness ? '&showWW=0&showFitbit=0&showWebMD=0' : ''}`,
            );
          })
          .catch(err => console.error(err));
      },
    };
  },
)(_Menu);

const _Editor = ({ json, useVim, onUpdate }) => {
  return (
    <FlexColumn
      style={{
        flexGrow: 1,
        flexShrink: 1,
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      <Menu />
      <AceEditor
        name="farts"
        mode="json"
        width="100%"
        height="100%"
        theme="solarized_dark"
        onChange={_debounce(value => onUpdate(value), 500)}
        keyboardHandler={useVim ? 'vim' : ''}
        tabSize={2}
        showPrintMargin={false}
        value={json}
      />
    </FlexColumn>
  );
};

export const Editor = connect(
  state => {
    return {
      json: state.Editor.json,
      useVim: state.Editor.vim,
    };
  },
  dispatch => {
    return {
      onUpdate: value => dispatch(setOutput(value)),
    };
  },
)(_Editor);

/* Get the defaults */
const json = window.localStorage.getItem('_editor');
if (json) {
  const defaults = JSON.parse(json);
  if (defaults.hasOwnProperty('vim')) {
    store.dispatch(setVim(defaults.vim));
  }
}
