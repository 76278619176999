import React from 'react';
import { FlexColumn } from '../shared/Flexbox';
import { TextEmphasis } from '../shared/Text';
import { SideParagraph } from '../shared/Layout';
import { connect } from 'react-redux';
import { TrustorName } from '../shared/PII';
import { Header } from './MenuClient';

export let MenuTrustor = ({ message }) => {
  return (
    <FlexColumn>
      <Header>
        <TextEmphasis>
          A message from <TrustorName />
        </TextEmphasis>
      </Header>

      {/* Default Message */}
      {!message.length && (
        <div>
          <SideParagraph>
            You and your family depend on us for your medical coverage and other
            benefits. We depend on Reta to help provide them, giving us
            negotiating power to make your plans more affordable, and technology
            to make them easier to use &ndash; like this Reta Benefits Center.
          </SideParagraph>

          <SideParagraph>
            We’re not just an employer. We’re a religious organization.
            Providing the best possible benefits is an important part of our
            commitment to our community. In this way we recognize your
            contributions and celebrate our shared strength.
          </SideParagraph>

          <SideParagraph>
            Whether you’re enrolling benefits for the first time, making changes
            – or returning to the site for plan information, links, documents
            and contacts to help you stay well and make the most of them.
          </SideParagraph>
        </div>
      )}

      {message.map((line, i) => {
        return (
          <SideParagraph key={i} dangerouslySetInnerHTML={{ __html: line }} />
        );
      })}
    </FlexColumn>
  );
};

MenuTrustor = connect(state => {
  return {
    message: state.Data.trustor.message,
  };
})(MenuTrustor);
