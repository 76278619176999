import _debounce from 'lodash.debounce';

export const SET_WINDOW_SIZE = 'SET_WINDOW_SIZE';
export const setWindowSize = (x = 0, y = 0) => {
  return {
    type: SET_WINDOW_SIZE,
    x,
    y,
  };
};

/**
 * This is a hack for an IE layout issue in the SideContent area where 100%
 * width is not being respected.  Jiggling any css style renders the correct
 * layout.  This function jiggles the height of a section.
 */
export const isIE = () => {
  return /MSIE\s|Trident/.test(navigator.userAgent);
};

export const ieResizeHack = () => {
  // only run if this is IE
  if (!isIE()) {
    return;
  }

  // console.debug('Running IE layout "jiggle"');

  // Get the sections
  const sections = document.querySelectorAll('.section');

  // cache the original height and calc a `hack` height
  const origHeight = [];
  const hackHeight = [];
  for (let i = 0; i < sections.length; i++) {
    let s = sections[i];
    origHeight.push(s.clientHeight + 'px');
    hackHeight.push(s.clientHeight - 20 + 'px');
  }

  // set the hack height
  setTimeout(() => {
    for (let i = 0; i < sections.length; i++) {
      let s = sections[i];
      s.style.minHeight = hackHeight[i];
    }

    // restore the original height
    setTimeout(() => {
      for (let i = 0; i < sections.length; i++) {
        let s = sections[i];
        s.style.minHeight = origHeight[i];
      }
    }, 50);
  });
};

export const onResize = () => {
  return dispatch => {
    // register a resize event
    window.addEventListener(
      'resize',
      _debounce(e => {
        return dispatch(
          setWindowSize(e.target.innerWidth, e.target.innerHeight),
        );
      }, 100),
    );

    // IE layout jiggle
    if (isIE()) {
      window.addEventListener('resize', _debounce(ieResizeHack, 100));
    }

    // set the initial window size
    dispatch(setWindowSize(window.innerWidth, window.innerHeight));
  };
};
