import React, { useContext } from 'react';
import styled from 'styled-components';
import imgCheck from './check.svg';
import Color from 'color2';
import { FeaturesContext } from '../../../contexts/features';

const Wrapper = styled.div`
  width: 100%;

  &.bottom {
    position: absolute;
    bottom: 0;
  }

  & a {
    &:link,
    &:visited {
      color: inherit;
      font-style: inherit;
    }
  }
`;

const Content = styled.div`
  background-color: ${props =>
    new Color('#0078c2').alpha(props.sideC19 ? 1.0 : 0.9)};
  color: white;
  box-sizing: border-box;
  padding: 12px;
  font-size: 0.7em;
  line-height: 1.61em;
  text-align: center;

  & div {
    margin: 12px 0;
  }

  & .header {
    color: #feb814;
    font-size: 1.6em;
    text-align: center;
    margin-bottom: 18px;
    letter-spacing: 0.9;
    font-family: 'Roboto Condensed';
  }

  & .body {
    text-align: left;
    padding: 0 12px;
    box-sizing: border-box;
  }

  & .footer {
    color: #feb814;
    text-align: center;
    box-sizing: border-box;
    margin-top: 18px;

    & img {
      width: 18px;
    }
  }
`;

const Covid19 = () => {
  const features = useContext(FeaturesContext);

  return (
    <Wrapper className={features.active('autoSectionHeight') ? '' : 'bottom'}>
      <Content sideC19={features.active('sideC19')}>
        <div className="header">
          The Latest Coronavirus <br />
          Reta Medical Plan Update
        </div>
        <div className="body">
          In our effort to help prevent the spread of the Coronavirus and to
          improve access to care for our members, effective immediately Reta is
          providing 100% coverage to members for any testing, doctor visits,
          hospitalization and treatment related to the Coronavirus.
        </div>
        <div className="footer">
          <img src={imgCheck} alt="check" />
          Click on <i>Your Medical Plan Options</i> for up to date Coronavirus
          information.
        </div>
      </Content>
    </Wrapper>
  );
};

export default Covid19;
