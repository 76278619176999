import React from 'react';
import { Section, Paragraph as P } from '../shared/Layout';
import { SideTitle, TextEmphasis } from '../shared/Text';
import { COLORS } from '../../constants';
import { More } from '../shared/More';
import { Flexbox, FlexColumn } from '../shared/Flexbox';
import flexify from '../../utils/css/flexbox';
import { EnrollDeadlineMsg } from '../shared/Enrollment';
import { HSAWorks } from './HSAWorks';
import { connect } from 'react-redux';
import { show } from '../../actions/AboutPlans';
import styled from 'styled-components';
import { media } from '../../constants';

const ColumnWrapper = styled.div`
  flex: 1 1 287px;
  padding-left: 25px;
  padding-right: 25px;
  box-sizing: border-box;
  max-width: 50%;

  ${media.tablet`
    display: block;
    max-width: 100%;
    flex: 1 1 100%;
    padding-left: 0;
    padding-right: 0;
    margin-top: 24px;
  `};
`;

let Column = props => {
  return (
    <ColumnWrapper {...props}>
      <TextEmphasis
        style={{
          fontSize: 13,
          marginBottom: 20,
          letterSpacing: '.05em',
        }}
      >
        {props.title}
      </TextEmphasis>

      {props.children}
    </ColumnWrapper>
  );
};

let Paragraph = props => {
  return (
    <P
      style={{
        fontSize: 12,
        ...props.style,
      }}
    >
      {props.children}
    </P>
  );
};

const Wrapper = styled(Flexbox)`
  flex-wrap: nowrap;

  ${media.tablet`
    flex-wrap: wrap;
    max-width: 100%;
  `};
`;

export let AboutPlans = ({
  showModal,
  has,
  numOfPlans,
  onMore,
  isOE,
  ihcId,
  ...props
}) => {
  return (
    <Section
      id="about"
      style={{
        ...props.style,
      }}
    >
      <FlexColumn
        style={{
          flex: '1 0',
        }}
      >
        <div
          style={flexify({
            backgroundColor: COLORS.GRAY_BLUE,
            padding: 48,
            boxSizing: 'border-box',
            flex: '1 0',
          })}
        >
          <SideTitle
            style={{
              marginTop: 42,
              marginBottom: 36,
            }}
          >
            About Your Medical Plan{numOfPlans > 1 ? 's' : ''}
          </SideTitle>

          <Wrapper>
            {has.HSA && (
              <Column
                title="Health Savings Account or HSA Plan"
                style={{
                  paddingLeft: 0,
                }}
              >
                <div>
                  <Paragraph>
                    Medical plans that offer a Health Savings Account (HSA) are
                    high deductible qualified plans. This type of medical HSA
                    plan allows you and your employer to make contributions to
                    your own personal account that you can draw from to pay for
                    plan deductibles, copays, coinsurance and other qualified
                    dental, vision and prescription drug expenses.
                  </Paragraph>

                  <Paragraph>
                    You make contributions to your account through before-tax
                    payroll deductions up to certain limits. And this money is
                    yours to keep—even if you change plans or leave your
                    employment.
                  </Paragraph>

                  {!showModal && (
                    <Flexbox
                      style={{
                        justifyContent: 'flex-end',
                      }}
                    >
                      <More
                        style={{
                          fontSize: 12,
                        }}
                        onClick={onMore}
                      />
                    </Flexbox>
                  )}
                </div>
              </Column>
            )}

            {!showModal && has.PPO && (
              <Column
                title="Preferred Provider Organizations or PPO plans"
                style={Object.assign({}, !has.HSA && { paddingLeft: 0 })}
              >
                <div>
                  <Paragraph>
                    PPO plans give you flexibility. You don&apos;t need a
                    primary care physician. You can go to any healthcare
                    professional you want without a referral &mdash; inside or
                    outside of your network.
                  </Paragraph>

                  <Paragraph>
                    Staying inside your network means smaller copays and full
                    coverage. If you choose to go outside your network,
                    you&apos;ll have higher out-of-pocket costs, and not all
                    services may be covered.
                  </Paragraph>
                </div>
              </Column>
            )}

            {!showModal && has.EPO && (
              <Column title="Exclusive Provider Organizations or EPO plans">
                <div>
                  <Paragraph>
                    EPO plans combine the flexibility of PPO plans with the
                    cost-savings of HMO plans. You won&apos;t need to choose a
                    primary care physician, and you don&apos;t need referrals to
                    see a specialist.
                  </Paragraph>

                  <Paragraph>
                    You&apos;ll have a network of doctors and hospitals to
                    choose from. EPO plans don&apos;t cover care outside your
                    network unless it&apos;s an emergency.
                  </Paragraph>

                  <Paragraph>
                    It&apos;s important to know who participates in your EPO
                    plan&apos;s network. If you go to a doctor or hospital that
                    doesn&apos;t accept your plan, you&apos;ll pay all costs.
                  </Paragraph>
                </div>
              </Column>
            )}

            {!showModal && has.DEPO && has.kaiser && (
              <Column title="Kaiser Deductible EPO Plans">
                <div>
                  <Paragraph>
                    Your Kaiser EPO plan is not just health coverage – it’s a
                    partnership in health.
                  </Paragraph>

                  <Paragraph>
                    A Deductible Exclusive Provider Organization plan (DEPO)
                    works just like a regular EPO – with a personal physician,
                    simple copays, and no out-of-network coverage except in an
                    emergency. To make your per-paycheck costs even more
                    affordable, you’ll need to meet an annual deductible before
                    the plan starts paying for most care.
                  </Paragraph>
                </div>
              </Column>
            )}

            {!showModal && has.ACO && (
              <Column title="Accountable Care Organizations or ACO plans">
                <div>
                  <Paragraph>
                    ACO plans take a new approach to healthcare that’s focused
                    on you. Doctors in the plan are responsible for coordinating
                    every aspect of your care, and every professional who cares
                    for you, to support your overall health.
                  </Paragraph>

                  <Paragraph>
                    The plan is built around a carefully chosen network of
                    doctors, hospitals and other facilities. Simple copays for
                    most services make the plan easy to use. Keep in mind that
                    if you choose to get care outside the network, you’ll pay
                    the full cost for most services except emergency care.
                  </Paragraph>
                </div>
              </Column>
            )}

            {showModal && <HSAWorks />}
          </Wrapper>

          {isOE && (
            <div
              style={{
                marginTop: 36,
                marginBottom: 24,
              }}
            >
              <TextEmphasis>
                <EnrollDeadlineMsg />
              </TextEmphasis>
            </div>
          )}
        </div>
      </FlexColumn>
    </Section>
  );
};

AboutPlans = connect(
  state => {
    return {
      showModal: state.AboutPlans.show,
      has: state.Data.has,
      numOfPlans: state.Data.plans.available.medical.length,
      isOE: state.Data.enrollment.type === 'ANNUAL',
      ihcId: state.Data.ihcId,
    };
  },
  dispatch => {
    return {
      onMore: () => dispatch(show()),
    };
  },
)(AboutPlans);
