import React, { useState } from 'react';
import imgMail from '../../assets/img/mail.png';
import imgMyChart from '../../assets/img/myChart.png';
import styled from 'styled-components/macro';

const Title = styled.div`
  position: relative;
  color: #d81318;
  font-weight: bold;

  & > div {
    display: flex;

    & > .login {
      position: relative;
      top: 4px;
      color: #c38b0a;
    }

    & > .myChart > img {
      padding-left: 6px;
      position: absolute;
      left: 70px;
      top: 0;
    }
  }
`;

const MailWrapper = styled.div`
  display: flex;
  line-height: 1.6;
  margin-bottom: 12px;
  cursor: pointer;

  & .icon {
    display: flex;
    align-items: center;
  }

  & .content {
    padding-left: 16px;
  }

  & .slug {
    font-size: 14px;
  }
`;

const MailItem = ({ title, slug, onClick = () => {} }) => {
  return (
    <MailWrapper onClick={onClick}>
      <div className="icon">
        <img src={imgMail} alt="" />
      </div>
      <div className="content">
        <Title>{title}</Title>
        <div className="slug">{slug}</div>
      </div>
    </MailWrapper>
  );
};

const Wrapper = styled.div`
  padding: 18px;
  background-color: white;
  min-height: 250px;
  color: #646464;
  max-height: 400px;
  overflow-y: auto;
`;

export const Message = styled.div`
  display: flex;

  & img {
    position: relative;
    top: 36px;
  }

  & .content {
    padding-left: 14px;
    width: 100%;
    position: relative;
  }

  & h3 {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 16px;
  }

  & h4 {
    color: #d81318;
  }

  & p {
    color: #7d7d7d;
    margin-bottom: 16px;
    line-height: 1.6;
    font-size: 15px;
  }

  & .blue {
    color: #0361b9;
  }

  & .italic {
    font-style: italic;
  }

  & emphasis {
    font-weight: bold;
  }

  & ul {
    list-style: circle outside none;
    margin-left: 16px;
    margin-bottom: 16px;

    & li {
      color: #7d7d7d;
      line-height: 1.6;
      font-size: 15px;
    }

    &.my-chart {
      list-style: none;

      & li::before {
        content: '\\2022';
        color: red;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }

  & ol {
    margin-left: 16px;
    margin-bottom: 16px;
    counter-reset: counter;
    list-style: none;

    & li {
      counter-increment: counter;
      color: #7d7d7d;
      line-height: 1.6;
      font-size: 15px;
      margin-bottom: 12px;
    }

    & li::before {
      content: counter(counter) ') ';
      width: 1em;
      margin-left: -1em;
    }
  }

  & .more {
    color: #c59016;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
  }
`;

const BSG = () => {
  const [show, setShow] = useState(false);

  return (
    <Wrapper onClick={() => setShow(false)}>
      {!show && (
        <>
          <MailItem
            title="It's about you!"
            slug="A personal message about your health"
            onClick={e => {
              e.stopPropagation();
              setShow('message');
            }}
          />

          <MailItem
            title={<span style={{ color: '#1369bc' }}>Benefits Update</span>}
            slug="100% coverage for all COVID-19 related testing and treatment"
            onClick={e => {
              e.stopPropagation();
              setShow('covid');
            }}
          />

          <MailItem
            title={
              <div>
                <div className="login">
                  <div>Log in to</div>
                </div>
                <div className="myChart">
                  <img src={imgMyChart} alt="MyChart" />
                </div>
              </div>
            }
            slug="You have an appointment reminder"
            onClick={e => {
              e.stopPropagation();
              setShow('myChart');
            }}
          />

          <MailItem
            title="It's about you!"
            slug="You fit the high-risk profile for COVID-19.  Take these precautions."
            onClick={e => {
              e.stopPropagation();
              setShow('aboutCovid');
            }}
          />
        </>
      )}

      {show === 'message' && (
        <Message>
          <div>
            <img src={imgMail} alt="" />
          </div>
          <div className="content">
            <h3>Your Personal Inbox</h3>

            <h4>It's about you!</h4>

            <p>
              <b>A personal message about your health.</b>
            </p>

            <p className="blue italic">
              With your recent history of diabetes, it's important to take
              control of you health. And now your medical plan will make it more
              affordable for you to do just that.
            </p>

            <p>
              To encourage you to keep regular doctor appointments and follow
              recommended treatments and lifestyle changes, effective
              immediately,{' '}
              <emphasis>
                your DOC medical High Deductible Health Plan will absorb all
                deductible expenses and copays for all diabetes related doctor
                office visits and treatment you receive.
              </emphasis>
            </p>

            <p>
              Beyond health insurance, your employer also provides you a
              specially tailored and private Home Health Monitoring program as a
              way to help you help yourself to a healthier life while putting
              more money in your pocket. <span className="more">More</span>
            </p>
          </div>
        </Message>
      )}

      {show === 'covid' && (
        <Message>
          <div>
            <img src={imgMail} alt="" />
          </div>
          <div className="content">
            <h3>Your Personal Inbox</h3>

            <Title>
              <span style={{ color: '#1369bc' }}>Benefits Update</span>
            </Title>

            <p>
              <b>
                100% coverage for all COVID-19 related testing and treatment.
              </b>
            </p>

            <p>
              In our effort to help prevent the spread of the Caronavirus and to
              improve access to care for our employees, the ETF has waived all
              employee copays, deductibles or co-insurance for testing of
              COVID-19 as well as for all telehealth or virtual medical visits
              offered through your medical plans. All treatments including any
              hospitalization, quarantines, transportation and pharmacy costs
              will be covered according to normal medical plan provisions.
            </p>
          </div>
        </Message>
      )}

      {show === 'myChart' && (
        <Message>
          <div>
            <img src={imgMail} alt="" />
          </div>
          <div className="content">
            <h3>You hanve an appointment reminder</h3>

            <Title style={{ marginBottom: 24 }}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={e => {
                  e.stopPropagation();
                  window.open(
                    'https://mychart.uwhealth.org/mychart/Authentication/Login',
                    '_blank',
                  );
                }}
              >
                <div className="login">
                  <div>Log in to</div>
                </div>
                <div className="myChart" style={{ top: -3 }}>
                  <img src={imgMyChart} alt="MyChart" />
                </div>
              </div>
            </Title>

            <ul className="my-chart">
              <li>Ask Questions</li>
              <li>View your test results</li>
              <li>Schedule an appointment</li>
              <li>Video visits</li>
              <li>e-Visits</li>
              <li>Clinic billing summaries</li>
              <li>Read MyChart messages</li>
              <li>AND MORE</li>
            </ul>
          </div>
        </Message>
      )}

      {show === 'aboutCovid' && (
        <Message>
          <div>
            <img src={imgMail} alt="" />
          </div>
          <div className="content">
            <h3>Your Personal Inbox</h3>

            <h4>It's about you!</h4>

            <p>
              <b>
                You fit the high-risk profile for COVID-19. Take these
                precautions.
              </b>
            </p>

            <p>
              Based on your age, current weight and height proportions and
              medical conditions, you are at high risk of getting COVID-19. It
              is especially important for people at increased risk of severe
              illness from COVID-19, and those who live with them, to protect
              themselves from getting COVID-19.
            </p>

            <p>
              The best way to protect yourself and to help reduce the spread of
              the virus that causes COVID-19 is to:
            </p>

            <ul>
              <li>
                Limit your interactions with other people as much as possible.
              </li>
              <li>
                Take 
                <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html">
                  precautions to prevent getting
                </a>
                 COVID-19 when you do interact with others.
              </li>
            </ul>

            <p>
              If you start feeling sick and think you may have COVID-19, get in
              touch with your healthcare provider within 24 hours.
            </p>

            <h4 style={{ marginBottom: 24 }}>
              What to consider before being around other people
            </h4>

            <p>
              There is no way to ensure you have zero risk of infection, so it
              is important to understand the risks and know how to be as safe as
              possible if or when you do 
              <a href="https://www.cdc.gov/coronavirus/2019-ncov/daily-life-coping/going-out.html">
                resume some activities
              </a>
              , 
              <a href="https://www.cdc.gov/coronavirus/2019-ncov/daily-life-coping/essential-goods-services.html">
                run errands
              </a>
              , or attend 
              <a href="https://www.cdc.gov/coronavirus/2019-ncov/community/large-events/considerations-for-events-gatherings.html">
                events and gatherings
              </a>
              .
            </p>

            <p>
              People at increased risk of severe illness from COVID-19, and
              those who live with them, should consider their level of risk
              before 
              <a href="https://www.cdc.gov/coronavirus/2019-ncov/daily-life-coping/deciding-to-go-out.html">
                deciding to go out
              </a>
               and ensure they are taking steps to 
              <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html">
                protect themselves
              </a>
              . Consider avoiding activities where taking protective measures
              may be difficult, such as activities where 
              <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/social-distancing.html">
                social distancing
              </a>
               can’t be maintained. Everyone should take steps to prevent
              getting and spreading COVID-19 to protect themselves, their
              communities, and people who are at increased risk of severe
              illness.
            </p>
          </div>
        </Message>
      )}
    </Wrapper>
  );
};

export default BSG;
