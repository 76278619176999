import React, { useEffect } from 'react';
import { Flexbox } from '../shared/Flexbox';
import { Cell } from './Cell';
import { connect } from 'react-redux';
import clsx from 'clsx';

const _Row = ({
  isPriest,
  isNun,
  showNoChangesCol,
  isNewHire,
  joinedContent,
  ...props
}) => {
  /**
   * Hack to join "cells" together.  Basically it's another element over the
   * top of the existing cells.
   */
  useEffect(() => {
    const resizer = () => {
      const availEl = document.querySelector(
        `.available.joined.${props.className}.${props.type}`,
      );

      if (availEl) {
        const dims = availEl.getBoundingClientRect();
        const parentDims = availEl.parentNode.getBoundingClientRect();

        const firstColWidth = dims.width;
        const overallWidth = parentDims.width;
        const overallHeight = parentDims.height;
        const parentBg = availEl.parentNode.style.backgroundColor;
        const borderWidth = 1;

        document
          .querySelectorAll(`.joined.${props.className}.${props.type}`)
          .forEach(el => {
            el.style.width = `${overallWidth - firstColWidth - borderWidth}px`;
            el.style.height = `${overallHeight}px`;
            el.style.left = `${dims.width + borderWidth}px`;
            el.style.backgroundColor = parentBg;
          });
      }
    };

    window.addEventListener('resize', resizer, true);

    // the flex boxes are resizing, run the resizer multiple times
    const count = 30;
    let cur = 0;
    const cancelId = setInterval(() => {
      resizer();
      if (cur === count) {
        clearInterval(cancelId);
      }
      cur += 1;
    }, 10);

    return () => window.removeEventListener('resize', resizer);
  }, [props.className, props.type]);

  return (
    <Flexbox
      style={{
        position: 'relative',
        alignItems: 'stretch',
        aligntContent: 'baseline',
        backgroundColor: props.id % 2 ? '#dfdfe1' : 'white',
        width: '100%',
      }}
    >
      <Cell
        className={clsx(
          joinedContent && ['available', 'joined', props.type, props.className],
        )}
        style={{
          flex: '3 3 200px',
          ...props.cellStyle,
        }}
      >
        {props.benefitPlans}
      </Cell>

      {!isNewHire && (
        <Cell
          style={{
            flex: '2 2 150px',
            ...props.cellStyle,
          }}
        >
          {props.haveNow}
        </Cell>
      )}

      {!isPriest && !isNun && (
        <Cell
          style={{
            flex: '1 1 115px',
            ...props.cellStyle,
          }}
        >
          {props.coverage}
        </Cell>
      )}

      {showNoChangesCol && !isNewHire && (
        <Cell
          style={{
            flex: '2 2 145px',
            ...props.cellStyle,
          }}
        >
          {props.noChange}
        </Cell>
      )}

      {/* add joined content */}
      {joinedContent && (
        <Cell
          className={clsx('joined', props.className, props.type)}
          style={{ position: `absolute`, display: 'block', paddingTop: 0 }}
        >
          {joinedContent}
        </Cell>
      )}
    </Flexbox>
  );
};
export const Row = connect(state => {
  return {
    isPriest: state.Data.isPriest ? true : false,
    isNun: state.Data.isNun ? true : false,
    isNewHire: state.Data.enrollment.type === 'NEW_HIRE',
    showNoChangesCol: state.BenefitsNow.showNoChangesCol,
  };
})(_Row);
