import { RECEIVED_DATA } from "../actions/Data";
import _uniqWith from "lodash.uniqwith";
import _isEqual from "lodash.isequal";
import _has from "lodash.has";

const initState = {
  plans: [],
  trustor: [],
  location: [],
  reta: [],
};

export const Contacts = (state = initState, action) => {
  switch (action.type) {
    case RECEIVED_DATA: {
      let newState = { ...initState };

      /**
       * Plan contacts
       */
      for (let key in action.data.plans.available) {
        let plans = action.data.plans.available[key];

        plans.forEach((p) => {
          if (p.contacts && p.contacts.length) {
            newState.plans = newState.plans.concat(p.contacts);
          }
        });
      }

      newState.plans = newState.plans.map((p) => {
        if (
          p.phones &&
          typeof p.phones === "string" &&
          /\[|\{/.test(p.phones[0])
        ) {
          return {
            ...p,
            phones: JSON.parse(p.phones),
          };
        }
        return p;
      });

      /**
       * Remove any duplicate entries
       */
      newState.plans = _uniqWith(newState.plans, _isEqual);

      /**
       * Reta contacts
       */
      if (
        _has(action.data, "reta.contacts") &&
        action.data.reta.contacts.length
      ) {
        newState.reta = newState.reta.concat(action.data.reta.contacts);
      }

      /**
       * Trustor contacts
       */
      if (
        _has(action.data, "trustor.contacts") &&
        action.data.trustor.contacts.length
      ) {
        newState.trustor = newState.trustor.concat(
          action.data.trustor.contacts
        );
      }

      /**
       * Employer contacts
       */
      if (
        _has(action.data, "location.contacts") &&
        action.data.location.contacts.length
      ) {
        newState.location = newState.location.concat(
          action.data.location.contacts
        );
      }

      return newState;
    }

    default:
      return state;
  }
};
