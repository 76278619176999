import React, { useContext } from 'react';
import imgBackground from '../../assets/img/workplace.png';
import { FlexColumn } from '../shared/Flexbox';
import { FeaturesContext } from '../../contexts/features';

export let Content = () => {
  const features = useContext(FeaturesContext);

  if (features.active('fullBg')) {
    return <div></div>;
  } else {
    return (
      <FlexColumn
        style={{
          position: 'relative',
          width: '100%',
          flexGrow: 1,
          backgroundImage: `url(${imgBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }}
      ></FlexColumn>
    );
  }
};
