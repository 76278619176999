import React from 'react';
import { SectionWithSideContent } from '../shared/Layout';
import { SideContent } from './SideContent';
import { Content } from './Content';
import { COLORS } from '../../constants';

export class WhatsNewSection extends React.Component {
  render() {
    return (
      <SectionWithSideContent
        id="new"
        sideContentStyle={{
          backgroundColor: COLORS.GRAY_BLUE,
        }}
        sideContent={<SideContent />}
        contentStyle={{
          backgroundColor: COLORS.GRAY_BLUE,
        }}
        content={<Content />}
      />
    );
  }
}
