import React from 'react';
import imgCarretRight from '../../assets/img/carretRight.svg';
import { OrangeText } from './Text';

export function More(props) {
  return (
    <OrangeText
      style={{
        cursor: 'pointer',
        fontSize: 11,
        ...props.style,
      }}
      onClick={props.onClick}
    >
      More{' '}
      <img
        src={imgCarretRight}
        style={{
          width: 8,
          height: 8,
          marginLeft: 4,
        }}
        alt=""
      />
    </OrangeText>
  );
}
export { More as default };
