import React from 'react';
import styled from 'styled-components';
import { Paragraph } from './shared/Layout';
import { TextDark } from './shared/Text';
import { connect } from 'react-redux';
import imgExclaim from '../assets/img/exclaim.svg';

const Message = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  padding: 60px;
  box-sizing: border-box;
  overflow-y: auto;
`;

const Content = styled.div`
  width: 700px;

  @media (max-width: 700px) {
    width: 100%;
  }
`;

const Bold = styled.div`
  margin-top: 20px;
  font-weight: bold;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Img = styled.div`
  flex: 0 0 64px;
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
  padding-left: 12px;
  box-sizing: border-box;
`;

class ErrorMsg extends React.Component {
  state = {
    expanded: false,
  };

  render() {
    const { message, stack, componentStack } = this.props;

    return (
      <Message>
        <Content>
          <Header>
            <Img>
              <img src={imgExclaim} alt="!" />
            </Img>
            <HeaderText>
              <Paragraph>
                <TextDark>
                  Whoops. There was an error displaying the presentation.
                </TextDark>
              </Paragraph>

              <Paragraph style={{ marginBottom: 0 }}>
                <TextDark>
                  Please retry your request again. If you continue to receive an
                  error, please contact Reta Support.
                </TextDark>
              </Paragraph>
            </HeaderText>
          </Header>

          <Paragraph
            style={{
              marginTop: 30,
              fontFamily: 'monospace',
              fontWeight: 'normal',
              padding: 16,
              border: '1px solid #d0d0d0',
              width: '100%',
              boxSizing: 'border-box',
            }}
          >
            <div
              style={{
                color: '#737373',
                cursor: 'pointer',
              }}
              onClick={() => this.setState({ expanded: !this.state.expanded })}
            >
              <b>
                {!this.state.expanded
                  ? '+ Click to show error'
                  : '- Error Message:'}
              </b>
            </div>

            {this.state.expanded && (
              <div
                style={{
                  color: '#737373',
                  padding: 5,
                }}
              >
                <div style={{ marginTop: 6 }}>{message}</div>

                {stack && (
                  <React.Fragment>
                    <Bold>Stack:</Bold>
                    {stack}
                  </React.Fragment>
                )}

                {componentStack && (
                  <React.Fragment>
                    <Bold>Component:</Bold>
                    {componentStack}
                  </React.Fragment>
                )}

                <Bold>Url:</Bold>
                {window.location.href}
              </div>
            )}
          </Paragraph>
        </Content>
      </Message>
    );
  }
}

const mapStateToProps = state => {
  return {
    message: state.Error.message,
    stack: state.Error.stack,
    componentStack: state.Error.componentStack,
  };
};

export default connect(mapStateToProps)(ErrorMsg);
