import fetch from 'isomorphic-fetch';
import queryString from 'query-string';

export const SET_API_HOST = 'SET_API_HOST';
function setApiHost(host) {
  return {
    type: SET_API_HOST,
    host,
  };
}

export const FETCHING_DATA = 'FECTHING_DATA';
function fetchingData() {
  return {
    type: FETCHING_DATA,
  };
}

export const RECEIVED_DATA = 'RECEIVED_DATA';
function receivedData(json) {
  return {
    type: RECEIVED_DATA,
    data: json,
  };
}

export const FETCH_ERROR = 'FETCH_ERROR';
export function fetchError(message) {
  return {
    type: FETCH_ERROR,
    message,
  };
}

export async function fetchData(host, dispatch) {
  /**
   * Make sure there is a query id
   */
  const query = queryString.parse(window.location.search);
  if (!query.id) {
    return dispatch(fetchError('No presentation id was provided.'));
  }

  /**
   * Fetch the presentation with the id
   */
  const url = `${host}/presentation?id=${query.id}`;
  console.debug('Presentation url:', url);

  const res = await fetch(url, {
    mode: 'cors',
  });

  if (!res.ok || res.status >= 400) {
    throw new Error(`Unable to retrieve presentation with id: "${query.id}".`);
  }

  const json = await res.json();
  dispatch(receivedData(json));

  /**
   * Scroll to selected section, if any
   */
  if (!window.location.hash) {
    return;
  }
  const elem = document.querySelector(window.location.hash);
  if (elem) {
    const bbox = elem.getBoundingClientRect();
    window.scrollTo(0, bbox.y);
  }
}

export function fetchApiHost() {
  return dispatch => {
    dispatch(fetchingData);
    return fetch('/apiHost')
      .then(res => res.text())
      .then(host => {
        console.debug(`Using Api Host: ${host}`);
        dispatch(setApiHost(host));
        dispatch(processOptions());
        return fetchData(host, dispatch);
      });
  };
}

export const SET_OPTIONS = 'SET_OPTIONS';
const processOptions = () => {
  const query = queryString.parse(window.location.search);
  const options = {};

  if (query.bridgeHealth) {
    options.bridgeHealth = /1|true/i.test(query.bridgeHealth);
  }

  if (query.vivante) {
    options.vivante = /1|true/i.test(query.vivante);
  }

  if (query.showBridgeHealth) {
    options.showBridgeHealth = /1|true/i.test(query.showBridgeHealth);
  }

  if (query.showVivante) {
    options.showVivante = /1|true/i.test(query.showVivante);
  }

  if (query.showWW) {
    options.showWW = /1|true/i.test(query.showWW);
  }

  if (query.showFitbit) {
    options.showFitbit = /1|true/i.test(query.showFitbit);
  }

  return {
    type: SET_OPTIONS,
    options,
  };
};
