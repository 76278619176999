import React from 'react';
import { Section } from '../shared/Layout';
import { COLORS } from '../../constants';
import { FullName } from '../shared/PII';
import { SideTitle, OrangeText } from '../shared/Text';
import { BulletSimple } from '../shared/Bullets';
import { Flexbox, FlexColumn } from '../shared/Flexbox';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { media, Mobile, Desktop } from '../../constants';

const Group = props => {
  return (
    <FlexColumn
      style={{
        width: '100%',
        marginRight: 54,
        flex: '1 1',
        ...props.style,
      }}
    >
      <div
        style={{
          borderBottom: `1px solid ${COLORS.ORANGE}`,
          marginBottom: 12,
          paddingBottom: 6,
          boxSizing: 'border-box',
          fontSize: 13,
        }}
      >
        <OrangeText>{props.title || <span>&nbsp;</span>}</OrangeText>
      </div>

      <FlexColumn>
        {props.contacts &&
          props.contacts.map((contact, i) => {
            return <Contact key={i} contact={contact} />;
          })}
      </FlexColumn>
    </FlexColumn>
  );
};

const Contact = props => {
  return (
    <Flexbox
      style={{
        marginBottom: 17,
        fontSize: 13,
      }}
    >
      <div
        style={{
          marginRight: 12,
        }}
      >
        <BulletSimple />
      </div>

      <div
        style={{
          color: COLORS.TEXT,
        }}
      >
        {props.contact.description &&
          props.contact.description.map((desc, i) => {
            return (
              <div
                key={i}
                style={{ marginBottom: 8 }}
                dangerouslySetInnerHTML={{ __html: desc }}
              />
            );
          })}

        {props.contact.phones &&
          props.contact.phones.length === 1 &&
          props.contact.phones.map((phone, i) => {
            return (
              <div key={i} style={{ marginBottom: 8 }}>
                Call: {phone}
              </div>
            );
          })}

        {props.contact.phones &&
          props.contact.phones.length > 1 &&
          props.contact.phones.map((phone, i) => {
            return (
              <div
                key={i}
                style={{ marginBottom: 8 }}
                dangerouslySetInnerHTML={{ __html: phone }}
              />
            );
          })}

        {props.contact.raw && (
          <div
            style={{ marginBottom: 8 }}
            dangerouslySetInnerHTML={{ __html: props.contact.raw }}
          ></div>
        )}

        {props.contact.call && (
          <div style={{ marginBottom: 8 }}>Call: {props.contact.call}</div>
        )}

        {props.contact.fax && (
          <div style={{ marginBottom: 8 }}>Fax: {props.contact.fax}</div>
        )}

        {props.contact.email && (
          <div style={{ marginBottom: 8 }}>
            Email:{' '}
            <a
              href={'mailto:' + props.contact.email}
              style={{
                textDecoration: 'none',
                color: 'white',
                fontWeight: 'bold',
                letterSpacing: '0.05em',
              }}
            >
              {props.contact.email}
            </a>
          </div>
        )}

        {props.contact.website && (
          <div>
            <a
              href={props.contact.website.url}
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: 'none',
                color: 'white',
                fontWeight: 'bold',
                letterSpacing: '0.05em',
              }}
            >
              {props.contact.website.name}
            </a>
          </div>
        )}
      </div>
    </Flexbox>
  );
};

const Spacer = styled.div`
  width: 16px;
`;

const Wrapper = styled(Flexbox)`
  justify-content: space-between;
  width: 100%;
  flex-wrap: nowrap;

  ${media.tablet`
    flex-wrap: wrap;
  `};
`;

export let Contacts = ({
  plans = [],
  employer = [],
  reta = [],
  ihcId,
  ...props
}) => {
  let lenA = Math.round(plans.length / 2);

  return (
    <Section
      id="contacts"
      style={{
        ...props.style,
      }}
      contentStyle={{
        backgroundColor: COLORS.GRAY_BLUE,
      }}
    >
      <FlexColumn
        style={{
          padding: 48,
          boxSizing: 'border-box',
          flex: '1 0',
        }}
      >
        <SideTitle
          style={{
            marginBottom: 36,
          }}
        >
          <OrangeText>
            <FullName />,
          </OrangeText>{' '}
          here are your key benefits contacts.
        </SideTitle>

        <Wrapper>
          <Desktop
            style={{
              display: 'flex',
              flex: '1 1 100px',
            }}
          >
            <Group
              style={{
                marginRight: 0,
              }}
              title="Plan Providers"
              contacts={plans.slice(0, lenA)}
            />

            <Group contacts={plans.slice(lenA)} />
          </Desktop>

          <Mobile
            style={{
              flex: '1 1 100px',
            }}
          >
            <Group
              style={{
                marginRight: 0,
              }}
              title="Plan Providers"
              contacts={plans}
            />
          </Mobile>

          <Spacer />

          {employer.length > 0 && (
            <div>
              <Group
                style={{
                  flex: '1 1 100px',
                }}
                title="Your Employer"
                contacts={employer}
              />
              <Spacer />
            </div>
          )}

          <Group
            style={{
              marginRight: 0,
              flex: '1 1 100px',
            }}
            title={ihcId === 'Reta Trust' || 'Organization'}
            contacts={reta}
          />
        </Wrapper>
      </FlexColumn>
    </Section>
  );
};

export default connect((state, ownProps) => {
  return {
    plans: state.Contacts.plans,
    employer: state.Contacts.trustor.concat(state.Contacts.location),
    reta: state.Contacts.reta,
    ihcId: state.Data.ihcId,
    ...ownProps,
  };
})(Contacts);
