import { RECEIVED_DATA } from "../actions/Data";

const initState = {
  images: {
    logo: {},
    enroll: {},
    oe: {},
    newHire: {},
    menuMessage: [],
  },
};

const Client = (state = initState, action) => {
  switch (action.type) {
    case RECEIVED_DATA: {
      return {
        ...state,
        ...action.data.client,
      };
    }

    default:
      return { ...state };
  }
};

export default Client;
