import { RECEIVED_DATA } from '../actions/Data';
import { SHOW_MODAL_NETWORK, SET_MODAL_POS_NETWORK } from '../actions/Networks';

const initialState = {
  networks: {},
  modal: {
    id: null,
    visible: false,
    items: [],
    pos: {
      x: null,
      y: null,
    },
  },
};

export const Networks = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVED_DATA: {
      const data = action.data;
      let newState = { ...initialState };
      for (let key in data.plans.available) {
        if (!newState.networks.hasOwnProperty(key)) {
          newState.networks[key] = [];
        }

        // step through all the available plasn
        data.plans.available[key].forEach(plan => {
          // only need one instance of each network
          if (
            newState.networks[key].filter(p => p.network === plan.network)
              .length === 0
          ) {
            newState.networks[key].push(plan);
          }
        });
      }

      return { ...newState };
    }

    case SHOW_MODAL_NETWORK:
      return {
        ...state,
        modal: {
          ...state.modal,
          id: action.id,
          visible: true,
          items: action.items,
        },
      };

    case 'HIDE_ALL_MENUS':
      return {
        ...state,
        modal: { ...initialState.modal },
      };

    case SET_MODAL_POS_NETWORK:
      return {
        ...state,
        modal: {
          ...state.modal,
          pos: {
            x: action.pos[0],
            y: action.pos[1],
          },
        },
      };

    default:
      return { ...state };
  }
};
