import React from 'react';
import { FlexColumn } from '../shared/Flexbox';
import { TextEmphasis } from '../shared/Text';
import { SideParagraph } from '../shared/Layout';
import { Header } from './MenuClient';

export let MenuBSGGov = () => {
  return (
    <FlexColumn>
      <Header>
        <TextEmphasis>A message from Governor Tony Evers</TextEmphasis>
      </Header>

      <div>
        <SideParagraph>
          Benefits open enrollment is a time when all of us make choices about
          the right benefits that fit both our needs and our budget. The
          Coronavirus has changed the world drastically. So now it’s more
          important than ever to take your time to go through your options
          carefully and to make meaningful choices. This personalized ETF site
          is easy to use and will help you do just that.
        </SideParagraph>

        <SideParagraph>
          Certainly, these trying times have given us time for pause. And in
          reflection, there is certainty in knowing that we are all in this
          together and that we need to find ways to help employees of the State
          of Wisconsin get through these tough times and onto better times
          ahead. The announcement on this page about Reta offering 100% coverage
          for all COVID-19 related testing, hospitalization and treatment is one
          example among others that we have taken in short order to do our part.
          And together, we will get through this.
        </SideParagraph>

        <SideParagraph>--Governor Tony Evers--</SideParagraph>
      </div>
    </FlexColumn>
  );
};
