import React, { useEffect, useRef, useContext } from 'react';
import { Flexbox, FlexColumn } from './Flexbox';
import { SideNav } from './SideNav';
import { Text } from './Text';
import { Menu } from '../menu/Menu';
import { connect } from 'react-redux';
import flexify from '../../utils/css/flexbox';
import { ieResizeHack } from '../../actions/Window';
import { media, headerHeight } from '../../constants';
import styled from 'styled-components/macro';
import _has from 'lodash.has';
import { FeaturesContext } from '../../contexts/features';

const CanvasWrapper = styled(Flexbox)`
  position: relative;
  width: 100%;
  flex-shrink: 0;
  align-items: flex-stretch;

  ${media.tablet`
    width: 100%;
  `}

  & .text-client-global {
    color: ${props =>
      _has(props, 'client.colors.text.global')
        ? props.client.colors.text.global + ' !important'
        : '#faf4f5'};
  }

  & .text-client-emphasis {
    color: ${props =>
      _has(props, 'client.colors.text.emphasis')
        ? props.client.colors.text.emphasis + ' !important'
        : '#ffb11e'};
  }
`;

const _Canvas = ({ client, ...props }) => {
  const ft = useContext(FeaturesContext);

  return (
    <CanvasWrapper client={client}>
      <FlexColumn
        style={{
          flexGrow: 1,
          flexShrink: 1,
          width: '100%',
        }}
      >
        {props.children}
      </FlexColumn>

      {!ft.active('hideMenu') && (
        <SideNav>
          <Menu />
        </SideNav>
      )}
    </CanvasWrapper>
  );
};
export const Canvas = connect(state => {
  return {
    width: state.Window.x,
    client: state.Client,
  };
})(_Canvas);

export const Content = styled(FlexColumn)`
  flex: 1 0 100px;
  overflow: auto;
  position: relative;

  ${media.tablet`
    display: none !important;
  `}
  ${media.phone`
    display: none;
  `}
`;

const SideContentWrapper = styled(FlexColumn)`
  width: 375px;
  min-height: 600px;
  flex: 0 0 375px;
  position: relative;

  ${media.tablet`
    width: 100%;
    display: block;
    flex: none;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-top: ${headerHeight}px;
  box-sizing: border-box;
`;

export const SideContent = ({ setSideScrollY = () => {}, ...props }) => {
  const elem = useRef(null);

  useEffect(() => {
    ieResizeHack();
  }, [props]);

  useEffect(() => {
    elem.current.onscroll = e => setSideScrollY(e.target.scrollTop);
    // eslint-disable-next-line
  }, []);

  return (
    <SideContentWrapper style={props.style}>
      <Wrapper isMedical={props.isMedical} ref={elem}>
        {props.children}
      </Wrapper>
    </SideContentWrapper>
  );
};

const SectionWrapper = styled(FlexColumn)`
  position: relative;
  width: 100%;
  display: ${props => (props['data-auto'] ? 'block !important' : 'inherit')};
  height: ${props => (props['data-auto'] ? 'auto' : '100%')};
  min-height: ${props =>
    props['data-auto'] ? '500px' : props['data-min-height'] + 'px'};

  ${media.tablet`
    display: block !important;
    width: 100%;
    height: auto;
    min-height: 0;
  `}
`;

const SectionItems = styled(FlexColumn)`
  flex: 1 1 100px;
  overflow: auto;

  ${media.tablet`
    display: block !important;
    overflow: unset;
  `}
`;

const SpacerWrapper = styled.div`
  width: 100%;
  height: 55px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: white;
  box-sizing: border-box;
`;

export const SpacerColor = styled.div`
  width: 100%;
  height: 45px;
  background-color: rgb(191, 207, 209);
`;

export const Spacer = () => (
  <SpacerWrapper>
    <SpacerColor />
  </SpacerWrapper>
);

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
`;

export let Section = ({ y, bgImage, ...props }) => {
  const features = useContext(FeaturesContext);

  return (
    <SectionWrapper
      id={props.id}
      className="section"
      data-auto={features.active('autoSectionHeight')}
      data-min-height={y}
    >
      <Background image={bgImage} />
      <SectionItems
        style={flexify({
          ...props.contentStyle,
          /**
           * If we need to limit the height of the elements...
           */
          // flex: '1 1 900px',
          // maxHeight: 900,
        })}
      >
        {props.children}
      </SectionItems>

      <Spacer />
    </SectionWrapper>
  );
};
Section = connect(state => {
  return {
    y: state.Window.y,
  };
})(Section);

export function SectionStandIn(props) {
  return (
    <Section
      style={{
        height: 550,
        ...props.style,
      }}
    >
      {props.children}
    </Section>
  );
}

export function SectionWithSideContent(props) {
  return (
    <Section {...props} id={props.id}>
      <Flexbox
        style={{
          height: '100%',
          flexGrow: 1,
          alignItems: 'stretch',
          ...props.style,
        }}
      >
        <SideContent
          {...props}
          style={{
            ...props.sideContentStyle,
          }}
        >
          {props.sideContent}
        </SideContent>

        <Content
          style={{
            ...props.contentStyle,
          }}
        >
          {props.content}
        </Content>
      </Flexbox>
    </Section>
  );
}

export function SideParagraph(props) {
  if (props.dangerouslySetInnerHTML) {
    return (
      <div
        style={{
          marginBottom: 14,
          ...props.style,
        }}
      >
        <Text
          className={props.className}
          dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
        />
      </div>
    );
  } else {
    return (
      <div
        style={{
          marginBottom: 14,
          ...props.style,
        }}
      >
        <Text className={props.className}>{props.children}</Text>
      </div>
    );
  }
}
export { SideParagraph as Paragraph };

export function SectionWithSideContentFullBg(props) {
  return (
    <Section {...props} id={props.id}>
      <Flexbox
        style={{
          height: '100%',
          flexGrow: 1,
          alignItems: 'stretch',
          ...props.style,
          position: 'relative',
        }}
      >
        <SideContent
          {...props}
          style={{
            // opacity: 0.9,
            ...props.sideContentStyle,
          }}
        >
          {props.sideContent}
        </SideContent>

        <Content
          style={{
            ...props.contentStyle,
          }}
        >
          {props.content}
        </Content>
      </Flexbox>
    </Section>
  );
}
