import React from 'react';
import { FlexColumn } from '../shared/Flexbox';
import { connect } from 'react-redux';
import { BulletLine as Bline, BulletSimple } from '../shared/Bullets';

const BulletLine = props => {
  return (
    <Bline
      icon={<BulletSimple />}
      style={{
        alignItems: 'baseline',
        cursor: 'default',
      }}
    >
      {props.children}
    </Bline>
  );
};

export let MenuCongrats = ({ congrats }) => {
  return (
    <FlexColumn
      style={{
        width: 450,
      }}
    >
      {congrats &&
        congrats.map((msg, i) => {
          // skip blank lines
          if (!msg.length) {
            return <></>;
          }

          return <BulletLine key={i}>{msg}</BulletLine>;
        })}
    </FlexColumn>
  );
};

MenuCongrats = connect(state => {
  return {
    congrats: state.Data.event.congrats,
  };
})(MenuCongrats);
