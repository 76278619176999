import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import * as Sentry from "@sentry/browser";
import AppRouter from "./router";
import "./stylesheets";

if (window.location.hostname !== "localhost") {
  Sentry.init({
    dsn:
      "https://5b3942ac9aea48a0aa7b17cb654f74dc@o375145.ingest.sentry.io/5194156",
  });
}

ReactDOM.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>,
  document.getElementById("rbc")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/**
 * Shims for old browsers
 */
if (!console.debug) {
  console.debug = console.log;
}

window.onunhandledrejection = (promiseRejectionEvent) => {
  const { reason } = promiseRejectionEvent;

  // handle error here, for example log
  store.dispatch({
    type: "SET_ERROR",
    message: reason.message,
    stack: reason.stack,
  });
};

window.onerror = (message, source, lineno, colno, error) => {
  store.dispatch({
    type: "SET_ERROR",
    message: error.message,
    stack: error.stack,
  });
};
