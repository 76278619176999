import { RECEIVED_DATA } from '../actions/Data';

const initState = {
  changes: [],
};

/**
 * Function to determine if the person is a clergy member
 */
export const _isClergy = data => {
  return (
    data.isPriest ||
    data.isNun ||
    data.isDeacon ||
    data.isBishop ||
    data.isBrother ||
    /w+/.test(data.clergyType)
  );
};

export const isVisible = data => {
  return (
    data.enrollment.type === 'ANNUAL' ||
    (data.trustor.whatsNew && data.trustor.whatsNew.length > 0)
  );
};

export const WhatsNew = (state = initState, action) => {
  switch (action.type) {
    case RECEIVED_DATA: {
      let changes = [];
      const data = action.data;

      changes = []
        .concat(data.trustor.whatsNew || [])
        .concat(data.event.whatsNew || [])
        .filter(change => {
          /**
           * Filter conditional messages
           */
          switch (change.condition) {
            case 'NOT_CLERGY':
              return !_isClergy(data);

            default:
              return true;
          }
        })
        .map(change => {
          let details = change.details;
          if (
            change.details &&
            typeof change.details === 'string' &&
            /\[|\{/.test(change.details[0])
          ) {
            try {
              details = JSON.parse(change.details);
            } catch (e) {
              console.error('Unable to parse `change.details`', e);
            }
          }

          return {
            ...change,
            details,
            selected: false,
          };
        });

      return {
        changes,
        isVisible: isVisible(data),
      };
    }

    default:
      return {
        ...state,
      };
  }
};
