import React, { useEffect, useContext } from 'react';
import { Flexbox } from './components/shared/Flexbox';
import { Canvas } from './components/shared/Layout';
import { WelcomeSection } from './components/welcome/WelcomeSection';
import { WhatsNewSection } from './components/whatsNew/WhatsNewSection';
import { BenefitsNow } from './components/benefitsNow/BenefitsNow';
import { CompareAlt as CompareBenefitsAlt } from './components/compare/CompareAlt';
import { NonReta } from './components/other/NonReta';
import { AboutPlans } from './components/about/AboutPlans';
import { DTool } from './components/decision/DTool';
import Contacts from './components/contacts/Contacts';
import { connect } from 'react-redux';
import { fetchApiHost } from './actions/Data';
import { Network } from './components/network/Network';
import { NetworkDelta } from './components/network/delta/Network';
import { media, sizes, Desktop } from './constants';
import styled from 'styled-components';
import { onResize } from './actions/Window';
import { MobileMenu } from './components/menu/MobileMenu';
import { store } from './store';
import Fetching from './components/Fetching';
import ErrorBoundary from './components/ErrorBoundary';
import FeaturesProvider, { FeaturesContext } from './contexts/features';
import ConfigProvider from './contexts/config';
import Workplace from './components/workplace/Workplace';

const FirstSection = styled.div`
  display: block;
  flex: 0 0 50px;
  width: 100%;

  ${media.tablet`
    display: none;
  `}
`;

export let Sections = ({
  initialized,
  plans,
  options,
  error,
  networks,
  showWhatsNew,
  workplace,
  ft,
  ihcId,
  eventId,
}) => {
  const features = useContext(FeaturesContext);

  useEffect(() => {
    if (!features.active('autoSectionHeight')) {
      store.dispatch(onResize());
    }
  }, [features]);

  useEffect(() => {
    if (ihcId !== 'reta') {
      window.document.title = 'Your Benefits Center';
    }
  }, [ihcId]);

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      {!error && !initialized && <Fetching />}

      {!error && initialized && (
        <Canvas>
          <MobileMenu />

          {![
            'onlyNetworks',
            'onlyRx',
            'onlyDental',
            'onlyVision',
            'onlyWellness',
          ].some(ft.active) && (
            <>
              <FirstSection />

              <WelcomeSection />

              {!ft.active('hideWhatsNew') && showWhatsNew && (
                <WhatsNewSection />
              )}

              <BenefitsNow />

              {plans.medical.length > 0 && <AboutPlans />}
            </>
          )}

          {(ft.active('onlyNetworks') ||
            !['onlyRx', 'onlyDental', 'onlyVision', 'onlyWellness'].some(
              ft.active,
            )) &&
            networks.medical.map((plan, i) => (
              <Network
                key={i}
                id={`medical-${plan.network.toLowerCase()}`}
                plan={plan}
                isMedical={true}
              />
            ))}

          {plans.medical.length > 0 &&
            ![
              'onlyNetworks',
              'onlyRx',
              'onlyDental',
              'onlyVision',
              'onlyWellness',
            ].some(ft.active) && <CompareBenefitsAlt />}

          {![
            'onlyNetworks',
            'onlyRx',
            'onlyDental',
            'onlyVision',
            'onlyWellness',
          ].some(ft.active) && (
            <>
              {ft.active('showBridgeHealth') &&
                plans.bridgeHealth &&
                plans.bridgeHealth.length > 0 && (
                  <Network id={'bridgeHealth'} plan={plans.bridgeHealth[0]} />
                )}

              {ft.active('showVivante') &&
                plans.vivante &&
                plans.vivante.length > 0 && (
                  <Network id={'vivante'} plan={plans.vivante[0]} />
                )}

              {ft.active('showLivongo') &&
                plans.livongo &&
                plans.livongo.length > 0 && (
                  <Network id={'livongo'} plan={plans.livongo[0]} />
                )}

              <Desktop>
                {options.enableDecisionTool && plans.medical.length > 1 && (
                  <DTool />
                )}
              </Desktop>
            </>
          )}

          {(!['onlyNetworks', 'onlyDental', 'onlyVision', 'onlyWellness'].some(
            ft.active,
          ) ||
            ft.active('onlyRx')) &&
            networks.rx.map((plan, i) => {
              return (
                <Network key={i} id={plan.network.toLowerCase()} plan={plan} />
              );
            })}

          {(!['onlyNetworks', 'onlyRx', 'onlyVision', 'onlyWellness'].some(
            ft.active,
          ) ||
            ft.active('onlyDental')) &&
            networks.dental.map((plan, i) => {
              // if (plan.carrierId === 'delta' && eventId === '2023-oe') {
              //   return (
              //     <NetworkDelta
              //       key={i}
              //       id={plan.network.toLowerCase()}
              //       plan={plan}
              //     />
              //   );
              // }

              return (
                <Network key={i} id={plan.network.toLowerCase()} plan={plan} />
              );
            })}

          {(!['onlyNetworks', 'onlyRx', 'onlyDental', 'onlyWellness'].some(
            ft.active,
          ) ||
            ft.active('onlyVision')) &&
            networks.vision.map((plan, i) => {
              return (
                <Network key={i} id={plan.network.toLowerCase()} plan={plan} />
              );
            })}

          {(!['onlyNetworks', 'onlyRx', 'onlyDental', 'onlyVision'].some(
            ft.active,
          ) ||
            ft.active('onlyWellness')) &&
            networks.wellness
              .filter(
                plan =>
                  // skip catapult health if it's not enabled
                  !(
                    !ft.active('showCatapultHealth') &&
                    plan.carrierId === 'catapult'
                  ) &&
                  !(
                    !ft.active('showVirtualMission') &&
                    plan.carrierId === 'virtualMission'
                  ),
              )
              .map((plan, i) => {
                return (
                  <Network
                    key={i}
                    id={
                      /MY_VIRTUAL_MISSION/i.test(plan.network)
                        ? 'mvm'
                        : `wellness-${plan.network.toLowerCase()}`
                    }
                    plan={plan}
                  />
                );
              })}

          {![
            'onlyNetworks',
            'onlyRx',
            'onlyDental',
            'onlyVision',
            'onlyWellness',
          ].some(ft.active) &&
            networks.retirement &&
            networks.retirement.map((plan, i) => {
              return <Network key={i} id={`retirement`} plan={plan} />;
            })}

          {![
            'onlyNetworks',
            'onlyRx',
            'onlyDental',
            'onlyVision',
            'onlyWellness',
          ].some(ft.active) &&
            networks.other.length > 0 && <NonReta />}

          {![
            'onlyNetworks',
            'onlyRx',
            'onlyDental',
            'onlyVision',
            'onlyWellness',
          ].some(ft.active) && (
            <>
              {ft.active('showWorkplace') &&
                workplace &&
                workplace.map((wrk, i) => {
                  return <Workplace key={i} id={`workplace-${i}`} data={wrk} />;
                })}
              <Contacts
                style={{
                  marginBottom: 0,
                }}
              />
            </>
          )}
        </Canvas>
      )}
    </div>
  );
};

Sections = connect(state => {
  const language = state.Language.current;
  let workplace =
    state.Data.workplace &&
    state.Data.workplace.filter(wrk => wrk.language === language);

  // if language not found, return english
  if (workplace && !workplace.length) {
    workplace =
      state.Data.workplace &&
      state.Data.workplace.filter(wrk => wrk.language === 'ENGLISH');
  }

  return {
    initialized: state.Data.initialized,
    plans: state.Data.plans.available,
    options: state.Options,
    networks: state.Networks.networks,
    showWhatsNew: state.WhatsNew.isVisible,
    workplace,
    ft: state.Features.features,
    ihcId: state.Data.ihcId,
    eventId: state.Data.eventId,
  };
})(Sections);

const MainLayout = styled(Flexbox)`
  justify-content: center;

  ${media.desktop`
    justify-content: flex-start;
  `}

  ${media.tablet`
    background-color: white;
    width: 100%;
  `}
`;

const Width = styled.div`
  width: ${sizes.desktop}px;

  ${media.desktop`
    width: 100%;
  `}
`;

export const App = () => {
  useEffect(() => {
    /**
     * Get the Api Host
     */
    store.dispatch(fetchApiHost());
  }, []);

  return (
    <ConfigProvider>
      <FeaturesProvider>
        <ErrorBoundary>
          <MainLayout
            onClick={() => {
              store.dispatch({ type: 'HIDE_ALL_MENUS' });
              store.dispatch({ type: 'SHOW_MOBILE_MENU', value: false });
            }}
          >
            <Width>
              <Sections />
            </Width>
          </MainLayout>
        </ErrorBoundary>
      </FeaturesProvider>
    </ConfigProvider>
  );
};
export { App as default };
