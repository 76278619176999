import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 10px;
  cursor: pointer;
  width: fit-content;
  position: relative;
  max-width: 100%;
`;

const Img = styled.img`
  width: 100%;
`;

export function Logo(props) {
  return (
    <Wrapper {...props}>
      <Img src={props.src} />
    </Wrapper>
  );
}

Logo.defaultProps = {
  onClick: () => console.log('Click'),
};
