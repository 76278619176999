import { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ConfigContext } from '../../contexts/config';
import queryString from 'query-string';
import Modal from 'react-modal';
import imgClose from '../../assets/img/close.svg';
import { Container, Sticky, Messages, BodyStyle } from './BulletinBoard.styles';
import clsx from 'clsx';
import imgCheck from '../../assets/img/bulletinBoardCheck.png';

Modal.setAppElement('#rbc');

const BulletinBoard = ({ orgId, firstName, lastName }) => {
  const config = useContext(ConfigContext);
  const location = useLocation();
  const history = useHistory();

  const [allBulletins, setAllBulletins] = useState([]);
  const [currentBulletins, setCurrentBulletins] = useState([]);
  const [currentBulletin, setCurrentBulletin] = useState();
  const [latest, setLatest] = useState([]);
  const [showBulletinBoard, setShowBuletinBoard] = useState(false);

  // get the bulletins
  useEffect(() => {
    (async () => {
      const res = await fetch(
        `${config.bbHost}/bulletins?` + new URLSearchParams({ orgId }),
      );
      const bulletins = await res.json();

      setAllBulletins(bulletins.all);
      setCurrentBulletins(bulletins.current);
    })();
  }, [config, orgId]);

  // get the top 3 bulletins
  useEffect(() => {
    setLatest(currentBulletins.slice(0, 2));
  }, [currentBulletins]);

  useEffect(() => {
    const { bulletin, showBulletinBoard } = queryString.parse(location.search);
    setCurrentBulletin(allBulletins.find(d => d.id === bulletin));

    setShowBuletinBoard(/true|1/.test(showBulletinBoard));
  }, [location, allBulletins]);

  /**
   * Handle bulletin selection
   */
  const handleSelect = id => {
    const parsed = queryString.parse(location.search);

    history.push({
      search: queryString.stringify({
        ...parsed,
        bulletin: id,
        showBulletinBoard: true,
      }),
    });
  };

  const handleAllMessages = () => {
    const parsed = queryString.parse(location.search);
    delete parsed.bulletin;

    history.push({
      search: queryString.stringify({
        ...parsed,
        showBulletinBoard: true,
      }),
    });
  };

  /**
   * Handle close events
   */
  const handleClose = () => {
    const parsed = queryString.parse(location.search);
    delete parsed.showBulletinBoard;
    delete parsed.bulletin;

    history.push({
      search: queryString.stringify(parsed),
    });
  };

  // Don't render anything if there are no messages
  if (!allBulletins.length) {
    console.warn('No bulletins found.');
    return <div></div>;
  }

  return (
    <>
      {/* Stick Note */}
      <Container>
        <Sticky>
          <div className="fullname">
            {firstName} {lastName}
          </div>

          <div className="slug">Your Benefits Bulletin Board</div>

          <p>
            Your exclusive place to keep in touch (<i>and stay in touch</i>)
            with benefit matters that matter to you.
          </p>

          {/* Latest items */}
          <div className="list">
            {latest.length > 0 &&
              latest.map(bulletin => {
                return (
                  <div
                    className="item"
                    key={bulletin.id}
                    onClick={() => handleSelect(bulletin.id)}
                  >
                    <img src={imgCheck} alt="" />
                    <div>{bulletin.title}</div>
                  </div>
                );
              })}

            <div className="item" key="all" onClick={handleAllMessages}>
              <img src={imgCheck} alt="" />
              All Messages
            </div>
          </div>
        </Sticky>
      </Container>

      {/* Bulletin Board Modal */}
      <Modal
        isOpen={showBulletinBoard}
        onRequestClose={handleClose}
        contentLabel="Reta Bulletin Board"
        style={{
          content: {
            width: '100%',
            maxWidth: '75vw',
            height: '100%',
            minHeight: 400,
            maxHeight: '80vh',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: 0,
            boxShadow: '0 8px 20px 0 rgba(20, 0, 0, .3)',
            overflow: 'hidden',
          },
        }}
        shouldCloseOnEsc={true}
      >
        <Messages>
          <div className="top">
            <h3>Your Benefits Bulletin Board</h3>

            <div className="close" onClick={handleClose}>
              <img src={imgClose} alt="" />
            </div>
          </div>

          <div className="main">
            {/* Message List */}
            <div className="left">
              {allBulletins.map(bulletin => {
                return (
                  <div
                    className={clsx(
                      'item',
                      currentBulletin &&
                        bulletin.id === currentBulletin.id &&
                        'active',
                    )}
                    onClick={() => handleSelect(bulletin.id)}
                    key={bulletin.id}
                  >
                    <div className="title">{bulletin.title}</div>
                    {bulletin.slug && (
                      <div className="slug">{bulletin.slug}</div>
                    )}
                  </div>
                );
              })}
            </div>

            {/* Message Body */}
            <div className="right">
              {currentBulletin && (
                <div>
                  <div className="title">{currentBulletin.title}</div>
                  <div className="slug">{currentBulletin.slug}</div>
                  <BodyStyle
                    className="body"
                    dangerouslySetInnerHTML={{ __html: currentBulletin.body }}
                  />
                </div>
              )}

              {!currentBulletin && (
                <div className="note">
                  <div>Please select a message on the left.</div>
                </div>
              )}
            </div>
          </div>
        </Messages>
      </Modal>
    </>
  );
};

export default connect(state => ({
  orgId: state.Data.orgId,
  firstName: state.Data.personalInfo.name.first,
  lastName: state.Data.personalInfo.name.last,
}))(BulletinBoard);
