import { combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import {
  SET_PERSONA,
  SET_VIM,
  SET_OUTPUT,
  FETCHING_PRESENTATION,
  FETCH_ERROR_PRESENTATION,
  FETCHED_PRESENTATION,
} from '../actions/Editor';

const initialState = {
  personas: [
    {
      name: 'BSG',
      url: '/assets/json/personas/bsg.json',
    },
    {
      name: 'Demo',
      url: '/assets/json/personas/demo.json',
    },
    {
      name: 'Tony Montana',
      url: '/assets/json/personas/tonyMontana.json',
    },
    {
      name: 'Rob Geyer',
      url: '/assets/json/personas/robGeyer.json',
    },
    {
      name: 'Texas Pete',
      url: '/assets/json/personas/texas.json',
    },
    {
      name: 'BAS 12',
      url: '/assets/json/personas/bas12.json',
    },
    {
      name: 'Babe Ruth',
      url: '/assets/json/personas/babeRuth.json',
    },
    {
      name: 'Fab Five Freddy',
      url: '/assets/json/personas/fabFive.json',
    },
    {
      name: 'NA',
      url: '/assets/json/personas/na.json',
    },
    {
      name: 'Check Rx',
      url: '/assets/json/personas/checkRx.json',
    },
    {
      name: 'Workplace',
      url: '/assets/json/personas/workplace.json',
    },
  ],
  carriers: [
    {
      name: 'AETNA',
      url: '/assets/json/personas/carriers/aetna.json',
    },
    {
      name: 'ANTHEM',
      url: '/assets/json/personas/carriers/anthem.json',
    },
    {
      name: 'BLUE_SHIELD_CA',
      url: '/assets/json/personas/carriers/bsca.json',
    },
    {
      name: 'KAISER',
      url: '/assets/json/personas/carriers/kaiser.json',
    },
    {
      name: 'UHC',
      url: '/assets/json/personas/carriers/uhc.json',
    },
    {
      name: 'DELTA',
      url: '/assets/json/personas/carriers/delta.json',
    },
    {
      name: 'ENVISIONRX',
      url: '/assets/json/personas/carriers/envisionrx.json',
    },
    {
      name: 'VSP',
      url: '/assets/json/personas/carriers/vsp.json',
    },
    {
      name: 'WELLNESS',
      url: '/assets/json/personas/carriers/wellness.json',
    },
    {
      name: 'WEBMD (legacy)',
      url: '/assets/json/personas/carriers/webmd-legacy.json',
    },
    {
      name: 'WEBMD',
      url: '/assets/json/personas/carriers/webmd.json',
    },
    {
      name: 'FITBIT',
      url: '/assets/json/personas/carriers/fitbit.json',
    },
    {
      name: 'WW',
      url: '/assets/json/personas/carriers/ww.json',
    },
    {
      name: 'VIVANTE',
      url: '/assets/json/personas/carriers/vivante.json',
    },
    {
      name: 'ELIXIR',
      url: '/assets/json/personas/carriers/elixir.json',
    },
    {
      name: 'CVS',
      url: '/assets/json/personas/carriers/cvs.json',
    },
    {
      name: 'CATAPULT',
      url: '/assets/json/personas/carriers/catapult.json',
    },
  ],
  json: '',
  vim: false,
  output: '',
  fetching: false,
};

const Editor = (state = initialState, action) => {
  switch (action.type) {
    case SET_PERSONA:
      return {
        ...state,
        json: action.json,
        output: action.json,
      };

    case SET_VIM:
      return {
        ...state,
        vim: action.value,
      };

    case SET_OUTPUT:
      return {
        ...state,
        output: action.value,
      };

    case FETCHING_PRESENTATION:
      return {
        ...state,
        fetching: true,
        error: false,
      };

    case FETCH_ERROR_PRESENTATION:
      return {
        ...state,
        fetching: false,
        error: true,
        message: action.message,
      };

    case FETCHED_PRESENTATION:
      return {
        ...state,
        fetching: false,
      };

    default:
      return state;
  }
};

const rootReducer = combineReducers({
  Editor,
});

const loggerMiddleware = createLogger();

export const store = createStore(
  rootReducer,
  applyMiddleware(thunkMiddleware, loggerMiddleware),
);
