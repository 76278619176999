import { SELECT_ENGLISH, SELECT_SPANISH } from '../actions/Language';

const initialState = {
  current: 'ENGLISH',
};

export const Language = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_ENGLISH:
      return {
        ...state,
        current: 'ENGLISH',
      };

    case SELECT_SPANISH:
      return {
        ...state,
        current: 'SPANISH',
      };

    default:
      return state;
  }
};
