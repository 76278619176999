export const SELECT_ENGLISH = 'SELECT_ENGLISH';
export const selectEnglish = () => {
  return {
    type: SELECT_ENGLISH,
  };
};

export const SELECT_SPANISH = 'SELECT_SPANISH';
export const selectSpanish = () => {
  return {
    type: SELECT_SPANISH,
  };
};
