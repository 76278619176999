import React from 'react';
import { SideParagraph } from '../shared/Layout';
import { TextEmphasis, OrangeText, SideTitle } from '../shared/Text';
import { EnrollDeadlineMsg } from '../shared/Enrollment';
import { FullName } from '../shared/PII';
import { connect } from 'react-redux';
import { Padding } from '../welcome/SideContent';
import styled from 'styled-components';
import { sizes } from '../../constants';
import moment from 'moment';

const SidePadding = styled(Padding)`
  padding: 36px;
  padding-right: 0;
  box-sizing: border-box;

  @media (max-width: ${sizes.desktop}px) {
    padding: 36px;
    padding-top: 86px;
  }
`;

const Wrapper = styled.div`
  flex: 0 0 235px;
`;

let _SideContent = ({
  numOfPlans,
  individualNonZero,
  hasDefault,
  isOE,
  isNewHire,
  ...props
}) => {
  return (
    <Wrapper>
      <SidePadding horizontal={props.horizontal}>
        <SideTitle>
          <OrangeText>
            <FullName />,
          </OrangeText>
          {numOfPlans > 1
            ? " here's how your plans compare."
            : ' review your medical plan overview.'}
        </SideTitle>

        {/* Open Enrollment */}
        {isOE && (
          <div>
            <SideParagraph
              style={{
                marginTop: props.horizontal ? 20 : 32,
              }}
            >
              {numOfPlans === 1 && (
                <span>
                  Here&apos;s an overview of your Reta medical plan benefits,
                  features and costs. Before you enroll, you may want to compare
                  this plan with other options you may have available to you.
                </span>
              )}

              {numOfPlans > 1 && individualNonZero && (
                <span>
                  Before you choose a medical plan, compare the key features and
                  benefits of each option to see how they add up for you.
                </span>
              )}

              {numOfPlans > 1 && !individualNonZero && (
                <span>
                  Before you enroll for medical coverage, compare the key
                  features and benefits of your plan.
                </span>
              )}
            </SideParagraph>

            {hasDefault && (
              <SideParagraph
                style={Object.assign(
                  {
                    marginTop: 20,
                  },
                  props.horizontal && {
                    marginTop: 0,
                    marginBottom: 0,
                  },
                )}
              >
                As a point of reference, the medical plan in which you are
                currently enrolled is outlined in{' '}
                <OrangeText>orange.</OrangeText>
              </SideParagraph>
            )}
          </div>
        )}

        {/* Post Open Enrollment */}
        {!isOE && !isNewHire && (
          <SideParagraph
            style={{
              marginTop: props.horizontal ? 20 : 32,
            }}
          >
            {numOfPlans === 1 && (
              <span>
                Here&apos;s an overview of your Reta medical plan benefits,
                features and costs made available to you for the{' '}
                <EnrollmentYear /> plan year. You will not be able to change
                your current election for <EnrollmentYear />
                unless you have a qualified life event (such as having a baby or
                getting married) that allows you to make enrollment changes
                before then. Check with your benefits contact for more
                information on qualifying life events.
              </span>
            )}

            {numOfPlans > 1 && (
              <span>
                Here is a side-by-side comparison of the key features, benefits
                and costs of the medical plans made available to you for the{' '}
                <EnrollmentYear /> plan year. You will not be able to change
                your current election for <EnrollmentYear /> unless you have a
                qualified life event (such as having a baby or getting married)
                that allows you to make enrollment changes before then. Check
                with your benefits contact for more information on qualifying
                life events.
              </span>
            )}
          </SideParagraph>
        )}

        {/* Post Open Enrollment */}
        {isNewHire && (
          <SideParagraph
            style={{
              marginTop: props.horizontal ? 20 : 32,
            }}
          >
            Here is a side-by-side comparison of the key features, benefits and
            costs of the medical plans available to you for the{' '}
            <EnrollmentYear /> plan year. Once you enroll, you won’t be able to
            change your elections unless you have a qualified life event such as
            having a baby or getting married. Check with your benefits contact
            for more information on qualifying life events.
          </SideParagraph>
        )}

        {/* Enrollment Deadline */}
        {isOE && (
          <SideParagraph
            style={{
              marginTop: 20,
              marginBottom: 0,
            }}
          >
            <TextEmphasis>
              <EnrollDeadlineMsg />
            </TextEmphasis>
          </SideParagraph>
        )}
      </SidePadding>
    </Wrapper>
  );
};

export const SideContent = connect(state => {
  return {
    numOfPlans: state.Data.plans.available.medical.length,
    // TODO: Once BAS moves to the new schema, uncomment this (Tue Mar 21 13:17:07 2017);
    // individualNonZero:
    //   state.Data.plans.available.medical.reduce((prev, cur) => {
    //     if (prev) { return prev; }
    //
    //     // reduce for the `individual` amount
    //     const amount = cur.costs.reduce((p, c) => {
    //       if (p) return p;
    //
    //       if (/^individual$/i.test(c.name)) {
    //         return c.amount;
    //       }
    //     }, 0);
    //
    //     return Number(amount.replace(/\$|,/g, '')) > 0;
    //   }, false),
    individualNonZero: true,
    hasDefault: state.Data.plans.available.medical.reduce((prev, cur) => {
      if (prev) {
        return prev;
      }
      return cur.default;
    }, false),
    isOE: state.Data.enrollment.type === 'ANNUAL',
    isNewHire: state.Data.enrollment.type === 'NEW_HIRE',
  };
})(_SideContent);

const EnrollmentYear = connect((state, ownProps) => {
  return {
    enroll: state.Data.enrollment,
    ...ownProps,
  };
})(({ enroll }) => {
  return <span>{moment(enroll.dateEnd, 'YYYY-MM-DD').format('YYYY')}</span>;
});
