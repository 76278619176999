import React, { useEffect, useRef, useContext, useState } from 'react';
import { showMenu, hideAll } from '../../actions/Welcome';
import { SideParagraph } from '../shared/Layout';
import { TextEmphasis, OrangeText, SideTitle } from '../shared/Text';
import { FullName, LocationName, TrustorName } from '../shared/PII';
import { EnrollStartDate, EnrollEndDate } from '../shared/Enrollment';
import { BulletLargeBlueYellow, BulletLine } from '../shared/Bullets';
import { connect } from 'react-redux';
import { CongratsArrow } from './CongratsArrow';
import MenuClient from './MenuClient';
import { MenuTrustor } from './MenuTrustor';
import { MenuLocation } from './MenuLocation';
import { MenuCongrats } from './MenuCongrats';
import _has from 'lodash.has';
import { COLORS, sizes } from '../../constants';
import styled from 'styled-components/macro';
import Covid19 from './Covid19/Covid19';
import { FeaturesContext } from '../../contexts/features';
import imgCheckYellow from '../../assets/img/checkYellow.svg';
import { MenuBSGGov } from './MenuBSGGov';
import { MenuBSGDOC } from './MenuBSGDOC';
import { MenuBSGETF } from './MenuBSGETF';
import Calculator from './Calculator';
import BSG from './BSG';
import BulletinBoard from './BulletinBoard';

const Modal = styled.div`
  background-color: ${COLORS.ANTHEM_BLUE};
  box-sizing: border-box;
  padding: 36px;

  @media (min-width: ${sizes.tablet}px) {
    display: none;
  }
`;

export const Padding = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 36px;
`;

const PaddingBullet = styled(Padding)`
  // padding-top: 6px;
  // padding-bottom: 6px;
  padding: 0 36px;
`;

const Group = styled.div`
  &.flex {
    flex: 1 1 150px;
    overflow-y: auto;
  }

  &.covid {
    flex: 0 0 270px;
  }
`;

const Blue = styled.span`
  color: #016ec1;
  font-weight: bold;
  font-style: italic;
`;

const Balances = styled.div`
  border-radius: 10px;
  color: #fefdfd;
  padding: 12px 36px;
  padding-right: 24;
  background-color: #4d8cc1;
  line-height: 1.6;
  font-size: 17px;

  & > div:first-child {
    position: relative;

    & > img {
      position: absolute;
      left: -22px;
    }
  }

  & > div:nth-child(2) {
    color: #fefdfd;
    font-size: 0.8em;
  }
`;

const BulletText = styled.span`
  color: #555555;
  font-weight: bold;
`;

const BSGContent = styled.div`
  display: block;

  @media (min-width: ${sizes.tablet + 1}px) {
    display: none;
  }
`;

const _SideContent = ({
  client,
  enableLocationMenu,
  enableTrustorMenu,
  clientWelcomeMessageUrl,
  ihcId,
  ...props
}) => {
  const [showBalances, setShowBalances] = useState(false);
  const elem = useRef();
  const features = useContext(FeaturesContext);

  useEffect(() => {
    /**
     * There is an occasional weird IE bug with the layout.  Simply toggling
     * any CSS setting corrects it.  We can probably fix this with enough time,
     * but for now, let's hack it if we detect IE.
     */
    if (/MSIE\s|Trident\//.test(navigator.userAgent)) {
      setTimeout(() => {
        elem.current.style.width = 'auto';
        setTimeout(() => {
          elem.current.style.width = '100%';
        });
      });
    }
  }, []);

  const handleShowBalances = () => {
    setShowBalances(!showBalances);
  };

  return (
    <>
      <Group className={features.active('autoSectionHeight') ? '' : 'flex'}>
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            boxSizing: 'border-box',
            color: _has(client, 'colors.text.global')
              ? client.colors.text.global
              : COLORS.TEXT,
            marginBottom: features.active('autoSectionHeight') ? 24 : 12,
          }}
          onClick={props.onClose}
        >
          <Padding
            style={{
              paddingBottom: ihcId === 'reta' ? 'inherit' : 0,
            }}
          >
            <div
              style={{
                width: '100%',
              }}
              ref={elem}
            >
              {/* Title */}
              <SideTitle
                className="text-client-global"
                style={{
                  width: '100%',
                }}
              >
                <OrangeText className="text-client-emphasis">
                  <FullName />,
                </OrangeText>{' '}
                <span className="text-client-global">
                  {_has(client, 'welcome.messages') &&
                    client.welcome.messages.slug}
                </span>
              </SideTitle>

              {/* Body */}

              {/* MidYear Welcome Message */}
              {!props.isOE &&
                !props.isNewHire &&
                _has(client, 'welcome.messages.midYear') &&
                client.welcome.messages.midYear.map((msg, idx) => (
                  <SideParagraph
                    className="text-client-global"
                    key={idx}
                    style={{
                      marginTop: 32,
                      marginBottom: 20,
                    }}
                    dangerouslySetInnerHTML={{ __html: msg }}
                  />
                ))}

              {/* Open Enrollment Message */}
              {props.isOE &&
                _has(client, 'welcome.messages.oe') &&
                client.welcome.messages.oe.map((msg, idx) => (
                  <SideParagraph
                    className="text-client-global"
                    key={idx}
                    style={{
                      marginTop: 32,
                      marginBottom: 20,
                    }}
                    dangerouslySetInnerHTML={{ __html: msg }}
                  />
                ))}

              {/* New Hire Welcome Message */}
              {props.isNewHire &&
                _has(client, 'welcome.messages.newHire') &&
                client.welcome.messages.newHire.map((msg, idx) => (
                  <SideParagraph
                    className="text-client-global"
                    key={idx}
                    style={{
                      marginTop: 32,
                    }}
                    dangerouslySetInnerHTML={{ __html: msg }}
                  />
                ))}

              {/* Enrollment Deadline */}
              {props.isOE && props.showDeadline && (
                <SideParagraph
                  className="text-client-global"
                  style={{
                    color: _has(client, 'colors.text.emphasis')
                      ? client.colors.text.emphasis
                      : COLORS.ORANGE,
                    marginTop: 20,
                  }}
                >
                  <TextEmphasis className="text-client-emphasis">
                    Your enrollment period is from <EnrollStartDate /> to{' '}
                    <EnrollEndDate />.
                  </TextEmphasis>
                </SideParagraph>
              )}

              {ihcId === 'bsg' && (
                <SideParagraph className="text-client-global">
                  In the end, <Blue>you</Blue> are in charge of{' '}
                  <Blue>your</Blue> wellness and out-of-pocket costs. So stay
                  healthy and choose the plans that best suit your needs and
                  budget.
                </SideParagraph>
              )}
            </div>
          </Padding>

          {/* A message from the client */}
          {ihcId === 'reta' && !features.active('bulletinBoard') && (
            <>
              <PaddingBullet>
                <BulletLine
                  style={{ marginTop: 0 }}
                  onClick={e => {
                    if (!clientWelcomeMessageUrl) {
                      e.stopPropagation();
                      props.onShowMenu(e, 'reta');
                    }
                  }}
                  selected={props.showRetaMenu}
                >
                  <a
                    href={clientWelcomeMessageUrl}
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{ textDecoration: 'none' }}
                  >
                    <span className="text-client-global">
                      A message from {client.displayName}
                    </span>
                  </a>
                </BulletLine>
              </PaddingBullet>

              {props.showClientMenu && (
                <Modal>
                  <MenuClient />
                </Modal>
              )}

              {/* A message from the organization */}
              {enableTrustorMenu && (
                <PaddingBullet>
                  <BulletLine
                    onClick={e => props.onShowMenu(e, 'trustor')}
                    selected={props.showTrustorMenu}
                  >
                    <span className="text-client-global">
                      A message from <TrustorName />
                    </span>
                  </BulletLine>
                </PaddingBullet>
              )}

              {props.showTrustorMenu && (
                <Modal>
                  <MenuTrustor />
                </Modal>
              )}

              {/* A message from the location */}
              {enableLocationMenu && (
                <PaddingBullet>
                  <BulletLine
                    onClick={e => props.onShowMenu(e, 'location')}
                    selected={props.showLocationMenu}
                  >
                    <span className="text-client-global">
                      News from <LocationName />
                    </span>
                  </BulletLine>
                </PaddingBullet>
              )}

              {props.showLocationMenu && (
                <Modal>
                  <MenuLocation />
                </Modal>
              )}

              {props.isOE && props.showCongrats && (
                <div>
                  <CongratsArrow />
                  <Modal>
                    <MenuCongrats />
                  </Modal>
                </div>
              )}
            </>
          )}

          {ihcId === 'bsg' && (
            <>
              <PaddingBullet>
                <BulletLine
                  icon={BulletLargeBlueYellow}
                  onClick={e => props.onShowMenu(e, 'reta')}
                  selected={props.showRetaMenu}
                >
                  <BulletText>A Message from Governor Tony Evers</BulletText>
                </BulletLine>
              </PaddingBullet>

              {props.showRetaMenu && (
                <Modal>
                  <MenuBSGGov />
                </Modal>
              )}

              <PaddingBullet>
                <BulletLine
                  icon={BulletLargeBlueYellow}
                  onClick={e => props.onShowMenu(e, 'trustor')}
                  selected={props.showTrustorMenu}
                >
                  <BulletText>
                    A Message from Kevin A. Carr, DOC Secretary
                  </BulletText>
                </BulletLine>
              </PaddingBullet>

              {props.showTrustorMenu && (
                <Modal>
                  <MenuBSGDOC />
                </Modal>
              )}

              <PaddingBullet>
                <BulletLine
                  icon={BulletLargeBlueYellow}
                  onClick={e => props.onShowMenu(e, 'location')}
                  selected={props.showLocationMenu}
                >
                  <BulletText>
                    A Message from Robert Conlin, ETF Secretary
                  </BulletText>
                </BulletLine>
              </PaddingBullet>

              {props.showLocationMenu && (
                <Modal>
                  <MenuBSGETF />
                </Modal>
              )}

              {/* Balances */}
              <div
                style={{
                  padding: 36,
                  paddingRight: 24,
                  minHeight: 100,
                  cursor: 'pointer',
                }}
                onClick={handleShowBalances}
              >
                {!showBalances && (
                  <Balances>
                    <div>
                      <img src={imgCheckYellow} alt="" />
                      Check Your Current Balances
                    </div>
                    <div>Plan Deductibles. HSAs. 403(b) Plan. And More.</div>
                  </Balances>
                )}

                {showBalances && <Calculator />}
              </div>

              <BSGContent>
                <BSG />
              </BSGContent>
            </>
          )}
        </div>
      </Group>

      {/* Covid 19 Message */}
      {ihcId === 'reta' &&
        features.active('showRetaC19') &&
        features.active('sideC19') && (
          <Group
            className={features.active('autoSectionHeight') ? '' : 'covid flex'}
          >
            <Covid19 />
          </Group>
        )}

      {/* Bulletin Board */}
      {ihcId === 'reta' && features.active('bulletinBoard') && (
        <BulletinBoard />
      )}
    </>
  );
};

/**
 * Connect to redux
 */
export const SideContent = connect(
  state => {
    return {
      showRetaMenu: state.Welcome.menus.reta.active,
      showTrustorMenu:
        _has(state.Data, 'trustor.id') && state.Welcome.menus.trustor.active,
      showLocationMenu:
        _has(state.Data, 'location.id') && state.Welcome.menus.location.active,
      isOE: state.Data.enrollment.type === 'ANNUAL',
      isNewHire: state.Data.enrollment.type === 'NEW_HIRE',
      showCongrats: state.Data.event.disableCongrats
        ? false
        : state.Welcome.menus.congrats.active,
      showDeadline:
        state.Data.enrollment.dateStart && state.Data.enrollment.dateEnd,
      client: state.Client,
      enableTrustorMenu: !state.Features.features.active(
        'hideWelcomeTrustorMenu',
      ),
      enableLocationMenu: !state.Features.features.active(
        'hideWelcomeLocationMenu',
      ),
      clientWelcomeMessageUrl: state.Welcome.clientWelcomeMessageUrl,
      ihcId: state.Data.ihcId,
    };
  },
  dispatch => {
    return {
      onShowMenu: (e, menuName) => {
        e.stopPropagation(true);
        dispatch(showMenu(menuName));
      },
      onClose: () => dispatch(hideAll()),
    };
  },
)(_SideContent);
