import React from "react";
import styled from "styled-components";
import { FlexColumn } from "./shared/Flexbox";
import { TextDark } from "./shared/Text";
import { Spinner } from "./shared/Spinner";

const FetchComponent = styled(FlexColumn)`
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const TxtLine = styled.div`
  margin-bottom: 24px;
  text-align: center;
`;

let Fetching = () => {
  return (
    <FetchComponent>
      <TxtLine>
        <TextDark>Fetching your information...</TextDark>
      </TxtLine>

      <Spinner />
    </FetchComponent>
  );
};

export default Fetching;
