import React from 'react';
import { FlexColumn } from '../shared/Flexbox';
import { TextEmphasis } from '../shared/Text';
import { SideParagraph } from '../shared/Layout';
import { connect } from 'react-redux';
import { media } from '../../constants';
import styled from 'styled-components';
import _has from 'lodash.has';

export const Header = styled.div`
  margin-bottom: 14px;

  ${media.tablet`
    display: none;
  `}
`;

export let MenuClient = ({
  client,
  message,
  isOE,
  isNewHire,
  isMidYear,
  noCurrentPlans,
}) => {
  return (
    <FlexColumn>
      <Header>
        <TextEmphasis>A Message from {client.displayName}</TextEmphasis>
      </Header>

      {!message &&
        isNewHire &&
        _has(client, 'welcome.messages.menu.newHire') &&
        client.welcome.messages.menu.newHire.map((msg, idx) => (
          <SideParagraph key={idx} dangerouslySetInnerHTML={{ __html: msg }} />
        ))}

      {!message &&
        isMidYear &&
        _has(client, 'welcome.messages.menu.midYear') &&
        client.welcome.messages.menu.midYear.map((msg, idx) => (
          <SideParagraph key={idx} dangerouslySetInnerHTML={{ __html: msg }} />
        ))}

      {!message &&
        isOE &&
        _has(client, 'welcome.messages.menu.oe') &&
        client.welcome.messages.menu.oe.map((msg, idx) => (
          <SideParagraph key={idx} dangerouslySetInnerHTML={{ __html: msg }} />
        ))}

      {!message &&
        isOE &&
        noCurrentPlans &&
        _has(client, 'welcome.messages.menu.oeNoPlans') &&
        client.welcome.messages.menu.oeNoPlans.map((msg, idx) => (
          <SideParagraph key={idx} dangerouslySetInnerHTML={{ __html: msg }} />
        ))}

      {message &&
        message.map((line, i) => {
          return <SideParagraph key={i}>{line}</SideParagraph>;
        })}
    </FlexColumn>
  );
};

MenuClient = connect(state => {
  return {
    message: state.Data.event.message,
    isOE: state.Data.enrollment.type === 'ANNUAL',
    isNewHire: state.Data.enrollment.type === 'NEW_HIRE',
    isMidYear: state.Data.enrollment.type === 'MID_YEAR',
    noCurrentPlans: state.BenefitsNow.currentPlans.length === 0,
    client: state.Client,
  };
})(MenuClient);

export default MenuClient;
