import React from 'react';
import imgWhiteBullet from '../../assets/img/whiteBullet.svg';
import { Flexbox } from '../shared/Flexbox';
import { COLORS } from '../../constants';
import { Text } from '../shared/Text';

export const Bullet = () => {
  return <img src={imgWhiteBullet} style={{ width: 5, height: 5 }} alt="" />;
};

export const BulletLine = ({
  bulletAlign = 'center',
  bulletOffset = 0,
  ...props
}) => {
  return (
    <Flexbox
      style={{
        alignItems: bulletAlign,
        cursor: 'pointer',
        marginTop: 22,
        width: '100%',
        ...props.style,
        position: 'relative',
      }}
      onClick={props.onClick}
    >
      <div style={{ top: bulletOffset, position: 'relative' }}>
        <Bullet />
      </div>

      <div
        style={{
          marginLeft: 10,
          width: '100%',
        }}
      >
        <Text
          style={{
            fontSize: 16,
            fontWeight: 'bold',
            color: COLORS.DARK_BLUE,
            width: '100%',
          }}
          dangerouslySetInnerHTML={{ __html: props.children }}
        />
      </div>
    </Flexbox>
  );
};
