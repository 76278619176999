import React from 'react';
import { OrangeSubTitle, ItalicHeading, Text } from '../shared/Text';
import { BulletSimple } from '../shared/Bullets';
import styled from 'styled-components';
import { COLORS } from '../../constants';

export const Heading = styled(ItalicHeading)`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
`;

export const SimpleBulletLine = props => (
  <div>
    <LineWrapper>
      <BulletSimple style={{ paddingRight: 12, paddingBottom: 2 }} />
      <div>
        <EndText style={props.style}>{props.children}</EndText>
      </div>
    </LineWrapper>
  </div>
);

export const LineWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

export const EndTitle = styled(OrangeSubTitle)`
  line-height: 19px;
  letter-spacing: inherit;
  font-weight: inherit;
`;

export const EndText = styled(Text)`
  font-size: 11px;
  line-height: 8px;
`;

export const Wrapper = styled.div`
  background-color: ${COLORS.LIGHT_BLUE_BG};
  padding: 24px 30px;
  padding-right: 9px;
  box-sizing: border-box;
`;
export const EndGroup = styled.div`
  box-sizing: border-box;
  padding-bottom: 20px;
  & :last-child {
    padding-bottom: 0px;
  }
`;

export const MoreWrapper = styled.span`
  padding-left: 20px;
  box-sizing: border-box;
  margin-top: 10px;
  font-size: 12px;

  & :last-child {
    padding-bottom: 0;
  }
`;

export const A = styled.a`
  color: ${COLORS.ORANGE};
  text-decoration: none;
  font-weight: bold;
`;

export const More = props => (
  <MoreWrapper>
    <A href={props.href}>Learn more</A>
  </MoreWrapper>
);
