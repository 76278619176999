import { SET_WINDOW_SIZE } from '../actions/Window';

const initialState = {
  x: 0,
  y: 0,
};

export const Window = (state = initialState, action) => {
  switch (action.type) {
    case SET_WINDOW_SIZE:
      return {
        x: action.x,
        y: action.y,
      };

    default:
      return state;
  }
};
