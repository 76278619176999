import React from 'react';
import { connect } from 'react-redux';

export let FullName = connect((state, ownProps) => {
  return {
    pii: state.Data.personalInfo,
    isPriest: state.Data.isPriest,
    isNun: state.Data.isNun,
    isDeacon: state.Data.isDeacon,
    isBishop: state.Data.isBishop,
    isBrother: state.Data.isBrother,
    ...ownProps,
  };
})(({ pii, isPriest, isNun, isDeacon, isBishop, isBrother }) => {
  /**
   * Reference:
   * http://www.traditioninaction.org/religious/d003rpHowToAddressClergy.html
   */
  return (
    <span>
      {isPriest && <span>Reverend </span>}
      {isNun && <span>Sister </span>}
      {isBishop && <span>Most Reverend </span>}
      {isBrother && <span>Brother </span>}
      {isDeacon && <span>Deacon </span>}
      {pii.name.first} {pii.name.last}
    </span>
  );
});

export let LocationName = connect((state, ownProps) => {
  return {
    location: state.Data.location,
    ...ownProps,
  };
})(({ location }) => {
  return <span>{location.name}</span>;
});

export let TrustorName = connect(state => {
  return {
    trustor: state.Data.trustor,
  };
})(({ trustor }) => {
  return <span>{trustor.name}</span>;
});
