import React from 'react';
import styled from 'styled-components/macro';
import imgCheckLightBlue from '../../assets/img/checkLightBlue.svg';

const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  &:first-child {
    padding-top: 10px;
  }

  &:last-child {
    padding-bottom: 10px;
  }

  & .area {
    background-color: #798497;
    border: 1px solid #507279;
    border-radius: 4px;
    color: white;
    line-height: 1.6;
    padding: 5px;
  }

  & .check {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 20px;
    margin-right: 10px;

    & > img {
      display: block;
    }
  }

  & .title {
    flex: 1 1 150px;
    font-size: 12px;
  }
`;

const Item = ({ children }) => {
  return (
    <ItemWrapper>
      <div className="area check">
        <img src={imgCheckLightBlue} alt="" />
      </div>
      <div className="area title">{children}</div>
    </ItemWrapper>
  );
};

const Wrapper = styled.div`
  min-height: 100px;
  display: flex;
  justify-content: center;

  & > div {
    display: flex;
    flex-direction: column;
    width: 250px;
    border-radius: 10px;
    border: 1px solid #507279;

    & .header {
      border-radius: 10px 10px 0 0;
      background-color: #9ab3cd;
      padding: 12px;
      border-bottom: 1px solid #507279;
      flex: 0 0 25px;

      & > .inset {
        background: #798497;
        padding: 8px;
        color: #fdb813;
        text-align: center;
        border: 1px solid #507279;
      }
    }

    & .content {
      overflow-y: auto;
      max-height: 250px;
      background-color: white;
      border-radius: 0 0 10px 10px;
    }
  }
`;
const Calculator = () => {
  return (
    <Wrapper>
      <div>
        <div className="header">
          <div className="inset">Your Benefit Balances</div>
        </div>

        <div className="content">
          <Item>My Medical Plan Deductible Status</Item>
          <Item>My Current HSA Balance</Item>
          <Item>My Dental Plan Deductible Status</Item>
          <Item>My Vision Plan Deductible Status</Item>
          <Item>My Drug Plan Deductible Status</Item>
        </div>
      </div>
    </Wrapper>
  );
};

export default Calculator;
