import { combineReducers } from 'redux';
import { Data } from './Data';
import { Welcome } from './Welcome';
import { AboutPlans } from './AboutPlans';
import { Contacts } from './Contacts';
import { Menu } from './Menu';
import { WhatsNew } from './WhatsNew';
import { RetaEnroll } from './RetaEnroll';
import { Language } from './Language';
import { BenefitsNow } from './BenefitsNow';
import { Options } from './Options';
import { Window } from './Window';
import { Networks } from './Networks';
import { Compare } from './Compare';
import Error from './Error';
import Client from './Client';
import Features from './Features';

export const rootReducer = combineReducers({
  Data,
  Welcome,
  AboutPlans,
  Contacts,
  Menu,
  WhatsNew,
  RetaEnroll,
  Language,
  BenefitsNow,
  Options,
  Window,
  Networks,
  Compare,
  Error,
  Client,
  Features,
});
export { rootReducer as default };
