import React from 'react';
import { COLORS } from '../../constants';
import imgArrowRight from '../../assets/img/menuArrowRight.svg';
import imgArrowDown from '../../assets/img/menuArrowDown.svg';
import { Flexbox } from '../shared/Flexbox';
import { expand, selectSub } from '../../actions/Menu';
import { connect } from 'react-redux';
import { NavItem } from './NavItem';

const ArrowRight = props => {
  return (
    <Flexbox
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        width: 11,
      }}
    >
      <img
        src={props.icon || imgArrowRight}
        style={{
          width: 5,
          height: 5,
        }}
        alt=""
      />
    </Flexbox>
  );
};

const ArrowDown = () => {
  return <ArrowRight icon={imgArrowDown} />;
};

export const SubMenu = props => {
  return (
    <NavItem
      style={{
        color: COLORS.NAVITEM_TEXT_DARK,
        backgroundColor: 'white',
      }}
      activeStyle={
        props.menu.selected && {
          backgroundColor: 'white',
          color: COLORS.ORANGE,
        }
      }
      menu={props.menu}
      onClick={props.onClick}
    >
      {props.children}
    </NavItem>
  );
};

export let Expandable = ({ language, ...props }) => {
  const expanded =
    props.menu.menus.filter(m => m.selected).length > 0 || props.menu.selected;

  return (
    <div>
      <NavItem
        menu={props.menu}
        style={Object.assign(
          {},
          expanded && {
            color: 'white',
            backgroundColor: COLORS.ORANGE,
          },
        )}
        onClick={e => {
          e.stopPropagation(true);
          props.onExpand(props.menu.id);
        }}
        expandable={true}
      >
        <Flexbox>
          {expanded ? <ArrowDown /> : <ArrowRight />}
          {props.children}
        </Flexbox>
      </NavItem>

      {expanded
        ? props.menu.menus.map((menu, i) => {
            return (
              <SubMenu
                menu={menu}
                key={i}
                onClick={() => props.onSelectSub(props.menu.id, menu.id)}
              >
                {menu.name[language]}
              </SubMenu>
            );
          })
        : null}
    </div>
  );
};
Expandable = connect(
  state => {
    return {
      language: state.Language.current.toLowerCase(),
    };
  },
  dispatch => {
    return {
      onExpand: id => dispatch(expand(id)),
      onSelectSub: (parentId, id) => dispatch(selectSub(parentId, id)),
    };
  },
)(Expandable);
