import React from 'react';
import { COLORS } from '../../constants';
import styled from 'styled-components/macro';

export const Text = styled.span`
  color: ${COLORS.TEXT};
  font-size: 16px;
  letter-spacing: 0.5;
  line-height: 22px;
`;

export function TextDark(props) {
  return (
    <Text
      style={{
        color: COLORS.TEXT_DARK,
        fontWeight: 'bold',
        ...props.style,
      }}
    >
      {props.children}
    </Text>
  );
}

export function TextEmphasis(props) {
  return (
    <span
      className={props.className}
      style={{
        color: COLORS.ORANGE,
        fontSize: 18,
        lineHeight: '25px',
        ...props.style,
      }}
    >
      {props.children}
    </span>
  );
}

export function OrangeText(props) {
  return (
    <span
      {...props}
      style={{
        color: COLORS.ORANGE,
        ...props.style,
      }}
    >
      {props.children}
    </span>
  );
}

export function SideTitle(props) {
  return (
    <div
      style={{
        color: COLORS.TEXT,
        fontFamily: 'Roboto Condensed',
        fontWeight: 300,
        fontSize: 26,
        lineHeight: '33px',
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
}

export const OrangeSubTitle = styled.div`
  color: ${COLORS.ORANGE};
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 18px;
  font-weight: bold;
  margin-bottom: 14px;
`;

export const ItalicHeading = styled.div`
  color: ${COLORS.TEXT};
  font-size: 15px;
  line-height: 20px;
  font-style: italic;
  font-weight: bold;
`;
