import React from 'react';
import imgClose from '../../assets/img/close.svg';

export function Close(props) {
  return (
    <div
      style={{
        position: 'absolute',
        top: 8,
        right: 8,
        cursor: 'pointer',
        ...props.style,
      }}
      onClick={props.onClick}
    >
      <img src={imgClose} style={{ width: 10, height: 10 }} alt="" />
    </div>
  );
}
export { Close as default };
