import React from 'react';
import { Paragraph } from '../shared/Layout';
import { TextEmphasis } from '../shared/Text';
import { EnrollDeadlineMsg } from '../shared/Enrollment';
import { connect } from 'react-redux';
import { Padding } from '../welcome/SideContent';
import styled from 'styled-components';
import { media, sizes, COLORS } from '../../constants';
import { onClick } from './Content';

const Mobile = styled.div`
  text-align: center;

  @media (min-width: ${sizes.tablet}px) {
    display: none;
  }
`;

const Title = styled.div`
  color: ${COLORS.TEXT};
  font-family: Roboto Condensed;
  font-weight: 300;
  font-size: 26px;
  line-height: 33px;
  margin-top: 55px;
  margin-bottom: 36px;

  ${media.tablet`margin-top: 0;`}
`;

const ToolTitle = styled.div`
  margin-top: 120px;
  font-family: Roboto;
  font-size: 26px;
  letter-spacing: 0.5px;
  color: white;
  width: 100%;
`;

const ToolButton = styled.button`
  margin-top: 36px;
  margin-bottom: 96px;
  color: white;
  font-family: Roboto;
  font-size: 18px;
  letter-spacing: 0.5px;
  background-color: ${COLORS.ORANGE};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 22px;
  padding-right: 22px;
`;

export let SideContent = ({ isOE, testing }) => {
  return (
    <Padding
      style={{
        width: '100%',
      }}
    >
      <Title>Want Help in Making Your Medical Plan Choices?</Title>

      {isOE && (
        <div>
          <Paragraph>
            We are pleased to announce the <i>Reta Decision Tool</i> designed to
            guide you in selecting the Reta health insurance plan option that
            best suits your personal and financial needs. After you answer four
            basic questions about your expected healthcare use, in less than a
            minute your
            <i>Reta Decision Tool</i> will provide you with a customized
            recommendation of the plan that will best suit your needs.
          </Paragraph>
        </div>
      )}

      {!isOE && (
        <Paragraph>
          When it comes time to enroll, the Reta Decision Tool will help to
          guide you in selecting the Reta health insurance plan option that best
          suits your personal and financial needs. After you answer four basic
          questions about your expected healthcare use, in less than a minute
          your Reta Decision Tool will provide you with a customized
          recommendation of the plan that will best suit your needs.
        </Paragraph>
      )}

      <Mobile>
        <ToolTitle>Your Reta Decision Tool</ToolTitle>
        <ToolButton onClick={() => onClick(testing)}>Click to Start</ToolButton>
      </Mobile>

      {isOE && (
        <Paragraph
          style={{
            marginTop: 20,
          }}
        >
          <TextEmphasis>
            <EnrollDeadlineMsg />
          </TextEmphasis>
        </Paragraph>
      )}
    </Padding>
  );
};

SideContent = connect(state => {
  return {
    isOE: state.Data.enrollment.type === 'ANNUAL',
    testing: state.Data.test,
  };
})(SideContent);
