import React from 'react';
import { connect } from 'react-redux';
import {
  Wrapper,
  EndTitle,
  EndGroup,
  More,
  SimpleBulletLine,
} from './EndContent.style';

const EndContent = ({ options, has, planId, ft, ihcId }) => {
  return (
    <Wrapper>
      <EndTitle>
        If you’re in a {ihcId === 'reta' ? 'Reta Medical' : 'medical'} plan,
        you’re automatically eligible for these benefits.
      </EndTitle>

      {ft.active('showWellness') && has.catapult && (
        <EndGroup>
          <SimpleBulletLine style={{ fontSize: 14 }}>
            VirtualCheckup by <i>Catapult Health</i>
          </SimpleBulletLine>

          <More href="#wellness-catapult" />
        </EndGroup>
      )}

      {ft.active('showWellness') && (options.showWW || has.ww) && (
        <EndGroup>
          <SimpleBulletLine style={{ fontSize: 14 }}>
            <i>WW</i>, or Weight Watchers <i>Reimagined</i>
          </SimpleBulletLine>

          <More href="#wellness-ww" />
        </EndGroup>
      )}

      {(ft.active('showVivante') || has.vivante) && (
        <EndGroup>
          <SimpleBulletLine style={{ fontSize: 14 }}>
            GIThrive by <i>Vivante Health</i>
          </SimpleBulletLine>

          <More href="#vivante" />
        </EndGroup>
      )}

      {(ft.active('showBridgeHealth') || has.bridgeHealth) &&
        !/kaiser/i.test(planId) && (
          <EndGroup>
            <SimpleBulletLine style={{ fontSize: 14 }}>
              Surgical Benefits by <i>BridgeHealth</i>
            </SimpleBulletLine>

            <More href="#bridgeHealth" />
          </EndGroup>
        )}

      {ft.active('showWellness') && (ft.active('showFitbit') || has.fitbit) && (
        <EndGroup>
          <SimpleBulletLine style={{ fontSize: 14 }}>
            Free Fitbit tracker {ihcId === 'reta' && <span>from Reta</span>}
          </SimpleBulletLine>

          <More href="#wellness-fitbit" />
        </EndGroup>
      )}

      {(ft.active('showLivongo') || has.livongo) && !/kaiser/i.test(planId) && (
        <EndGroup>
          <SimpleBulletLine style={{ fontSize: 14 }}>
            Diabetes Management Benefit by Livongo
          </SimpleBulletLine>

          <More href="#livongo" />
        </EndGroup>
      )}
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  options: state.Options,
  has: state.Data.has || {},
  ft: state.Features.features,
  ihcId: state.Data.ihcId,
});

export default connect(mapStateToProps)(EndContent);
