import React from 'react';
import { FlexColumn } from './Flexbox';
import Close from './Close';

export let ModalMenu = props => {
  return (
    <div
      style={{
        height: '100%',
        position: 'relative',
        flexGrow: 1,
      }}
    >
      {/* Modal */}
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          backgroundColor: props.bgColor,
          opacity: props.opacity,
          width: 375,
          minHeight: 480,
          zIndex: 2000,
          ...props.style,
        }}
      >
        <FlexColumn
          style={{
            position: 'relative',
            padding: 14,
            boxSizing: 'border-box',
          }}
        >
          <Close onClick={props.onClose} />

          {props.children}
        </FlexColumn>
      </div>
    </div>
  );
};

ModalMenu.defaultProps = {
  bgColor: 'gray',
  opacity: 0.95,
  onClose: () => console.log('Close Clicked'),
};

export { ModalMenu as default };
