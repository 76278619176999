import { RECEIVED_DATA } from '../actions/Data';
import ft from 'feature-toggle';

const initState = {
  features: ft(),
};

export const Features = (state = initState, action) => {
  switch (action.type) {
    case RECEIVED_DATA: {
      console.debug('Enabled Features:', action.data.features);

      return {
        ...state,
        features: ft(action.data.features),
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default Features;
