import styled, { css } from 'styled-components';

export const COLORS = {
  RED: '#951a2f',
  GRAY_BLUE: '#4781b0',
  DARK_BLUE: '#175a8e',
  ORANGE: '#ffb11e',
  TEXT: '#faf4f5',
  TEXT_DARK: '#49494b',
  ANTHEM_BLUE: '#334b72',
  ANTHEM_MENU_BG: '#4377af',
  CLEARVIEW_BLUE: '#1e6fca',
  CLEARVIEW_RED: '#93212d',
  RX_GRAY: '#7b7b7b',
  NAVITEM_BG: '#e2e2e2',
  NAVITEM_TEXT: '#303030',
  NAVITEM_TEXT_DARK: '#9a9a9a',
  ORANGE_BORDER: '#e85d2f',
  LIGHT_BLUE_BG: '#4f76a0',
};

/**
 * compare borders
 */
export const ORANGE_BORDER_STYLE = `solid 5px ${COLORS.ORANGE_BORDER}`;
export const COMPARE_COL_STYLE = '1px solid #757575';

/**
 * Uniform Drop Shadow
 */
export const shadow = '0 8px 20px 0 rgba(20, 0, 0, .3)';

export const shadowStyle = {
  WebkitBoxShadow: shadow,
  MozBoxShadow: shadow,
  boxShadow: shadow,
};

export const COMPARE_COL_WIDTH = 175;
export const COMPARE_LABEL_WIDTH = 175;

export const sizes = {
  desktop: 1100,
  tablet: 768,
  phone: 376,
};

export const headerHeight = 50;

// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export const Mobile = styled.div`
  @media (min-width: ${sizes.tablet}px) {
    display: none !important;
  }
`;

export const Desktop = styled.div`
  @media (max-width: ${sizes.tablet}px) {
    display: none !important;
  }
`;
