import React from 'react';
import { Flexbox } from '../shared/Flexbox';
import { showMenu } from '../../actions/Welcome';
import { Text, SideTitle } from '../shared/Text';
import imgCongratsArrow from '../../assets/img/congratsArrow.svg';
import { connect } from 'react-redux';

export let CongratsArrow = ({ onToggleMenu }) => {
  return (
    <Flexbox
      style={{
        position: 'relative',
        left: -36,
        alignItems: 'center',
        marginTop: 42,
        marginBottom: 36,
        cursor: 'pointer',
      }}
      onClick={e => onToggleMenu(e, 'congrats')}
    >
      <div
        style={{
          position: 'relative',
        }}
      >
        <img
          src={imgCongratsArrow}
          style={{
            width: 199,
            height: 34,
          }}
          alt=""
        />
        <SideTitle
          style={{
            position: 'absolute',
            top: 2,
            left: 36,
            fontSize: 18,
          }}
        >
          Congratulations!
        </SideTitle>
      </div>

      <div
        style={{
          whiteSpace: 'nowrap',
          marginLeft: 7,
        }}
      >
        <Text>Click to find out why</Text>
      </div>
    </Flexbox>
  );
};

CongratsArrow = connect(null, dispatch => {
  return {
    onToggleMenu: (e, id) => {
      e.stopPropagation(true);
      dispatch(showMenu(id));
    },
  };
})(CongratsArrow);
