import React from 'react';
import imgActive from '../../assets/img/langActive.svg';
import imgInactive from '../../assets/img/langInactive.svg';
import { Flexbox } from './Flexbox';
import { COLORS } from '../../constants';
import { Text } from './Text';
import { selectEnglish, selectSpanish } from '../../actions/Language';
import { connect } from 'react-redux';

let Button = props => {
  return (
    <Flexbox
      {...props}
      style={{
        backgroundImage: `url(${imgInactive})`,
        width: 72,
        height: 22,
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: 'uppercase',
        fontSize: 10,
        fontWeight: 'bold',
        color: 'white',
        letterSpacing: 1,
        cursor: 'pointer',
        ...props.style,
      }}
      onClick={props.onClick}
    >
      {props.children}
    </Flexbox>
  );
};

let Active = props => {
  return (
    <Button
      {...props}
      style={{
        color: COLORS.TEXT_DARK,
        backgroundImage: `url(${imgActive})`,
        ...props.style,
      }}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};

class _Language extends React.Component {
  state = {
    hovering: false,
  };

  render() {
    return (
      <Flexbox
        style={{
          alignItems: 'center',
        }}
      >
        {this.props.language === 'ENGLISH' && (
          <Flexbox>
            <Active onClick={this.props.onSelectEnglish}>English</Active>

            <Button
              style={{
                marginLeft: 18,
              }}
              onClick={this.props.onSelectSpanish}
              onMouseEnter={() => this.setState({ hovering: true })}
              onMouseLeave={() => this.setState({ hovering: false })}
            >
              Espa&ntilde;ol
            </Button>
          </Flexbox>
        )}

        {this.props.language === 'SPANISH' && (
          <Flexbox>
            <Button onClick={this.props.onSelectEnglish}>English</Button>

            <Active
              style={{
                marginLeft: 18,
              }}
              onClick={this.props.onSelectSpanish}
              onMouseEnter={() => this.setState({ hovering: true })}
              onMouseLeave={() => this.setState({ hovering: false })}
            >
              Espa&ntilde;ol
            </Active>
          </Flexbox>
        )}

        {this.state.hovering && (
          <div
            style={{
              marginLeft: 8,
            }}
          >
            <Text
              style={{
                fontSize: 11,
              }}
            >
              where available
            </Text>
          </div>
        )}
      </Flexbox>
    );
  }
}

export const Language = connect(
  state => {
    return {
      language: state.Language.current,
    };
  },
  dispatch => {
    return {
      onSelectEnglish: () => dispatch(selectEnglish()),
      onSelectSpanish: () => dispatch(selectSpanish()),
    };
  },
)(_Language);
