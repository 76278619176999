import React from 'react';
import { COLORS } from '../../constants';
import { HeaderRow } from './HeaderRow';
import { Row } from './Row';
import { FlexColumn } from '../shared/Flexbox';
import { Logo } from './Logo';
import { CellText } from './CellText';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';

const WellnessLogo = styled.div`
  padding-bottom: 10px;

  &:last-child {
    padding-bottom: 0;
  }
`;

const PlanRow = ({ type, has, ...props }) => {
  return (
    <div>
      <Row
        id={props.id}
        type={type}
        className="header"
        cellStyle={{
          paddingBottom: 0,
        }}
        benefitPlans={
          <div
            style={{
              fontSize: 12,
              fontWeight: 'bold',
              color: COLORS.TEXT_DARK,
              marginBottom: /wellness/i.test(props.description) ? 0 : 10,
            }}
          >
            {props.description}
          </div>
        }
        joinedContent={type === 'wellness' && <div></div>}
      />

      <Row
        id={props.id}
        cellStyle={{
          paddingTop: 0,
        }}
        type={type}
        className="content"
        benefitPlans={
          <FlexColumn>
            {type === 'wellness' && (has.kaiser || has.blueShieldCA) && (
              <>
                {has.kaiser && (
                  <WellnessLogo>
                    <img
                      style={{ paddingTop: 10 }}
                      src="https://firebasestorage.googleapis.com/v0/b/forty8-ihc-e908.appspot.com/o/carriers%2Fkaiser%2Fimages%2FlogoSmall.1521414727578.svg?alt=media&token=f11775d6-30b0-49ab-b333-042408b84acf"
                      alt="kaiser"
                    />
                  </WellnessLogo>
                )}

                {has.blueShieldCA && (
                  <WellnessLogo>
                    <img
                      style={{ paddingTop: 10 }}
                      src="https://firebasestorage.googleapis.com/v0/b/forty8-ihc-e908.appspot.com/o/carriers%2FblueShieldCA%2Fimages%2FlogoSmall.1618874709837.png?alt=media&token=daa11fe1-7741-4e2d-9955-4ecd38bd0ccb"
                      alt="blueShieldCA"
                    />
                  </WellnessLogo>
                )}
              </>
            )}

            {(type !== 'wellness' ||
              (type === 'wellness' && !has.kaiser && !has.blueShieldCA)) &&
              props.logos.map(
                (logo, i) =>
                  logo && (
                    <Logo
                      key={i}
                      src={logo.src}
                      style={Object.assign(
                        {},
                        i >= props.logos.length - 1 && { marginBottom: 0 },
                      )}
                      onClick={() => {
                        if (logo.link) {
                          window.open(logo.link);
                        }

                        if (logo.hash) {
                          let hash = logo.hash;
                          if (props.type === 'medical') {
                            hash = 'medical-' + hash;
                          }
                          if (props.type === 'wellness') {
                            hash = 'wellness-' + hash;
                          }
                          window.location.hash = hash;
                        }
                      }}
                    />
                  ),
              )}
          </FlexColumn>
        }
        haveNow={
          <FlexColumn>
            {props.current.map((cur, i) => (
              <CellText key={i}>{cur.description}</CellText>
            ))}

            {props.current.length === 0 && <CellText>Not enrolled</CellText>}
          </FlexColumn>
        }
        coverage={
          <FlexColumn>
            {props.current.map((cur, i) => {
              if (/Waive|Not enrolled/i.test(cur.tier)) {
                return <CellText key={i}>No coverage</CellText>;
              }

              return <CellText key={i}>{cur.tier}</CellText>;
            })}

            {props.current.length === 0 && <CellText>No coverage</CellText>}
          </FlexColumn>
        }
        noChange={
          <FlexColumn>
            {props.current.map((cur, i) => {
              return (
                <CellText key={i}>
                  {(() => {
                    if (cur.planType === 'FSA') {
                      return 'No FSA – remember to re-enroll!';
                    }
                    switch (cur.noChange) {
                      case 'SAME_COVERAGE':
                        return 'Same coverage';
                      case 'SAME_BENEFITS':
                        return 'Same coverage';
                      case 'NO_COVERAGE':
                        return 'No coverage';
                      case 'EMPLOYEE_ONLY_COVERAGE':
                        return 'Employee only coverage';
                      case 'NEW_PLAN':
                        return 'Enrolled in new plan';
                      case 'DISCONTINUED_SIMILAR':
                        return 'Enrolled in similar plan';
                      case 'DISCONTINUED':
                        return 'Discontinued';
                      case 'POSITIVE_ENROLLMENT':
                        return 'Enrolled in different plan';
                      default:
                        return cur.noChange;
                    }
                  })()}
                </CellText>
              );
            })}

            {/* If there are no current plans, the default will be 'no
            coverage' */}
            {props.current.length === 0 && <CellText>No coverage</CellText>}
          </FlexColumn>
        }
        joinedContent={
          type === 'wellness' && (
            <div>
              {has.kaiser && has.blueShieldCA && (
                <span>
                  If you enroll in a Reta Medical plan you are automatically
                  enrolled in the integrated wellness plan offered by that
                  carrier at no cost to you.
                </span>
              )}

              {!has.kaiser && has.blueShieldCA && (
                <span>
                  If you enroll in the BSC medical plan, you are automatically
                  enrolled in the Wellvolution wellness program at no cost to
                  you.
                </span>
              )}

              {has.kaiser && !has.blueShieldCA && (
                <span>
                  If you enroll in the Kaiser medical plan, you are
                  automatically enrolled in the integrated Kaiser wellness
                  program at no cost to you.
                </span>
              )}
            </div>
          )
        }
      />
    </div>
  );
};

export let BenefitsNowContent = ({ Plans, has }) => {
  return (
    <FlexColumn
      style={{
        padding: 36,
        paddingLeft: 0,
        boxSizing: 'border-box',
        flexGrow: 1,
        flexShrink: 0,
      }}
    >
      <HeaderRow />

      {Plans.map((plan, i) => {
        return (
          <PlanRow
            key={i}
            id={i}
            has={has}
            description={plan.description}
            current={plan.current}
            available={plan.available}
            logos={plan.logos}
            type={plan.type}
          />
        );
      })}
    </FlexColumn>
  );
};

const mapStateToProps = state => {
  return {
    Plans: state.BenefitsNow.Plans,
    has: state.Data.has,
  };
};

BenefitsNowContent = connect(mapStateToProps)(BenefitsNowContent);
