import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { COLORS, media } from '../../constants';
import imgSbcIcon from '../../assets/img/sbcIcon.png';
import imgSpdIcon from '../../assets/img/spdIcon.png';
import imgRedBullet from '../../assets/img/redBullet.svg';
import imgInfo from '../../assets/img/info.svg';

const COLUMN_WIDTH = 150;

const Padding = styled.div`
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* width: 100%; */
  box-sizing: border-box;
  padding: 36px;
  /* padding-top: 0; */
  position: relative;
  max-width: 925px;
  margin-left: auto;
  margin-right: auto;

  ${media.tablet`
     padding: 0;
  `}
`;

const GridCanvas = styled.div`
  padding-left: ${COLUMN_WIDTH}px;
  box-sizing: border-box;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  ${media.desktop`
    max-width: none;
  `}
`;

const Scroll = styled.div`
  /* width: 100%; */
  /* height: 100%; */
  overflow: auto;
`;

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
`;

const HeadCell = styled.th`
  width: ${COLUMN_WIDTH}px;
  min-width: ${COLUMN_WIDTH}px;
  height: 47px;
  background-color: ${COLORS.ORANGE};
  color: ${COLORS.RED};
  font-family: Roboto Condensed;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  vertical-align: middle;
  border: 1px solid #ffc96b;
  z-index: 100;
  padding-left: 18px;
  padding-right: 18px;
  box-sizing: border-box;

  &:nth-child(2) {
    margin-left: ${COLUMN_WIDTH - 1}px;
  }
`;

const DEFAULT_BORDER = `3px solid ${COLORS.ORANGE_BORDER} !important`;
const PlanName = styled(HeadCell)`
  height: 52px;
  max-height: 52px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-left: ${props =>
    props.default ? DEFAULT_BORDER : '1px solid #FFC96B'};
  border-right: ${props =>
    props.default ? DEFAULT_BORDER : '1px solid #FFC96B'};
  border-top: ${props =>
    props.default ? DEFAULT_BORDER : '1px solid #FFC96B'};
`;

const FixedCol = styled(HeadCell)`
  position: absolute;
  top: auto;
  left: 0;
  width: ${COLUMN_WIDTH}px;
`;

const NetworkCell = styled(HeadCell)`
  width: ${COLUMN_WIDTH / 2}px;
  min-width: ${COLUMN_WIDTH / 2}px;
  text-transform: none;
  padding: 0;
  border-left: ${props =>
    props.defaultLeft ? DEFAULT_BORDER : '1px solid #FFC96B'};
  border-right: ${props =>
    props.defaultRight ? DEFAULT_BORDER : '1px solid #FFC96B'};
`;

const CellWrapper = styled.td`
  width: ${COLUMN_WIDTH / 2}px;
  min-width: ${COLUMN_WIDTH / 2}px;
  height: 32px;
  color: black;
  font-family: Roboto Condensed;
  font-size: 12px;
  line-height: 16px;
  vertical-align: bottom;
  box-sizing: border-box;
  text-align: center;
  background-color: ${props => (props.gray ? '#DFDFE1' : 'white')};
  border-left: ${props =>
    props.defaultLeft ? DEFAULT_BORDER : '1px solid #979797'};
  border-right: ${props =>
    props.defaultRight ? DEFAULT_BORDER : '1px solid #979797'};
  border-bottom: ${props => (props.defaultBottom ? DEFAULT_BORDER : 'inherit')};

  &:nth-child(even) {
    border-right: 1px solid #cdcbcb;
  }

  &:nth-child(2) {
    border-left: none;
  }

  &:last-child {
    border-right: 1px solid #cdcbcb;
  }
`;

const Full = styled.div`
  width: 100%;
  height: 100%;
`;

const RowHeaderWrapper = styled(CellWrapper)`
  position: absolute;
  top: auto;
  left: 0;
  width: ${COLUMN_WIDTH}px;
  font-weight: bold;
  text-align: left;
`;

const RowCategoryWrapper = styled(RowHeaderWrapper)`
  text-align: right;
  font-weight: normal;
`;

const Flex = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 6px;
  padding-right: 6px;
  box-sizing: border-box;
`;

const FlexRight = styled(Flex)`
  justify-content: flex-end;
`;

const FlexCenter = styled(Flex)`
  justify-content: center;
`;

const Cell = props => {
  return (
    <CellWrapper {...props}>
      <Full>
        {/* The 'width' hacks are for nested 'flex' elements in IE11 ignoring
        'max-width' */}
        <FlexCenter
          style={Object.assign(
            {
              width: COLUMN_WIDTH / 2,
            },
            props.style && props.style.width && { width: props.style.width },
            props.style &&
              props.style.maxWidth && { width: props.style.maxWidth },
          )}
        >
          {props.children}
        </FlexCenter>
      </Full>
    </CellWrapper>
  );
};

const JoinedCellWrapper = styled(Cell)`
  &:nth-child(even) {
    border-right: inherit;
  }

  &:last-child {
    border-right: 1px solid #cdcbcb;
  }
`;

const JoinedCell = props => {
  return (
    <JoinedCellWrapper
      {...props}
      colSpan="2"
      style={{
        width: COLUMN_WIDTH,
        maxWidth: COLUMN_WIDTH,
        ...props.style,
      }}
    >
      {props.children}
    </JoinedCellWrapper>
  );
};

const RowHeader = React.forwardRef((props, ref) => {
  return (
    <RowHeaderWrapper {...props} ref={ref}>
      <Full>
        <Flex>{props.children}</Flex>
      </Full>
    </RowHeaderWrapper>
  );
});

const RowCategory = props => {
  return (
    <RowCategoryWrapper {...props}>
      <Full>
        <FlexRight>{props.children}</FlexRight>
      </Full>
    </RowCategoryWrapper>
  );
};

const Footer = styled.div`
  margin-left: ${-COLUMN_WIDTH}px;

  ${media.tablet`
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 36px;
    box-sizing: border-box;
  `}
`;

const FooterText = styled.div`
  font-size: 10px;
  color: white;
  background-color: none;
  padding-top: 18px;
  box-sizing: border-box;
`;

const FooterItalic = styled(FooterText)`
  font-style: italic;
`;

const TopLeft = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
`;

class _Grid extends React.Component {
  state = {
    totalWidth: null,
    tableWidth: null,
  };

  constructor(props) {
    super(props);
    this.refFixed = React.createRef();
    this.refTable = React.createRef();
  }

  componentDidMount() {
    console.log('--', this.refFixed);
    const totalWidth =
      this.refFixed.current.getBoundingClientRect().width +
      this.refTable.current.getBoundingClientRect().width;

    this.setState({ totalWidth });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.numPlans >= 5) {
      return null;
    }

    if (nextProps.windowX <= prevState.totalWidth) {
      return {
        tableWidth: '100%',
      };
    }

    return {
      tableWidth: prevState.totalWidth + 'px',
    };
  }

  render() {
    return (
      <Padding>
        <GridCanvas
          style={Object.assign(
            {},
            this.state.tableWidth && { width: this.state.tableWidth },
          )}
        >
          <Scroll>
            <Table ref={this.refTable}>
              <thead>
                <tr>
                  <FixedCol
                    style={{
                      height: 52,
                      maxHeight: 52,
                      borderBottom: 'none',
                    }}
                  >
                    &nbsp;
                  </FixedCol>
                  {this.props.Plans.map((p, i) => (
                    <PlanName key={i} colSpan="2" default={p.default}>
                      {p.description}
                    </PlanName>
                  ))}
                </tr>
                <tr>
                  <FixedCol
                    style={{
                      textAlign: 'left',
                      borderTop: 'none',
                    }}
                  >
                    <Flex>Plan Details</Flex>
                  </FixedCol>

                  {this.props.Plans.map((p, i) => [
                    <NetworkCell key={i + 'i'} defaultLeft={p.default}>
                      In-Network
                    </NetworkCell>,
                    <NetworkCell key={i + 'o'} defaultRight={p.default}>
                      Out-of-Network
                    </NetworkCell>,
                  ])}
                </tr>
              </thead>

              <tbody>
                {/* Annual Deductible */}
                <tr>
                  <RowHeader ref={this.refFixed}>Annual Deductible</RowHeader>
                  {this.props.Plans.map((p, i) => [
                    <Cell key={i + 'i'} defaultLeft={p.default}>
                      &nbsp;
                    </Cell>,
                    <Cell key={i + 'o'} defaultRight={p.default}>
                      &nbsp;
                    </Cell>,
                  ])}
                </tr>
                <tr>
                  <RowCategory>Individual</RowCategory>

                  {this.props.Plans.map((p, i) => [
                    <Cell key={i + 'i'} defaultLeft={p.default}>
                      {p.deductible.in.ind}
                    </Cell>,
                    <Cell key={i + 'o'} defaultRight={p.default}>
                      {p.deductible.out.ind}
                    </Cell>,
                  ])}
                </tr>
                <tr>
                  <RowCategory>Family</RowCategory>

                  {this.props.Plans.map((p, i) => [
                    <Cell key={i + 'i'} defaultLeft={p.default}>
                      {p.deductible.in.fam}
                    </Cell>,
                    <Cell key={i + 'o'} defaultRight={p.default}>
                      {p.deductible.out.fam}
                    </Cell>,
                  ])}
                </tr>

                {/* Annual out-of-pocket maximum */}
                <tr>
                  <RowHeader
                    gray
                    style={{
                      height: 45,
                    }}
                  >
                    Annual out-of-pocket maximum
                    <TopLeft>
                      <Rollover text="Includes deductibles, copays and coinsurance.">
                        <img src={imgInfo} alt="info" />
                      </Rollover>
                    </TopLeft>
                  </RowHeader>
                  {this.props.Plans.map((p, i) => [
                    <Cell
                      key={i + 'i'}
                      gray
                      defaultLeft={p.default}
                      style={{
                        height: 45,
                      }}
                    >
                      &nbsp;
                    </Cell>,
                    <Cell
                      key={i + 'o'}
                      gray
                      defaultRight={p.default}
                      style={{
                        height: 45,
                      }}
                    >
                      &nbsp;
                    </Cell>,
                  ])}
                </tr>
                <tr>
                  <RowCategory gray>Individual</RowCategory>

                  {this.props.Plans.map((p, i) => [
                    <Cell key={i + 'i'} gray defaultLeft={p.default}>
                      {p.oopMax.in.ind}
                    </Cell>,
                    <Cell key={i + 'o'} gray defaultRight={p.default}>
                      {p.oopMax.out.ind}
                    </Cell>,
                  ])}
                </tr>
                <tr>
                  <RowCategory gray>Family</RowCategory>

                  {this.props.Plans.map((p, i) => [
                    <Cell key={i + 'i'} gray defaultLeft={p.default}>
                      {p.oopMax.in.fam}
                    </Cell>,
                    <Cell key={i + 'o'} gray defaultRight={p.default}>
                      {p.oopMax.out.fam}
                    </Cell>,
                  ])}
                </tr>

                {/* Doctor Copay */}
                <tr>
                  <RowHeader>Doctor visits</RowHeader>
                  {this.props.Plans.map((p, i) => [
                    <Cell key={i + 'i'} defaultLeft={p.default}>
                      &nbsp;
                    </Cell>,
                    <Cell key={i + 'o'} defaultRight={p.default}>
                      &nbsp;
                    </Cell>,
                  ])}
                </tr>
                <tr>
                  <RowCategory>Primary Care</RowCategory>

                  {this.props.Plans.map((p, i) => [
                    <Cell key={i + 'i'} defaultLeft={p.default}>
                      {p.doctor.in.primary}
                    </Cell>,
                    <Cell key={i + 'o'} defaultRight={p.default}>
                      {p.doctor.out.primary}
                    </Cell>,
                  ])}
                </tr>
                <tr>
                  <RowCategory>Specialist</RowCategory>

                  {this.props.Plans.map((p, i) => [
                    <Cell key={i + 'i'} defaultLeft={p.default}>
                      {p.doctor.in.special}
                    </Cell>,
                    <Cell key={i + 'o'} defaultRight={p.default}>
                      {p.doctor.out.special}
                    </Cell>,
                  ])}
                </tr>

                {/* Hospital - Outpatient */}
                {/* <tr>
                <RowHeader gray>Hospital - Outpatient</RowHeader>
                { this.props.Plans.map((p, i) => [
                  <Cell key={ i + 'i' } gray defaultLeft={ p.default }>
                    &nbsp;
                  </Cell>,
                  <Cell key={ i + 'o' } gray defaultRight={ p.default }>
                    &nbsp;
                  </Cell>
                ] ) }
              </tr>
              <tr>
                <RowCategory gray>Outpatient surgery</RowCategory>

                { this.props.Plans.map((p, i) => [
                  <Cell key={ i + 'i' } gray defaultLeft={ p.default }>
                    { p.hospitalOut.in.surgery }
                  </Cell>,
                  <Cell key={ i + 'o' } gray defaultRight={ p.default }>
                    { p.hospitalOut.out.surgery }
                  </Cell>
                ] ) }
              </tr>
              <tr>
                <RowCategory gray>X-rays, lab tests</RowCategory>

                { this.props.Plans.map((p, i) => [
                  <Cell key={ i + 'i' } gray defaultLeft={ p.default }>
                    { p.hospitalOut.in.xrayLabs }
                  </Cell>,
                  <Cell key={ i + 'o' } gray defaultRight={ p.default }>
                    { p.hospitalOut.out.xrayLabs }
                  </Cell>
                ] ) }
              </tr> */}

                {/* Hospital - Inpatient */}
                <tr>
                  <RowHeader gray>
                    Hospital - Inpatient
                    <TopLeft>
                      <Rollover text="Room and board, surgery, anesthesia, lab tests, medications">
                        <img src={imgInfo} alt="info" />
                      </Rollover>
                    </TopLeft>
                  </RowHeader>
                  {this.props.Plans.map((p, i) => [
                    <Cell key={i + 'i'} gray defaultLeft={p.default}>
                      &nbsp;
                    </Cell>,
                    <Cell key={i + 'o'} gray defaultRight={p.default}>
                      &nbsp;
                    </Cell>,
                  ])}
                </tr>
                <tr>
                  <RowCategory gray>&nbsp;</RowCategory>

                  {this.props.Plans.map((p, i) => [
                    <Cell key={i + 'i'} gray defaultLeft={p.default}>
                      {p.hospitalIn.in}
                    </Cell>,
                    <Cell key={i + 'o'} gray defaultRight={p.default}>
                      {p.hospitalIn.out}
                    </Cell>,
                  ])}
                </tr>

                {/* Emergency Room */}
                <tr>
                  <RowHeader>Emergency Room</RowHeader>

                  {this.props.Plans.map((p, i) => [
                    <Cell key={i + 'i'} defaultLeft={p.default}>
                      {p.er.in}
                    </Cell>,
                    <Cell key={i + 'o'} defaultRight={p.default}>
                      {p.er.out}
                    </Cell>,
                  ])}
                </tr>

                {/* Urgent Care */}
                {/* <tr>
                <RowHeader>Urgent Care</RowHeader>

                { this.props.Plans.map((p, i) => [
                  <Cell key={ i + 'i' } defaultLeft={ p.default }>
                    { p.urgentCare.in }
                  </Cell>,
                  <Cell key={ i + 'o' } defaultRight={ p.default }>
                    { p.urgentCare.out }
                  </Cell>
                ] ) }
              </tr> */}

                {/* Prescriptions - Retail Pharmacy */}
                <tr>
                  <RowHeader
                    gray
                    style={{
                      height: 45,
                    }}
                  >
                    Prescriptions - Retail Pharmacy
                    <TopLeft>
                      <Rollover text="Up to 90-day supply">
                        <img src={imgInfo} alt="info" />
                      </Rollover>
                    </TopLeft>
                  </RowHeader>
                  {this.props.Plans.map((p, i) => [
                    <Cell
                      gray
                      key={i + 'i'}
                      defaultLeft={p.default}
                      style={{
                        height: 45,
                      }}
                    >
                      &nbsp;
                    </Cell>,
                    <Cell
                      gray
                      key={i + 'o'}
                      defaultRight={p.default}
                      style={{
                        height: 45,
                      }}
                    >
                      &nbsp;
                    </Cell>,
                  ])}
                </tr>
                <tr>
                  <RowCategory gray>Generic</RowCategory>

                  {this.props.Plans.map((p, i) => [
                    <Cell gray key={i + 'i'} defaultLeft={p.default}>
                      {p.rxPharm.in.generic}
                    </Cell>,
                    <Cell gray key={i + 'o'} defaultRight={p.default}>
                      {p.rxPharm.out.generic}
                    </Cell>,
                  ])}
                </tr>
                <tr>
                  <RowCategory gray>Brand</RowCategory>

                  {this.props.Plans.map((p, i) => [
                    <Cell gray key={i + 'i'} defaultLeft={p.default}>
                      {p.rxPharm.in.brand}
                    </Cell>,
                    <Cell gray key={i + 'o'} defaultRight={p.default}>
                      {p.rxPharm.out.brand}
                    </Cell>,
                  ])}
                </tr>

                {/* Prescriptions - Mail Order */}
                <tr>
                  <RowHeader
                    style={{
                      height: 45,
                    }}
                  >
                    Prescriptions - Mail
                    <br />
                    Order
                    <TopLeft>
                      <Rollover text="Up to 90-day supply">
                        <img src={imgInfo} alt="info" />
                      </Rollover>
                    </TopLeft>
                  </RowHeader>
                  {this.props.Plans.map((p, i) => [
                    <Cell
                      key={i + 'i'}
                      defaultLeft={p.default}
                      style={{
                        height: 45,
                      }}
                    >
                      &nbsp;
                    </Cell>,
                    <Cell
                      key={i + 'o'}
                      defaultRight={p.default}
                      style={{
                        height: 45,
                      }}
                    >
                      &nbsp;
                    </Cell>,
                  ])}
                </tr>
                <tr>
                  <RowCategory>Generic</RowCategory>

                  {this.props.Plans.map((p, i) => [
                    <Cell key={i + 'i'} defaultLeft={p.default}>
                      {p.rxMail.in.generic}
                    </Cell>,
                    <Cell key={i + 'o'} defaultRight={p.default}>
                      {p.rxMail.out.generic}
                    </Cell>,
                  ])}
                </tr>
                <tr>
                  <RowCategory>Brand</RowCategory>

                  {this.props.Plans.map((p, i) => [
                    <Cell key={i + 'i'} defaultLeft={p.default}>
                      {p.rxMail.in.brand}
                    </Cell>,
                    <Cell key={i + 'o'} defaultRight={p.default}>
                      {p.rxMail.out.brand}
                    </Cell>,
                  ])}
                </tr>

                {/* Your monthly cost */}
                <tr>
                  <RowHeader gray>Your monthly cost</RowHeader>
                  {this.props.Plans.map((p, i) => [
                    <JoinedCell
                      gray
                      key={i + 'i'}
                      defaultLeft={p.default}
                      defaultRight={p.default}
                    >
                      &nbsp;
                    </JoinedCell>,
                  ])}
                </tr>

                {this.props.costTierNames.map((t, id) => {
                  return (
                    <tr key={id}>
                      <RowCategory gray>{t}</RowCategory>

                      {this.props.Plans.map((p, i) => [
                        <JoinedCell
                          gray
                          key={i + 'i'}
                          defaultLeft={p.default}
                          defaultRight={p.default}
                        >
                          {p.costTiers[t]}
                        </JoinedCell>,
                      ])}
                    </tr>
                  );
                })}

                {/* Detailed Plan Summaries */}
                <tr>
                  <RowHeader>Detailed Plan Summaries:</RowHeader>
                  {this.props.Plans.map((p, i) => [
                    <JoinedCell
                      key={i}
                      defaultLeft={p.default}
                      defaultRight={p.default}
                    >
                      &nbsp;
                    </JoinedCell>,
                  ])}
                </tr>

                {/* SBC */}
                <tr>
                  <RowCategory style={{ height: 50 }}>
                    <RedBullet rollover="A general summary of plan benefits and coverage">
                      Summary of Benefits and Coverage (SBC)
                    </RedBullet>
                  </RowCategory>

                  {this.props.Plans.map((p, i) => {
                    if (p.sbc) {
                      return (
                        <JoinedCell
                          key={i}
                          style={{
                            maxWidth: COLUMN_WIDTH,
                            height: 50,
                          }}
                          defaultLeft={p.default}
                          defaultRight={p.default}
                        >
                          <img
                            src={imgSbcIcon}
                            onClick={() => {
                              window.open(p.sbc.url);
                            }}
                            style={{
                              cursor: 'pointer',
                            }}
                            alt=""
                          />
                        </JoinedCell>
                      );
                    } else {
                      return (
                        <JoinedCell
                          key={i}
                          style={{
                            maxWidth: COLUMN_WIDTH,
                            height: 50,
                          }}
                          defaultLeft={p.default}
                          defaultRight={p.default}
                        >
                          Sorry, the SBC is not currently available.
                        </JoinedCell>
                      );
                    }
                  })}
                </tr>

                {/* SPD */}
                <tr>
                  <RowCategory
                    style={{
                      height: 50,
                      borderBottom: '1px solid white',
                    }}
                  >
                    <RedBullet rollover="A detailed legal document describing benefit plans">
                      Summary Plan Description (SPD)
                    </RedBullet>
                  </RowCategory>

                  {this.props.Plans.map((p, i) => {
                    if (p.spd) {
                      return (
                        <JoinedCell
                          key={i}
                          style={{
                            maxWidth: COLUMN_WIDTH,
                            height: 50,
                            borderBottom: '1px solid white',
                          }}
                          colSpan="2"
                          defaultLeft={p.default}
                          defaultRight={p.default}
                          defaultBottom={p.default}
                        >
                          <img
                            src={imgSpdIcon}
                            onClick={() => {
                              window.open(p.spd.url);
                            }}
                            style={{
                              cursor: 'pointer',
                            }}
                            alt=""
                          />
                        </JoinedCell>
                      );
                    } else {
                      return (
                        <JoinedCell
                          key={i}
                          style={{
                            maxWidth: COLUMN_WIDTH,
                            height: 50,
                            borderBottom: '1px solid white',
                          }}
                          colSpan="2"
                          defaultLeft={p.default}
                          defaultRight={p.default}
                          defaultBottom={p.default}
                        >
                          The SPD will be available after enrollment ends.
                        </JoinedCell>
                      );
                    }
                  })}
                </tr>
              </tbody>
            </Table>
          </Scroll>

          <Footer>
            {this.props.restrictedRx && (
              <FooterItalic>
                * Prescriptions are available through the plan&apos;s pharmacy
                benefit instead of EnvisionRx.
              </FooterItalic>
            )}

            <FooterText>
              NOTE: This is a summary of your benefits. For detailed
              information, please contact your carrier or refer to your Summary
              Plan Description (SPD).
            </FooterText>
          </Footer>
        </GridCanvas>
      </Padding>
    );
  }
}

export const Grid = connect(state => {
  return {
    Plans: state.Compare.Plans,
    costTierNames: state.Compare.costTierNames,
    restrictedRx: state.Compare.restrictedRx,
    numPlans: state.Compare.Plans.length,
    windowX: state.Window.x,
  };
})(_Grid);

const Flexbox = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  cursor: help;
`;

const Popup = styled.div`
  color: white;
  background-color: black;
  position: fixed;
  max-width: 200px;
  border-radius: 4px;
  padding: 6px;
  z-index: 2000;
`;

class Rollover extends React.Component {
  state = {
    hovering: false,
    x: 0,
    y: 0,
  };

  render() {
    return (
      <div
        style={{ cursor: 'help' }}
        onMouseEnter={() => this.setState({ hovering: true })}
        onMouseLeave={() => this.setState({ hovering: false })}
        onMouseMove={e => this.setState({ x: e.clientX, y: e.clientY })}
      >
        {this.props.children}

        {this.state.hovering && (
          <Popup
            style={{
              top: this.state.y + 5,
              left: this.state.x + 10,
            }}
          >
            {this.props.text}
          </Popup>
        )}
      </div>
    );
  }
}
const RedBullet = props => {
  return (
    <Flexbox
      style={{
        width: '100%',
        alignItems: 'baseline',
        cursor: 'help',
        textAlign: 'left',
      }}
    >
      <div
        style={{
          width: 'auto',
          height: 'auto',
          paddingRight: 6,
        }}
      >
        <img src={imgRedBullet} alt="" />
      </div>

      <Rollover text={props.rollover}>{props.children}</Rollover>
    </Flexbox>
  );
};
