export const EXPAND_ENROLL_MENU = 'EXPAND_ENROLL_MENU';
export const expand = () => {
  return {
    type: EXPAND_ENROLL_MENU,
  };
};

export const CLOSE_ENROLL_MENU = 'CLOSE_ENROLL_MENU';
export const close = () => {
  return {
    type: CLOSE_ENROLL_MENU,
  };
};
