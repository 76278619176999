import {
  SELECT_NAV_MENU,
  SELECT_NAV_SUB_MENU,
  EXPAND_NAV_MENU,
  SHOW_MOBILE_MENU,
} from '../actions/Menu';
import { RECEIVED_DATA, SET_OPTIONS } from '../actions/Data';
import { isVisible } from './WhatsNew';

const initialState = {
  menus: [],
  showMobileMenu: false,
  // showBridgeHealth: true,
  // showVivante: true,
  // showWW: false,
  // showFitbit: false,
  // showWebMD: false,
};

export const Menu = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPTIONS:
      return {
        ...state,
        ...action.options,
      };

    case RECEIVED_DATA: {
      let menus = [];
      const { ihcId } = action.data;

      /**
       * What's New if there are any changes from the trustor
       */
      if (
        !action.data.features.includes('hideWhatsNew') &&
        isVisible(action.data)
      ) {
        menus.push({
          name: {
            english: "What's New This Year",
            spanish: 'Lo que hay de nuevo este año',
          },
          id: 'new',
          selected: false,
        });
      }

      /**
       * Build menu for medical plans
       */
      if (action.data.plans.available.medical.length > 1) {
        let filtered = action.data.plans.available.medical.map(p => {
          return {
            name: {
              english: p.networkFullName,
              spanish: p.networkFullName,
            },
            id: `medical-${p.network.toLowerCase()}`,
            selected: false,
          };
        });

        let carriers = filtered.reduce(
          (p, c) => {
            let found = p.find(plan => plan.name.english === c.name.english);

            if (!found) {
              return p.concat([c]);
            }

            return p;
          },
          [filtered[0]],
        );

        menus.push(
          {
            name: {
              english: 'Benefits You Have Now',
              spanish: 'Beneficios que tiene ahora',
            },
            id: 'benefitsNow',
            selected: false,
          },
          {
            name: {
              english: 'About Your Medical Plans',
              spanish: 'Acerca de sus planes médicos',
            },
            id: 'about',
            selected: false,
          },
          {
            id: 'medical',
            name: {
              english: 'Your Medical Plan Options',
              spanish: 'Sus opciones de planes médicos',
            },
            selected: false,
            menus: carriers,
          },
          {
            name: {
              english: 'Compare Your Plan Options',
              spanish: 'Compare sus opciones de planes médicos',
            },
            id: 'compareBenefits',
            selected: false,
            style: {
              paddingRight: 0,
            },
          },
        );
      }

      /**
       * Single Medical Plan
       */
      if (action.data.plans.available.medical.length === 1) {
        const plan = action.data.plans.available.medical[0];
        menus.push(
          {
            name: {
              english: 'Benefit You Have Now',
              spanish: 'Beneficio que tiene ahora',
            },
            id: 'benefitsNow',
            selected: false,
          },
          {
            name: {
              english: 'About Your Medical Plans',
              spanish: 'Acerca de su plan médicos',
            },
            id: 'about',
            selected: false,
          },
          {
            name: {
              english: plan.networkFullName,
              spanish: plan.networkFullName,
            },
            id: `medical-${plan.network.toLowerCase()}`,
            selected: false,
          },
          {
            name: {
              english: 'Medical Plan Highlights',
              spanish: 'Puntos destacados del plan médico',
            },
            id: 'compareBenefits',
            selected: false,
            style: {
              paddingRight: 0,
            },
          },
        );
      }

      /**
       * BridgeHealth
       */
      if (action.data.plans.available.bridgeHealth) {
        menus.push({
          featureFlag: 'showBridgeHealth',
          name: {
            english:
              ihcId === 'reta' ? 'Reta Surgery Benefit' : 'Surgery Benefit',
            spanish:
              ihcId === 'reta'
                ? 'Reta Beneficio de cirugía'
                : 'Beneficio de cirugía',
          },
          id: 'bridgeHealth',
          selected: false,
        });
      }

      /**
       * Vivante
       */
      if (action.data.plans.available.vivante) {
        menus.push({
          featureFlag: 'showVivante',
          name: {
            english:
              ihcId === 'reta'
                ? 'Reta Digestive Health Benefit'
                : 'Digestive Health Benefit',
            spanish:
              ihcId === 'reta'
                ? 'Beneficio para la salud digestiva de Reta'
                : 'Beneficio para la salud digestiva',
          },
          id: 'vivante',
          selected: false,
        });
      }

      /**
       * Livongo
       */
      if (action.data.plans.available.livongo) {
        menus.push({
          featureFlag: 'showLivongo',
          name: {
            english: 'Diabetes Management Benefit',
            spanish: 'Beneficio de manejo de la diabetes',
          },
          id: 'livongo',
          selected: false,
        });
      }

      /**
       * Add the decision tool if it's available
       */
      if (
        action.data.options.enableDecisionTool &&
        action.data.plans.available.medical.length > 1
      ) {
        menus.push({
          name: {
            english: 'Your Decision Tool',
            spanish: 'Herramienta para toma de decisiones',
          },
          id: 'decision-tool',
          selected: false,
        });
      }

      /**
       * Rx menu
       */
      if (action.data.plans.available.rx.length) {
        menus.push({
          name: {
            english: 'Pharmacy Benefits',
            spanish: 'Beneficios farmacéuticos',
          },
          id: action.data.plans.available.rx[0].network.toLowerCase(),
          selected: false,
        });
      }

      /**
       * Dental Menu
       */
      if (action.data.plans.available.dental.length) {
        menus.push({
          name: {
            english: 'Dental',
            spanish: 'Dental',
          },
          id: action.data.plans.available.dental[0].network.toLowerCase(),
          selected: false,
        });
      } else if (
        action.data.plans.available.other.filter(p => p.category === 'DENTAL')
          .length
      ) {
        menus.push({
          name: {
            english: 'Dental',
            spanish: 'Dental',
          },
          id: 'voluntary',
          selected: false,
        });
      }

      /**
       * Vision menu
       */
      if (action.data.plans.available.vision.length) {
        menus.push({
          name: {
            english: 'Vision',
            spanish: 'Cuidado de la vista',
          },
          id: action.data.plans.available.vision[0].network.toLowerCase(),
          selected: false,
        });
      } else if (
        action.data.plans.available.other.filter(p => p.category === 'VISION')
          .length
      ) {
        menus.push({
          name: {
            english: 'Vision',
            spanish: 'Cuidado de la vista',
          },
          id: 'voluntary',
          selected: false,
        });
      }

      /**
       * Catapult Health, special for 2022, not in the wellbeing menu
       */
      if (action.data.features.includes('showCatapultHealth')) {
        menus.push({
          name: {
            english: 'Reta Virtual Checkup',
            spanish: 'Chequeo Virtual Reta',
          },
          id: 'wellness-catapult',
          selected: false,
        });
      }

      /**
       * My Virtual Mission, special for 2022, not in the wellbeing menu
       */
      if (action.data.features.includes('showVirtualMission')) {
        menus.push({
          name: {
            english: 'Reta Holy Land Pilgrimage',
            spanish: 'Reta Peregrinación a Tierra Santa',
          },
          id: 'mvm',
          selected: false,
        });
      }

      /**
       * Items for BSG demo presentation
       */
      if (
        action.data.plans.available.retirement &&
        action.data.plans.available.retirement.length
      ) {
        menus.push({
          name: {
            english: 'Retirement Plans',
            spanish: 'Planes de jubilación',
          },
          id: 'retirement',
          selected: false,
        });
      }

      /**
       * Add menu for non Reta plans
       */
      if (action.data.plans.available.other.length) {
        menus.push({
          name: {
            english: ihcId === 'reta' ? 'Other Benefits' : 'Voluntary Benefits',
            spanish:
              ihcId === 'reta' ? 'Otros beneficios' : 'Beneficios voluntarios',
          },
          id: 'voluntary',
          selected: false,
        });
      }

      /**
       * Add menu for My Workplace
       */
      if (action.data.workplace && action.data.workplace.length) {
        action.data.workplace.forEach((wrk, i) => {
          menus.push({
            featureFlag: 'showWorkplace',
            name: {
              english:
                action.data.ihcId === 'bsg' ? 'My Workplace' : 'Your Workplace',
              spanish: 'Tu lugar de trabajo',
            },
            id: `workplace-${i}`,
            selected: false,
          });
        });
      }

      /**
       * Add Contacts Menu
       */
      menus.push({
        name: {
          english: 'Key Contacts',
          spanish: 'Contactos clave',
        },
        id: 'contacts',
        selected: false,
      });

      /**
       * Add menu item to return to the home menu
       */
      menus.push({
        name: {
          english: 'Home',
          spanish: 'Inicio',
        },
        id: 'welcome',
        selected: false,
      });

      /**
       * Return the menus
       */
      return {
        menus,
      };
    }

    case SELECT_NAV_MENU: {
      let newState = { ...state };

      /**
       * If this is an expandable menu it, get the menu name
       */
      let fullMatch, parentId;
      if (action.id) {
        const match = action.id.match(/^(\w+)-(\w+)$/);
        if (match) {
          [fullMatch, parentId] = match;
        }
      }

      newState.menus.forEach(menu => {
        menu.selected = false;

        if (menu.id === parentId || menu.id === action.id) {
          menu.selected = true;
        }

        if (menu.menus) {
          menu.menus.forEach(m => {
            m.selected = false;

            if (m.id === fullMatch) {
              m.selected = true;
            }
          });
        }
      });

      return newState;
    }

    case EXPAND_NAV_MENU: {
      let newState = { ...state };

      /**
       * Deselect all
       */
      newState.menus.forEach(menu => {
        menu.selected = false;
        if (menu.menus) {
          menu.menus.forEach(m => (m.selected = false));
        }
      });

      newState.menus = newState.menus.map(menu => {
        if (menu.id === action.id) {
          return {
            ...menu,
            selected: true,
          };
        }
        return menu;
      });

      return newState;
    }

    case SELECT_NAV_SUB_MENU: {
      let newState = { ...state };

      /**
       * Deselect all
       */
      newState.menus.forEach(menu => {
        menu.selected = false;
        if (menu.menus) {
          menu.menus.forEach(m => (m.selected = false));
        }
      });

      newState.menus = newState.menus.map(menu => {
        /**
         * Parent Menu
         */
        if (menu.id === action.parentId) {
          return {
            ...menu,
            selected: true,

            /**
             * Submenus
             */
            menus: menu.menus.map(sub => {
              if (sub.id === action.id) {
                return {
                  ...sub,
                  selected: true,
                };
              }

              return sub;
            }),
          };
        }

        return menu;
      });

      return newState;
    }

    case SHOW_MOBILE_MENU:
      return {
        ...state,
        showMobileMenu: action.value,
      };

    default:
      return { ...state };
  }
};
