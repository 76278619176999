import React, { useState, useContext } from 'react';
import {
  SectionWithSideContent,
  SectionWithSideContentFullBg,
} from '../../shared/Layout';
import { SideContent } from './SideContent';
import { Content } from './Content';
import _has from 'lodash.has';
import { COLORS } from '../../../constants';
import Color from 'color2';
import { FeaturesContext } from '../../../contexts/features';

export let NetworkDelta = props => {
  const [sideScrollY, setSideScrollY] = useState(0);

  const features = useContext(FeaturesContext);

  if (features.active('fullBg')) {
    return (
      <SectionWithSideContentFullBg
        id={props.id}
        isMedical={props.isMedical || false}
        sideContentStyle={{
          backgroundColor: _has(props.plan, 'colors.background.side')
            ? new Color(props.plan.colors.background.side).alpha(0.85)
            : new Color(COLORS.RED).alpha(0.85),
        }}
        sideContent={<SideContent {...props} />}
        contentStyle={{
          paddingLeft: 0,
        }}
        content={<Content sideScrollY={sideScrollY} {...props} />}
        setSideScrollY={setSideScrollY}
        bgImage={
          _has(props.plan, 'images.background.url') &&
          props.plan.images.background.url
        }
      />
    );
  } else {
    return (
      <SectionWithSideContent
        id={props.id}
        isMedical={props.isMedical || false}
        style={{
          minHeight: props.plan?.images?.background?.minHeight || 'auto',
        }}
        sideContentStyle={{
          backgroundColor: _has(props.plan, 'colors.background.side')
            ? props.plan.colors.background.side
            : COLORS.RED,
        }}
        sideContent={<SideContent {...props} />}
        contentStyle={{
          paddingLeft: 0,
        }}
        content={<Content sideScrollY={sideScrollY} {...props} />}
        setSideScrollY={setSideScrollY}
      />
    );
  }
};
