import React from 'react';
import { COLORS } from '../../constants';
import { TextEmphasis } from '../shared/Text';
import { Flexbox, FlexColumn } from '../shared/Flexbox';
import { Paragraph } from '../shared/Layout';
import { close } from '../../actions/AboutPlans';
import { connect } from 'react-redux';
import Close from '../shared/Close';

let Step = props => {
  return (
    <FlexColumn
      style={{
        color: COLORS.TEXT,
        backgroundColor: COLORS.GRAY_BLUE,
        marginLeft: 18,
        marginRight: 18,
        padding: 17,
        flex: '1 1 100px',
        ...props.style,
      }}
    >
      <div
        style={{
          fontSize: 19,
          fontWeight: 'bold',
          width: '100%',
          textAlign: 'center',
          marginBottom: 12,
        }}
      >
        {props.step}
      </div>

      <Paragraph
        style={{
          fontSize: 12,
          lineHeight: 17,
        }}
      >
        {props.children}
      </Paragraph>
    </FlexColumn>
  );
};

export let HSAWorks = ({ onClose }) => {
  return (
    <FlexColumn
      style={{
        backgroundColor: COLORS.ORANGE,
        padding: 36,
        position: 'relative',
        flex: '1 1 576px',
      }}
    >
      <Close onClick={onClose} />

      <div
        style={{
          marginBottom: 12,
        }}
      >
        <TextEmphasis
          style={{
            color: COLORS.GRAY_BLUE,
            fontWeight: 'bold',
          }}
        >
          Here&apos;s how the health savings account works:
        </TextEmphasis>
      </div>

      <Flexbox
        style={{
          justifyContent: 'space-between',
        }}
      >
        <Step step="1" style={{ marginLeft: 0 }}>
          <div>
            You pay eligible expenses&mdash;using HSA money, made up of your own
            before-tax contributions plus any employer dollars—until you meet
            your plan’s annual deductible.
          </div>
        </Step>

        <Step step="2">
          <div>
            Your medical plan pays most of your expenses after that. You pay
            only your coinsurance in your benefit plan.
          </div>
        </Step>

        <Step step="3" style={{ marginRight: 0 }}>
          <div>
            The plan pays for 100% of eligible network expenses for the rest of
            the year when your total cost reaches your plan&apos;s out-of-pocket
            maximum.
          </div>
        </Step>
      </Flexbox>
    </FlexColumn>
  );
};

HSAWorks = connect(null, dispatch => {
  return {
    onClose: () => dispatch(close()),
  };
})(HSAWorks);
