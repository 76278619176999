import React from 'react';
import { BulletLine } from './Bullets';
import { COLORS } from '../../constants';
import { connect } from 'react-redux';
import { Text } from '../shared/Text';
import { media } from '../../constants';
import styled from 'styled-components';
import { EnrollmentRange } from '../shared/Enrollment';

const Detail = props => {
  return (
    <div
      style={{
        padding: '10px 0',
        paddingLeft: 16,
        boxSizing: 'border-box',
      }}
    >
      <Text
        style={{
          color: 'black',
        }}
      >
        {props.detail}
      </Text>
    </div>
  );
};

class NewItem extends React.Component {
  state = {
    expanded: false,
  };

  render() {
    return (
      <div>
        <BulletLine
          bulletAlign="start"
          bulletOffset={-2}
          onClick={() => this.setState({ expanded: !this.state.expanded })}
        >
          {this.props.title}
        </BulletLine>

        {this.state.expanded &&
          this.props.details &&
          this.props.details.map((detail, d) => (
            <Detail key={d} detail={detail} />
          ))}
      </div>
    );
  }
}

const Wrapper = styled.div`
  background-color: ${COLORS.ORANGE};
  padding: 36px;
  box-sizing: border-box;
  margin: 108px 54px;
  margin-left: 0;
  width: 100%;

  ${media.tablet`
    margin: 0;
  `};
`;

export const WhatsNew = connect(state => {
  return {
    changes: state.WhatsNew.changes,
    has: state.Data.has,
  };
})(({ changes, has }) => {
  return (
    <Wrapper>
      <div
        style={{
          color: COLORS.RED,
          marginBottom: 24,
        }}
      >
        What&apos;s New for <EnrollmentRange />
      </div>

      {changes.map((msg, i) => {
        /**
         * Filter out conditional message
         */
        if (msg.condition) {
          switch (msg.condition) {
            case 'HAS_ANTHEM': {
              if (!has.anthem) {
                return <></>;
              }
              break;
            }
            case 'HAS_VSP': {
              if (!has.vsp) {
                return <></>;
              }
              break;
            }
            default:
              break;
          }
        }

        return (
          <NewItem
            key={i}
            title={msg.title}
            details={
              msg.details &&
              msg.details.map((detail, i) => (
                <div key={i} dangerouslySetInnerHTML={{ __html: detail }} />
              ))
            }
          />
        );
      })}
    </Wrapper>
  );
});

export const Content = () => {
  return (
    <div
      style={{
        padding: 36,
        boxSizing: 'border-box',
      }}
    >
      <WhatsNew />
    </div>
  );
};
