import { FlexColumn } from '../shared/Flexbox';
import { COLORS } from '../../constants';
import styled from 'styled-components/macro';

export const Cell = styled(FlexColumn)`
  flex-grow: 1;
  color: ${COLORS.TEXT_DARK};
  font-size: 11px;
  font-family: Roboto Condensed;
  line-height: 14px;
  padding: 12px;
  box-sizing: border-box;
  border-right: 1px solid #c2c2c4;
  overflow: hidden;

  &.available.content {
    height: fit-content !important;
  }
`;
