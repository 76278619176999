import React from 'react';
import { BulletLine, BulletOrange } from '../shared/Bullets';
import { SideParagraph } from '../shared/Layout';
import { connect } from 'react-redux';
import { FlexColumn } from '../shared/Flexbox';
import { Padding } from '../welcome/SideContent';
import { COLORS, media } from '../../constants';
import styled from 'styled-components';

const SideTitle = styled.div`
  color: ${COLORS.TEXT};
  font-family: Roboto Condensed;
  font-weight: 300;
  font-size: 26px;
  line-height: 33px;
`;

const Title = styled(SideTitle)`
  margin-top: 55px;

  ${media.tablet`margin-top: 0;`}
`;

export let SideContent = ({
  plans,
  noMediaPlans,
  isOE,
  ihcId,
  displayName,
}) => {
  return (
    <FlexColumn
      style={{
        flexGrow: 1,
      }}
    >
      <Padding>
        <Title>
          {ihcId === 'reta' && 'Other Employee Benefits'}
          {ihcId !== 'reta' &&
            'Your Life Insurance, AD&D and Disability Benefit Options'}
        </Title>

        {ihcId === 'reta' && isOE && (
          <SideParagraph
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            Below are other benefits available to you. Unless indicated
            otherwise, these benefits are not provided by{' '}
            {ihcId === 'reta' ? 'Reta' : ihcId === 'bsg' ? 'ETF' : ''}. For more
            information, click on a plan and go directly to the provider
            information.
          </SideParagraph>
        )}

        {ihcId === 'reta' && !isOE && (
          <SideParagraph
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            Below are other benefits that are available for the current plan
            year for those who are eligible to enroll or change coverage status
            before the next open enrollment period. Unless indicated otherwise,
            these benefits are not provided by Reta. For more information, click
            on a plan and go directly to the provider information.
          </SideParagraph>
        )}

        {isOE && ihcId !== 'reta' && (
          <SideParagraph
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            These {displayName} benefits offer financial protection to you and
            your family in the event of unfortunate and life circumstances.
          </SideParagraph>
        )}

        {/* Bullets */}
        {plans.map((plan, p) => {
          /**
           * I there is no media, use a link to the corp site
           */
          if (!plan.media || !plan.media.length) {
            return (
              <BulletLine
                key={p}
                icon={<BulletOrange />}
                onClick={() => window.open(plan.corpSite)}
              >
                {plan.description}
              </BulletLine>
            );
          }

          /**
           * Don't add any groups if there is only one media file
           */
          // if (plan.media.length === 1) {
          //   return (
          //     <BulletLine
          //       key={p}
          //       icon={<BulletOrange />}
          //       onClick={() => window.open(plan.media[0].url)}
          //     >
          //       {plan.media[0].name}
          //     </BulletLine>
          //   );
          // }

          /**
           * Add plan name and all media under it
           */
          return (
            <div
              key={p}
              style={{
                marginTop: 10,
                marginBottom: 20,
              }}
            >
              <SideTitle
                style={{ fontSize: 17 }}
                key={p}
                dangerouslySetInnerHTML={{ __html: plan.description }}
              />

              {plan.media.map((m, i) => {
                return (
                  <BulletLine
                    key={i}
                    icon={<BulletOrange />}
                    onClick={() => m.type === 'URL' && window.open(m.url)}
                  >
                    {m.name}
                  </BulletLine>
                );
              })}
            </div>
          );
        })}

        {noMediaPlans.length > 0 && plans.length !== 0 && (
          <SideTitle style={{ fontSize: 17 }}>Other</SideTitle>
        )}

        {noMediaPlans.map((plan, p) => {
          return (
            <BulletLine
              key={p}
              icon={<BulletOrange />}
              onClick={() => {
                let link;
                if (
                  plan.media.length > 0 &&
                  plan.media[0].url &&
                  plan.media[0].url.length
                ) {
                  link = plan.media[0].url;
                }

                if (!link && plan.corpSite) {
                  link = plan.corpSite;
                }

                if (link) {
                  window.open(
                    plan.media.length > 0 ? plan.media[0].url : plan.corpSite,
                  );
                }
              }}
            >
              {plan.media.length ? plan.media[0].name : plan.description}
            </BulletLine>
          );
        })}
      </Padding>
    </FlexColumn>
  );
};

SideContent = connect(state => {
  const noMediaPlans = [];
  const order = [
    'MEDICAL',
    'DENTAL',
    'VISION',
    'RX',
    'LIFE',
    'AD&D',
    'LTD',
    'STD',
    'EAP',
    'WELLNESS',
  ];
  const plans = state.Data.plans.available.other
    .concat(
      state.Data.plans.available.life,
      state.Data.plans.available.ltd,
      state.Data.plans.available.std,
    )
    .sort((a, b) =>
      a.description < b.description
        ? -1
        : a.description > b.description
        ? 1
        : 0,
    )
    .reduce((acc, plan) => {
      if (plan.media.length > 1 || plan.forceCategory) {
        return acc.concat([plan]);
      }

      noMediaPlans.push(plan);

      return acc;
    }, [])
    // sort by the order list above
    .sort((a, b) => {
      return order.indexOf(a.category) - order.indexOf(b.category);
    });

  // Sort No Media Plans by Description
  noMediaPlans.sort(
    (a, b) =>
      // a.description < b.description ? -1 : a.description > b.description ? 1 : 0,
      order.indexOf(a.category) - order.indexOf(b.category),
  );

  return {
    plans,
    noMediaPlans,
    isOE: state.Data.enrollment.type === 'ANNUAL',
    ihcId: state.Data.ihcId,
    displayName: state.Data.client.displayName,
  };
})(SideContent);
