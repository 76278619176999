/* eslint-disable array-callback-return */
import React, { useContext, useRef, useState } from 'react';
import { BulletLine } from '../shared/Bullets';
import { showModal, setModalPos } from '../../actions/Networks';
import { connect } from 'react-redux';
import { Language } from '../shared/Language';
import { SideParagraph } from '../shared/Layout';
import _has from 'lodash.has';
import { sizes, COLORS } from '../../constants';
import { Padding } from '../welcome/SideContent';
import { SubMenu } from '../shared/SubMenu';
import EndContent from './EndContent';
import { FeaturesContext } from '../../contexts/features';
import { Text } from '../shared/Text';
import FooterMenuBlueShield from './FooterMenuBlueShield';
import styled from 'styled-components/macro';
import FooterMenuKaiser from './FooterMenuKaiser';

export const calcYOffset = (elem, parent) => {
  const rect = elem.getBoundingClientRect();
  const parentRect = parent.getBoundingClientRect();
  const scrollY = parent.parentNode.scrollTop;
  const y = rect.top - parentRect.top - scrollY;
  return y;
};

const CondSideCopy = props => {
  let children = props.children;
  if (typeof children === 'string' && /\[/.test(children[0])) {
    children = JSON.parse(children).join('');
  }

  return (
    <SideParagraph
      style={{
        marginTop: 34,
        marginBottom: 0,
      }}
    >
      {props.children.map((c, i) => {
        return <div key={i} dangerouslySetInnerHTML={{ __html: c }} />;
      })}
    </SideParagraph>
  );
};

const Mobile = styled.div`
  @media (min-width: ${sizes.tablet}px) {
    display: none;
  }
`;

const Menus = styled.div`
  background-color: ${COLORS.ANTHEM_MENU_BG};
  color: white;
  font-size: 18px;
  opacity: ${props => props.opacity};
  box-sizing: border-box;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const _Item = props => {
  const [showMenus, setShowMenus] = useState(false);
  const parentElem = useRef();

  return (
    <div ref={parentElem}>
      <Padding
        style={{
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <BulletLine
          bullettype={props.plan && props.plan.bulletType}
          onClick={e => {
            e.stopPropagation(true);

            // if there is a URL, open it in a new tab
            if (props.item.url) {
              return window.open(props.item.url, '_blank');
            }

            // show modal in the content area if it exists
            if (props.item.menus && props.item.menus.length) {
              props.onShowModal(
                props.item.menus,
                calcYOffset(
                  e.target.parentNode,
                  parentElem.current.parentNode.parentNode,
                ),
                props.id,
              );
            }

            // toggle mobile menu
            setShowMenus(prev => !prev);
          }}
          textstyle={props.textStyle}
        >
          {props.item.title}
        </BulletLine>
      </Padding>

      <Mobile>
        {showMenus && props.item.menus && props.item.menus.length && (
          <Menus>
            <Padding>
              <SubMenu items={props.item.menus} />
            </Padding>
          </Menus>
        )}
      </Mobile>
    </div>
  );
};

export const Item = connect(null, dispatch => {
  return {
    onShowModal: (items, y, id) => {
      dispatch(setModalPos(0, y));
      dispatch(showModal(items, id));
    },
  };
})(_Item);

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const MainContentWrapper = styled.div`
  /* locks the EndContent to the bottom and scrolls the logo and bullets */
  /* overflow-y: auto; */
`;

export let SideContent = ({
  language,
  acoNetworks,
  hpnNetworks,
  has,
  is,
  options,
  isMedical,
  hideLanguageSelector = false,
  ihcId,
  eventId,
  ...props
}) => {
  const features = useContext(FeaturesContext);

  return (
    <FlexColumn>
      <MainContentWrapper>
        <Padding
          style={{
            paddingBottom: props.plan.shiftBulletsUp ? 12 : 36,
          }}
        >
          {!hideLanguageSelector && <Language />}

          {/* Logo */}
          {_has(props.plan, 'images.logo.url') && (
            <div
              style={{
                marginTop: 48,
                position: 'relative',
                width: 111,
              }}
            >
              <img
                style={{
                  width: props.plan.images.logo.width || 'auto',
                  height: props.plan.images.logo.height || 'auto',
                }}
                src={
                  props.plan.images.logo.useOriginal
                    ? props.plan.images.logo.origUrl
                    : props.plan.images.logo.url
                }
                alt="logo"
              />
            </div>
          )}

          {/* Slug Line if available */}
          {props.plan.slug && props.plan.slug[language] && (
            <SideParagraph style={{ marginBottom: 62 }}>
              {props.plan.slug[language].value}
            </SideParagraph>
          )}

          {props.overrideContent && props.overrideContent}

          {/* Conditional side copy */}
          {!props.overrideContent &&
            props.plan.sideCopy &&
            // eslint-disable-next-line
            props.plan.sideCopy.map((text, i) => {
              if (!text[language]) {
                return;
              }

              if (!text[language].hasOwnProperty('condition')) {
                return (
                  <CondSideCopy key={i}>{text[language].value}</CondSideCopy>
                );
              }

              if (
                text[language].condition === 'BUNDLED_WITH_MEDICAL' &&
                !has.kaiser &&
                props.plan.bundledWith &&
                props.plan.bundledWith.includes('MEDICAL')
              ) {
                return (
                  <CondSideCopy key={i}>{text[language].value}</CondSideCopy>
                );
              }

              if (
                text[language].condition ===
                  'BUNDLED_WITH_MEDICAL_AND_HAS_KAISER' &&
                has.kaiser &&
                props.plan.bundledWith &&
                props.plan.bundledWith.includes('MEDICAL')
              ) {
                return (
                  <CondSideCopy key={i}>{text[language].value}</CondSideCopy>
                );
              }

              if (
                text[language].condition === 'HAS_HSA_AND_KAISER' &&
                has.HSA &&
                has.kaiser
              ) {
                return (
                  <CondSideCopy key={i}>{text[language].value}</CondSideCopy>
                );
              }

              if (
                text[language].condition === 'HAS_HSA' &&
                has.HSA &&
                !has.kaiser
              ) {
                return (
                  <CondSideCopy key={i}>{text[language].value}</CondSideCopy>
                );
              }

              if (text[language].condition === 'HAS_DEPO' && has.DEPO) {
                return (
                  <CondSideCopy key={i}>{text[language].value}</CondSideCopy>
                );
              }

              if (text[language].condition === 'HAS_EPO' && has.EPO) {
                return (
                  <CondSideCopy key={i}>{text[language].value}</CondSideCopy>
                );
              }

              if (text[language].condition === 'HAS_PPO' && has.PPO) {
                return (
                  <CondSideCopy key={i}>{text[language].value}</CondSideCopy>
                );
              }

              if (
                text[language].condition === 'HAS_KAISER' &&
                has.kaiser &&
                !has.HSA &&
                !(
                  props.plan.bundledWith &&
                  props.plan.bundledWith.includes('MEDICAL')
                )
              ) {
                return (
                  <CondSideCopy key={i}>{text[language].value}</CondSideCopy>
                );
              }

              if (
                text[language].condition === 'REQUIRES_MEDICAL' &&
                props.plan.requires &&
                props.plan.requires.includes('MEDICAL')
              ) {
                return (
                  <CondSideCopy key={i}>{text[language].value}</CondSideCopy>
                );
              }

              if (text[language].condition === 'NO_KAISER' && !has.kaiser) {
                return (
                  <CondSideCopy key={i}>{text[language].value}</CondSideCopy>
                );
              }
            })}
        </Padding>

        {/* Bullets */}
        <div
          style={{
            marginTop: props.plan.shiftBulletsUp ? 0 : 34,
            marginBottom:
              features.active('autoSectionHeight') && !props.plan.sideCopyFooter
                ? 63
                : 36,
          }}
        >
          {props.plan.content &&
            props.plan.content.map((item, i) => {
              if (!item[language]) {
                console.warn('Unsupported Item', item, 'on', props.plan);
                return;
              }

              // Check any conditions
              if (item[language].condition) {
                // if the condition doesn't match, don't render the item
                if (
                  item[language].condition === 'PLAN_HAS_ACO' &&
                  !acoNetworks.includes(props.plan.network)
                ) {
                  return;
                }

                if (
                  item[language].condition === 'PLAN_HAS_HPN' &&
                  !hpnNetworks.includes(props.plan.network)
                ) {
                  return;
                }

                if (
                  item[language].condition === 'PLAN_HAS_PPO_EPO' &&
                  !has.PPO &&
                  !has.EPO
                ) {
                  return;
                }

                if (item[language].condition === 'IS_PRIEST' && !is.priest) {
                  return;
                }

                if (
                  item[language].condition === 'IS_RETIRED_PRIEST' &&
                  !is.retiredPriest
                ) {
                  return;
                }

                if (item[language].condition === 'IS_NOT_PRIEST' && is.priest) {
                  return;
                }

                if (item[language].condition === 'IS_LAY' && !is.lay) {
                  return;
                }

                if (
                  item[language].condition === 'IS_SEMINARIAN' &&
                  !is.seminarian
                ) {
                  return;
                }

                if (item[language].condition === 'IS_CLERGY' && !is.clergy) {
                  return;
                }
              }

              return (
                <Item
                  key={i}
                  {...props}
                  item={item[language.toLowerCase()]}
                  iconType={props.plan.bulletType}
                />
              );
            })}
        </div>

        {/* Footer Menu */}
        {props.plan.network === 'BLUE_SHIELD_CA' && (
          <FooterMenuBlueShield
            plan={props.plan}
            id={props.id}
            language={language}
            iconType={props.plan.bulletType}
          />
        )}

        {props.plan.network === 'KAISER' && (
          <FooterMenuKaiser
            plan={props.plan}
            id={props.id}
            language={language}
            iconType={props.plan.bulletType}
          />
        )}

        {/* Footer */}
        {props.plan.sideCopyFooter && (
          <div
            style={{
              marginBottom: features.active('autoSectionHeight') ? 63 : 36,
            }}
          >
            <Padding>
              <Text
                dangerouslySetInnerHTML={{
                  __html: props.plan.sideCopyFooter[language.toLowerCase()],
                }}
              />
            </Padding>
          </div>
        )}
      </MainContentWrapper>

      {eventId === '2020-oe' &&
        isMedical &&
        (has.bridgeHealth ||
          has.vivante ||
          has.ww ||
          has.fitbit ||
          has.livongo ||
          options.showBrideHealth ||
          options.showVivante ||
          options.showWW ||
          options.showFitbit ||
          options.showWebMD) && (
          <EndContent options={options} planId={props.plan.id} />
        )}
    </FlexColumn>
  );
};

SideContent.defaultProps = {
  isMedical: false,
};

SideContent = connect(state => {
  return {
    language: state.Language.current.toLowerCase(),
    acoNetworks: state.Data.acoNetworks,
    hpnNetworks: state.Data.hpnNetworks,
    has: state.Data.has,
    is: state.Data.is,
    options: state.Options,
    ihcId: state.Data.ihcId,
    eventId: state.Data.event.eventId,
  };
})(SideContent);
