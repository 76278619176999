import React from 'react';
import { Flexbox } from './Flexbox';
import imgBullet from '../../assets/img/bullet.svg';
import imgBulletVideo from '../../assets/img/bulletVideo.svg';
import imgBulletOrange from '../../assets/img/bulletOrange.svg';
import imgSimpleBullet from '../../assets/img/simpleBullet.svg';
import imgBulletLargeBlueYellow from '../../assets/img/bulletLargeBlueYellow.svg';
import { COLORS } from '../../constants';
import { Text } from './Text';

export function Bullet() {
  return <img src={imgBullet} style={{ width: 20, height: 20 }} alt="" />;
}

export function BulletVideo() {
  return <img src={imgBulletVideo} style={{ width: 15, height: 15 }} alt="" />;
}

export function BulletOrange() {
  return <img src={imgBulletOrange} style={{ width: 20, height: 20 }} alt="" />;
}

export const BulletLargeBlueYellow = (
  <img
    src={imgBulletLargeBlueYellow}
    style={{ width: 30, height: 30 }}
    alt=""
  />
);

export const BulletSimple = props => {
  return (
    <img
      src={imgSimpleBullet}
      style={{ width: 5, height: 5, ...props.style }}
      alt=""
    />
  );
};

const BulletCheck = () => {
  return (
    <i
      className="fa fa-check"
      aria-hidden="true"
      style={{ color: 'rgb(255, 177, 30)' }}
    ></i>
  );
};

export const BulletLine = props => {
  let icon = props.icon || <Bullet />;
  if (props.bullettype === 'check') {
    icon = <BulletCheck />;
  }

  const textStyle = {
    ...(props.selected && { color: COLORS.ORANGE }),
    ...props.textstyle,
  };

  return (
    <Flexbox
      {...props}
      style={Object.assign(
        {
          alignItems: 'center',
          marginTop: 11,
          cursor: 'default',
          ...props.style,
        },
        props.onClick && props.bullettype !== 'check' && { cursor: 'pointer' },
      )}
      onClick={props.onClick}
    >
      {icon}

      <div
        style={{
          marginLeft: 12,
          width: '100%',
        }}
      >
        {/* If the child is a string, set the innerHTML */}
        {typeof props.children === 'string' && (
          <Text
            style={textStyle}
            dangerouslySetInnerHTML={{ __html: props.children }}
          />
        )}

        {/* If the children are nodes, add them as children */}
        {typeof props.children !== 'string' && (
          <Text style={textStyle}>{props.children}</Text>
        )}
      </div>
    </Flexbox>
  );
};
BulletLine.defaultProps = {
  selected: false,
};

export let BulletVideoLine = props => {
  return (
    <BulletLine {...props} icon={<BulletVideo />}>
      {props.children}
    </BulletLine>
  );
};

export function ModalBullet(props) {
  return (
    <Flexbox
      style={{
        marginBottom: 8,
      }}
    >
      <div>
        <img src={imgSimpleBullet} style={{ width: 5, height: 5 }} alt="" />
      </div>

      <div
        style={{
          marginLeft: 8,
        }}
      >
        <Text>{props.children}</Text>
      </div>
    </Flexbox>
  );
}
