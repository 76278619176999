import { Padding } from '../welcome/SideContent';
import { Text } from '../shared/Text';
import styled from 'styled-components/macro';
import { Item } from './SideContent';

const YellowText = styled(Text)`
  color: rgb(255, 177, 30);
  font-weight: bold;
`;

const Paragraph = styled.div`
  margin: 16px 0;
`;

const FooterMenuBlueShield = ({ plan, language, iconType, id }) => {
  const menuItems = [
    {
      english: {
        title: 'Wellvolution for Health and Well-Being',
        url: 'https://engagementpoint.com/',
      },
      spanish: {
        title: 'Wellvolution para la Salud y el Bienestar',
        url: 'https://engagementpoint.com/',
      },
    },
    // {
    //   english: {
    //     title: 'Wellvolution flyer',
    //     url:
    //       'https://firebasestorage.googleapis.com/v0/b/forty8-ihc-e908.appspot.com/o/clients%2Freta%2Fevents%2F2022-oe%2Fcarriers%2FblueShieldCA%2FWellvolutionFlyer2022.pdf?alt=media&token=01f14e1c-2dbf-4ff8-90cb-5c59ad86056f',
    //   },
    //   spanish: {
    //     title: 'Folleto de Wellvolution',
    //     url:
    //       'https://firebasestorage.googleapis.com/v0/b/forty8-ihc-e908.appspot.com/o/clients%2Freta%2Fevents%2F2022-oe%2Fcarriers%2FblueShieldCA%2FWellvolutionFlyer2022.pdf?alt=media&token=01f14e1c-2dbf-4ff8-90cb-5c59ad86056f',
    //   },
    // },
    {
      english: {
        title: 'Contact Us',
        menus: [
          {
            title:
              '<p>Blue Shield of California<br/><b>(888) 772-1076</b><br/>5 am - 8 pm Pacific Time Monday through Friday</p> <br/> <p> <b>NurseHelp 24/7 [TTY: 711]</b><br/> (877) 304-0504 </p>',
          },
        ],
      },
      spanish: {
        title: 'Contacta con nosotros',
        menus: [
          {
            title:
              '<p>Blue Shield of California<br/><b>(888) 772-1076</b> 5 a.m. - 8 p.m.<br/>hora del Pacífico de lunes a Viernes</p> <br/> <p> <b>NurseHelp 24/7 [TTY: 711]</b><br/> (877) 304-0504 </p>',
          },
        ],
      },
    },
  ];

  return (
    <div style={{ paddingBottom: 32 }}>
      <Padding style={{ paddingTop: 0, paddingBottom: 0, width: '100%' }}>
        <div style={{ width: '100%' }}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/forty8-ihc-e908.appspot.com/o/clients%2Freta%2Fevents%2F2022-oe%2Fcarriers%2FblueShieldCA%2F0519e53d-9c6c-4c2f-9c91-e337da31ca2b.png?alt=media&token=bb27244e-813d-48ab-9a99-2c331a959752"
            alt="EngagementPoint Logo"
          />
        </div>

        <Paragraph style={{ marginTop: 6 }}>
          {language === 'english' && (
            <Text>Your integrated wellness program</Text>
          )}
          {language === 'spanish' && (
            <Text>Su programa de bienestar integrado</Text>
          )}
        </Paragraph>

        <Paragraph>
          {language === 'english' && (
            <YellowText>
              If you enroll in this Reta medical plan, you can enroll in the
              Blue Shield <i>Wellvolution</i> lifestyle program at no additional
              cost to you by registering through <i>EngagementPoint</i>.
            </YellowText>
          )}
          {language === 'spanish' && (
            <YellowText>
              Si se inscribe en este plan médico de Reta, puede inscribirse en
              el Programa de estilo de vida <i>Wellvolution</i> de Blue Shield
              sin costo adicional costo para usted registrándose a través de{' '}
              <i>EngagementPoint</i>.
            </YellowText>
          )}
        </Paragraph>
      </Padding>

      {/* Menu Items */}
      {menuItems.map((menu, i) => {
        return (
          <Item
            key={i}
            id={id}
            item={menu[language.toLowerCase()]}
            iconType={iconType}
            textStyle={{ fontSize: 15 }}
          />
        );
      })}
    </div>
  );
};

export default FooterMenuBlueShield;
