import { useEffect, useState, createContext } from 'react';

export const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
  const [bbHost, setBbHost] = useState();

  useEffect(() => {
    (async () => {
      const res = await fetch('/bbHost');
        const bbHost = await res.text();
      console.debug('Buletin Board host:', bbHost)
      setBbHost(bbHost);
    })();
  }, []);

  return (
    <ConfigContext.Provider value={{ bbHost }}>
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
