import React from 'react';
import { Flexbox, FlexColumn } from '../shared/Flexbox';
import { COLORS } from '../../constants';
import imgClearview from '../../assets/img/carriers/myClearview.png';
import { connect } from 'react-redux';

export const onClick = testing => {
  window.open(
    testing
      ? 'https://www.bastest.com/web_projects/MainHomePage/MyEnroll_Home.aspx?page=4ADC1234C457D29DE053320A12AC9FEE'
      : 'https://www.myenroll.com/web_projects/MainHomePage/MyEnroll_Home.aspx?page=4ADC1234C457D29DE053320A12AC9FEE',
  );
};

export let Content = ({ testing }) => {
  return (
    <FlexColumn
      style={{
        backgroundColor: COLORS.GRAY_BLUE,
        position: 'relative',
        width: '100%',
        flexGrow: 1,
        flexShrink: 1,
        overflow: 'hidden',
      }}
    >
      <Flexbox
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <img
          src={imgClearview}
          onClick={() => onClick(testing)}
          alt="ClearView"
        />
      </Flexbox>
    </FlexColumn>
  );
};
Content = connect(state => {
  return {
    testing: state.Data.test,
  };
})(Content);
